import React from "react";
import decode from "jwt-decode";

// redux
import { connect } from 'react-redux';
import { updateSearchPreferencesRequest } from '../../../redux/actions/searchPreferences';

//graphql
import { withApollo } from "react-apollo";
import Mutations from "../../../apollo/mutations/mutations";
import Queries from "../../../apollo/queries/queries";

// common
import Button from "../../common/ModamilyAppButton";
import PageContainer from "../../common/ModamilyPageContainer";
import ModamilyAppLoadingComponent from '../../common/ModamilyAppLoadingComponent';
// modal
import UprgadeModal from "../../modals/ModamilyAppUpgrade";

//local
import SearchPreferencesMenu from './ModamilyAppSearchPreferencesMenu';
//basic search
import LookingFor from './BasicSearch/LookingFor';
import Distance from './BasicSearch/Distance';
import AgeRange from './BasicSearch/AgeRange';
//premium search
import HeightRange from './PremiumSearch/HeightRange';
import EducationLevel from './PremiumSearch/EducationLevel';
import Orientations from './PremiumSearch/Orientations';
import IncomeRange from './PremiumSearch/IncomeRange';
import Religion from './PremiumSearch/Religion';
import Races from './PremiumSearch/Races';
import EyeColor from './PremiumSearch/EyeColor';
import Ethnicity from './PremiumSearch/Ethnicity';
import Politics from './PremiumSearch/Politics';

// cosntants
import Options from "../../../constants/formOptions";

class ModamilyAppSearchPreferences extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			view: 0,
			showUpgrade: false,
			searchPreferences: null,
			loading: false
		};
	}

	componentDidMount() {
		this.fetchPreferences();
	}

	fetchPreferences = async () => {
		try {
			this.setState({ loading:true });
			const { client, updateSearchPreferencesRequest } = this.props;
			const response = await client.query({ query: Queries.getSearchPrefs });
			const result = decode(response.data.getSearchPrefs);
			if(result.action === 'success') {
				updateSearchPreferencesRequest(result.data);
				this.setState({
					searchPreferences: {...result.data},
					loading: false
				});
			} else {
				this.setState({ loading: false });
			}
		} catch(e) {
			this.setState({ loading: false });
			console.log('Error fetching preferences: ',e);
		}
	}

	setView = (index) => {
		this.setState({ view: index });
	};

	getSelectedIndexes = (prefs, options) => {
		if (!prefs) {
			return [];
		}
		let arr = [];

		for (let index = 0; index < prefs.length; index++) {
			const gender = prefs[index];
			let i = -1;
			options.forEach(e => {
				if (e.toUpperCase() === gender.toUpperCase()) {
					i = options.indexOf(e);
				}
			});
			if (i >= 0) {
				arr.push(i);
			}
		}
		return arr;
	};

	showModal = () => {
		this.setState({ showUpgrade: true });
	};

	closeModal = () => {
		this.setState({ showUpgrade: false });
	};

	handleSubmitSearchPreferences = async () => {
		const { client, updateSearchPreferencesRequest, searchPreferencesRdx } = this.props;
		const { searchPreferences } = this.state;
		this.setState({loading: true});
		try {
			const response = await client.mutate({mutation: Mutations.updateSearchPrefs, variables: { ...searchPreferences }});
			const result = decode(response.data.updateSearchPrefs);
			if(result.action === 'success') {

				// fetch users from db
				if(searchPreferences.zipcode === '') {
					searchPreferences.shouldFetchProfiles = true;
				} else if (
					searchPreferencesRdx.country !== searchPreferences.country ||
					searchPreferencesRdx.zipcode !== searchPreferences.zipcode ||
					searchPreferencesRdx.distance !== searchPreferences.distance
				) {
					searchPreferences.shouldFetchProfiles = true;
				} else {
					searchPreferences.shouldFetchProfiles = false;
				}
				updateSearchPreferencesRequest(searchPreferences);
				this.setView(0);
			} else {
				window.alert("Error occured please try again later");
			}
		} catch(err) {
			console.log('error: ', err);
		}
		this.setState({loading: false });
	}

	handleUpdateSearchPreferences= async (searchPreferences) => {
		this.setState({ searchPreferences });
	}

	render = () => {
		const { loading, searchPreferences } = this.state;
		if(loading || !searchPreferences) {
			return <ModamilyAppLoadingComponent />
		}
		let selectedGendersIndexes = this.getSelectedIndexes(
			searchPreferences.gender,
			Options.genders
		);

		let selectedLookingForIndexes = this.getSelectedIndexes(
			searchPreferences.lookingFor,
			Options.lookingFors
		);

		let selectedEducationIndexes = this.getSelectedIndexes(
			searchPreferences.education,
			Options.educationLevels
		);

		let selectedOrientationIndexes = this.getSelectedIndexes(
			searchPreferences.orientations,
			Options.orientation
		);

		let selectedReligionIndexes = this.getSelectedIndexes(
			searchPreferences.religion,
			Options.religion
		);

		
		let selectedPoliticsIndexes = this.getSelectedIndexes(
			searchPreferences.politics,
			Options.politics
		);

		let selectedRacesIndexes = this.getSelectedIndexes(
			searchPreferences.race,
			Options.races
		);

		let selectedEyeColorIndexes = this.getSelectedIndexes(
			searchPreferences.eyeColor,
			Options.eyeColor
		);
		
		let selectedEthnicityIndexes = this.getSelectedIndexes(
			searchPreferences.ethnicity,
			Options.ethnicities
		);

		let children = [
			<SearchPreferencesMenu
				showModal={this.showModal}
				setView={this.setView}
				searchPreferences={searchPreferences}
			/>,
			/** 1  looking for*/ 
			<LookingFor selectedGendersIndexes={selectedGendersIndexes} 
									selectedLookingForIndexes={selectedLookingForIndexes}
									searchPreferences={searchPreferences} 
									handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 2 */ 
			<Distance 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 3 */ 
			<AgeRange 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 4 */ 
			<HeightRange 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 5 */ 
			<EducationLevel selectedEducationIndexes={selectedEducationIndexes} 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 6 */ 
			<IncomeRange 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 7 */ 
			<Religion 
				selectedReligionIndexes={selectedReligionIndexes}
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 8 */ 
			<Races 
				selectedRacesIndexes={selectedRacesIndexes}
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 9 */ 
			<EyeColor 
				selectedEyeColorIndexes={selectedEyeColorIndexes}
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 10 */
			<Ethnicity 
				selectedEthnicityIndexes={selectedEthnicityIndexes}
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/** 11 */
			<Politics 
				selectedPoliticsIndexes={selectedPoliticsIndexes}
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
			/**  12 */ 
			<Orientations selectedOrientationIndexes={selectedOrientationIndexes} 
				searchPreferences={searchPreferences} 
				handleUpdate={this.handleUpdateSearchPreferences}/>,
				
			<div className="searchPrefForm">
				<div className="searchPrefIncomeRangeFormContainer"> </div>
			</div>
		];

		return this.state.view === 0 ? (
			<div className="searchPreferencesPage">
				{children[this.state.view]}
				<UprgadeModal
					closeModal={this.closeModal}
					active={this.state.showUpgrade}
				/>
			</div>
		) : (
			<PageContainer>
				<div className="searchPreferencesPage">
					<div className="formContainer">
						{children[this.state.view]}
					</div>
					<div className="searchPreferencesFormActions">
						<div className="searchPrefCancelButton">
							<Button
								text="Cancel"
								style="white"
								onClick={evt => {
									const { searchPreferencesRdx } = this.props;
									this.setState({ searchPreferences: searchPreferencesRdx }, () => this.setView(0));
								}}
							/>
						</div>
						<div className="searchPrefSubmitButton">
							<Button
								text="Submit"
								style="orangeGradient"
								onClick={this.handleSubmitSearchPreferences}
							/>
						</div>
					</div>
				</div>
			</PageContainer>
		);
	};
}

export default connect(
	({ searchPreferences }) => ({
		searchPreferencesRdx: searchPreferences.items
	}),
	{ updateSearchPreferencesRequest }
)(withApollo(ModamilyAppSearchPreferences))