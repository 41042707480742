import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import Subtitle from "../common/ModmilyAppSubtitle";

/**
 * @property {Object} data  - { religion: null, religionActivity: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppReligion extends React.Component {
	state = {
		data: this.props.data || { religion: null, religionActivity: null }
	};

	beliefOptions = [
		"atheist",
		"christian",
		"catholic",
		"jewish",
		"muslim",
		"buddhist",
		"hindu",
		"orthodox",
		"spiritual"
	];

	activityOptions = [
		"atheist",
		"serious about it",
		"raised that way, but not practicing",
		"not serious about it"
	];

	componentWillMount = () => {
		this.validate();
	};

	validate = () => {
		let data = this.state.data;
		if (!data.religion || !data.religionActivity) {
			this.props.invalidate();
		} else if (data.religion && data.religionActivity) {
			console.log(data);
			this.props.onValidChange(data);
		}
	};

	handleBeliefSelection = (value, index, isActive) => {
		let data = this.state.data;
		data.religion = isActive ? value : null;
		this.props.scrollToBottom && this.props.scrollToBottom();
		this.validate();
	};

	handleActivitySelection = (value, index, isActive) => {
		let data = this.state.data;
		data.religionActivity = isActive ? value : null;
		this.validate();
	};

	render = () => {
		let data = this.state.data;

		const activityIndex = this.activityOptions.indexOf(data.religionActivity);
		const religionIndex = this.beliefOptions.indexOf(data.religion);

		return (
			<div className="religionForm">
				<FormHeader text="Religion" />
				<ButtonGroup
					selected={religionIndex}
					type="radio"
					data={this.beliefOptions}
					onButtonClick={this.handleBeliefSelection}
				/>
				<Subtitle text={"ACTIVITY"} />
				<ButtonGroup
					selected={activityIndex}
					type="radio"
					data={this.activityOptions}
					onButtonClick={this.handleActivitySelection}
				/>
			</div>
		);
	};
}
