import React from 'react';
import Badge from '../common/ModamilyAppBadge';
import RoundImage from '../common/ModamilyAppRoundImage';
import TabMenuItem from '../common/ModamilyAppTabMenuItem';

import { Link, Redirect } from 'react-router-dom';

import { withApollo } from 'react-apollo';
import Queries from '../../apollo/queries/queries';
import decode from 'jwt-decode';

/**
 * @property {Boolean} active - if the menu is displayed or not
 */
class ModamilyAppProfileMenu extends React.Component {
  state = {
    active: this.props.active || false,
    redirect: false,
    redirectTo: null,
    displayUpgrade: false,
    user: null
  };

  async componentDidMount() {
    try {
      const { client } = this.props;
      const user = decode(localStorage.getItem("auth.token"));
    
      if (!user) {
        return <Redirect to="/" />;
      }

      const response = await client.query({ query: Queries.getUser, variables: { id: user.id }});
      if (response && response.data && response.data.user) {
        const result = decode(response.data.user);
        this.setState({ user : result.data.user });
				localStorage.setItem("user", JSON.stringify(result.data.user));
				localStorage.setItem("auth.token", result.data.token); 
      }
    } catch (err ) {
      console.log('error',err);
    }

  }

  redirectToSettings = redirectTo => {
    this.setState({ redirect: true, redirectTo });
  };

  render = () => {
    const { active } = this.props;
    const { user } = this.state;

    if (this.state.redirect) {
      return <Redirect to={'/settings/' + this.state.redirectTo} />;
    }

    let mainImg = null;
    if (user && user.images) {
      for (let index = 0; index < user.images.length; index++) {
        const image = user.images[index];
        if (image.isMain) {
          mainImg = image;
          break;
        }
      }
    }

    let menuContainerClass = active
      ? 'profileMenuActive'
      : 'profileMenuHidden';

    return (
      <div
        ref="profileMenu"
        className={'profileMenu' + ' ' + menuContainerClass}
      >
        <div>
          <div className="profileMenuContainer">
            <div className="profileMenuContent">
              <div className="profileMenuContentContainer">
                <div className="profileMenuImageContainer">
                  <Link to="/settings/editProfile">
                    <Badge type="edit">
                      {user && user.firstName && mainImg && mainImg.path ? (
                        <RoundImage
                          size="Large"
                          image={mainImg ? mainImg.path : null}
                          name={user.firstName}
                          isUrl={true}
                        />) : null 
                      }
                    </Badge>
                  </Link>
                </div>
                <div className="profileMenuNameContainer">
                  <p className="profileName">{user ? user.firstName : ''}</p>
                </div>
                {user && user.subscription ? (
                  user.subscription.subType.toUpperCase() !== 'FREE' ? (
                    <div className="accountTypeContainer">
                      <p className="redText regularWeight">
                        Premium Account
                      </p>
                    </div>
                  ) : null
                ) : null}
              </div>
              <Link to="/settings/searchPreferences">
                <TabMenuItem
                  onClick={() => {}}
                  header="Search Preferences"
                />
              </Link>
              <Link to="/settings/accountDetails">
                {' '}
                <TabMenuItem
                  onClick={() => {}}
                  header="Account Details"
                />
              </Link>
              {user && user.subscription ? (
                user.subscription.subType.toUpperCase() !==
                'FREE' ? null : (
                  <TabMenuItem
                    onClick={() => {
                      this.props.openModal();
                    }}
                    header="Upgrade Account"
                  />
                )
              ) : (
                <TabMenuItem
                  onClick={() => {
                    this.props.openModal();
                  }}
                  header="Upgrade Account"
                />
              )}
              <TabMenuItem
                onClick={() => {
                  window.open('mailto:support@modamily.com', '_blank');
                }}
                header="Contact Modamily"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withApollo(ModamilyAppProfileMenu);