import React from "react";

import decode from "jwt-decode";
import { withApollo } from "react-apollo";
import FormOptions from "../../constants/filterOptions";
import Countries from "../../constants/countries";
import Queries from "../../apollo/queries/queries";

import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import ModamilyAppTabMenuItem from "../common/ModamilyAppTabMenuItem";
import DropDownMenu from "../form/ModamilyAppDropDownMenu";
import TextInput from "../form/ModamilyAppTextInput";
import MultiSlider from "../form/ModamilyAppMultiSliderInput";
import Slider from "../form/ModamilyAppSliderInput";
import SwitchButton from "../common/ModamilyAppSwitchButton";
import Button from "../common/ModamilyAppButton";
import RoundImage from "../common/ModamilyAppRoundImage";
import ModamilyAppTabMenuHeader from "../common/ModamilyAppTabMenuHeader";
import FilterImage from "../../resources/images/appAssets/filter.svg";

import { v4 as uuidv4 } from 'uuid';


class AdminHome extends React.Component {
	state = {
		email: "",
		firstName: "",
		lastName: "",
		gender: [],
		minAge: 18,
		maxAge: 100,
		minIncome: 25000,
		maxIncome: 1000000,
		minHeight: 48,
		maxHeight: 84,
		distance: 1500,
		zipcode: "",
		country: "",
		orientation: "",
		lookingFor: [],
		religion: [],
		race: [],
		ethnicity: [],
		eyeColor: [],
		hairColor: [],
		minHeight: 48,
		maxHeight: 84,
		heightIsMetric: false,
		count: 0,
		page: 0,
		showMenu: false,
		key: Math.random * 100,
		users: [],
		loading: false
	};

	count = 0;
	limit = 200;
	totalCount = 0;

	generateUserList = data => {
		let arr = [];

		if (!data) {
			return "No users found!";
		}

		for (let index = 0; index < data.length; index++) {
			const user = data[index];

			//
			arr.push(
				<div className="adminRowUserContainer" key={uuidv4()}>
					<RoundImage
						isUrl
						image={user.images ? user.images[0].path : null}
						name={user.firstName}
					/>
					<ModamilyAppTabMenuItem
						key={user.id + "_" + user.firstName}
						header={user.firstName + " " + user.lastName}
						data={user.email}
						actionType="click"
						onClick={() => {
							this.props.selectUser(user);
						}}
					/>
				</div>
			);
		}
		return <div className="adminUserList" key={uuidv4()}>{arr}</div>;
	};

	genderFilterSelect = gender => {
		if (gender == "all") {
			this.setState({ gender: [] }, () => this.handleGetUsersRequest());
		} else {
			this.setState({ gender: [gender] }, () => this.handleGetUsersRequest());
		}
	};

	updateField = (key, value) => {
		if (value === "all" || value === "no preference") {
			value = [];
			if (key === "country") {
				value = "";
			}
		}
		let state = this.state;
		console.log('value', value);
		state[key] = value;
		this.setState(state);
		this.onEnterPress();
	};

	onEnterPress = () => {
		this.handleGetUsersRequest();
	};

	exportCSV = users => {
		let cols;

		let userKeys = [
			"id",
			"email",
			"firstName",
			"lastName",
			"country",
			"zipcode",
			"age",
			"gender",
			"religion",
			"orientation",
			"race",
			"astralSign",
			"eyeColor",
			"hairColor",
			"height",
			"heightIsMetric",
			"politics",
			"income",
			"phone",
			"diet",
			"bodyType",
			"bloodType",
			"children",
			"education",
			"school",
			"createdAt",
			"updatedAt"
		];

		cols = userKeys.join(",");
		Countries.countryNames.push("all");

		let csv = cols + "\n";
		let subscribedCSV =
			cols + ",subType,storeSubscription,subCreatedAt,subUpdatedAt" + "\n";

		for (let index = 0; index < users.length; index++) {
			const user = users[index];

			let values = [];
			for (let j = 0; j < userKeys.length; j++) {
				const key = userKeys[j];
				values.push(user[key]);
			}

			if (user.subscription) {
				if (
					user.subscription.subType != "free" ||
					user.subscription.storeSubscription == "legacy"
				) {
					let subscription = user.subscription;

					values.push(
						subscription.subType,
						subscription.storeSubscription,
						subscription.createdAt,
						subscription.updatedAt
					);

					subscribedCSV += values.join(",") + "\n";
				} else {
					csv += values.join(",") + "\n";
				}
			} else {
				csv += values.join(",") + "\n";
			}
		}

		let currentdate = new Date();
		let datetime =
			currentdate.getMonth() +
			1 +
			"/" +
			currentdate.getDate() +
			"/" +
			currentdate.getFullYear() +
			"_" +
			currentdate.getHours() +
			":" +
			currentdate.getMinutes() +
			":" +
			currentdate.getSeconds();

		let hiddenElement = document.createElement("a");
		hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
		hiddenElement.target = "_blank";
		hiddenElement.download = `free_users_${datetime}.csv`;
		hiddenElement.click();
		let hiddenSubbElement = document.createElement("a");
		hiddenSubbElement.href =
			"data:text/csv;charset=utf-8," + encodeURI(subscribedCSV);
		hiddenSubbElement.target = "_blank";
		hiddenSubbElement.download = `paid_users_${datetime}.csv`;
		hiddenSubbElement.click();
	};

	filterButtonClick = () => {
		this.setState({ showMenu: !this.state.showMenu });
	};

	componentDidMount() {
		this.handleGetUsersRequest();
	}

	handleGetUsersRequest = async (page = this.state.page) => {
		this.setState({ loading: true });
		try {
			const {client} = this.props;
			const {
				minHeight,
				maxHeight,
				minAge,
				maxAge,
				minIncome,
				maxIncome,
				distance,
				gender,
				zipcode,
				country,
				religion,
				ethnicity,
				education,
				eyeColor,
				hairColor,
				race,
				politics,
				lookingFor,
				heightIsMetric,
				orientation,
				lastName,
				firstName,
				email
			} = this.state;

			const response = await client.query({ query: Queries.adminGetUsers, variables: {
				minHeight,
				maxHeight,
				minAge,
				maxAge,
				minIncome,
				maxIncome,
				distance,
				gender,
				zipcode,
				country,
				religion,
				ethnicity,
				education,
				eyeColor,
				hairColor,
				race,
				politics,
				lookingFor,
				page,
				heightIsMetric,
				orientation,
				lastName,
				firstName,
				email
			}});

			const result = decode(response.data.adminGetUsers);
			if( result && result.data && result.data.users) {
				this.setState({
					users: result.data.users,
					count: result.data.users.length,
					totalCount: result.data.totalCount,
					page
				})
			}
		} catch(err) {
		this.setState({ loading: false });
			console.log('Error: ', err);
		}
		this.setState({ loading: false });
	}

	render = () => {
		const { users } = this.state;

		let menu = (
			<div
				className={
					"adminFilterMenu" +
					(+this.state.showMenu ? " adminMenuActive" : " adminMenuHidden")
				}>
				<div className="fd-row">
					<div className="sliderControl">
						<MultiSlider
							units="years old"
							data={[this.state.minAge, this.state.maxAge]}
							type="age"
							min={18}
							max={100}
							step={1}
							onChange={values => {
								this.setState({ minAge: values[0], maxAge: values[1] }, () => this.handleGetUsersRequest());
							}}
						/>

						<MultiSlider
							units="$"
							data={[this.state.minIncome, this.state.maxIncome]}
							type="income"
							min={25000}
							max={1000000}
							step={5000}
							onChange={values => {
								this.setState({
									minIncome: values[0],
									maxIncome: values[1]
								}, () => this.handleGetUsersRequest());
							}}
						/>
						<div className="heightSwitchButtonContainer fd-row">
							<p>Inches</p>
							<div className="heightSwitchButton">
								<SwitchButton
									active={this.state.heightIsMetric}
									onClick={() => {
										let heightIsMetric = !this.state.heightIsMetric;
										this.setState({ heightIsMetric }, () => this.handleGetUsersRequest());
									}}
								/>
							</div>
							<p>centimeters</p>
						</div>
						<MultiSlider
							key={this.state.heightIsMetric}
							units={this.state.heightIsMetric ? "cm" : "in"}
							data={[this.state.minHeight, this.state.maxHeight]}
							type="height"
							min={this.state.heightIsMetric ? 121 : 48}
							max={this.state.heightIsMetric ? 213 : 84}
							step={1}
							onChange={values => {
								this.setState({
									minHeight: values[0],
									maxHeight: values[1]
								}, () => this.handleGetUsersRequest());
							}}
						/>
						<Slider
							min={0}
							max={10000}
							step={1}
							units="distance"
							value={this.state.distance}
							onChange={value => {
								this.setState({ distance: value }, () => this.handleGetUsersRequest());
							}}
						/>
						<TextInput
							value={this.state.email}
							label="Email"
							onEnterPress={this.onEnterPress}
							onContentChange={data => {
								this.updateField("email", data ? data : "");
							}}
						/>
						<TextInput
							value={this.state.firstName}
							label="First Name"
							onEnterPress={this.onEnterPress}
							onContentChange={data => {
								this.updateField("firstName", data ? data : "");
							}}
						/>
						<TextInput
							value={this.state.lastName}
							label="Last Name"
							onEnterPress={this.onEnterPress}
							onContentChange={data => {
								this.updateField("lastName", data ? data : "");
							}}
						/>
					</div>
					<div className="dropMenuControls">
						<DropDownMenu
							data={FormOptions.genders}
							onItemClick={this.genderFilterSelect}
							type="Gender"
							title="Gender Filter"
							selected={FormOptions.genders.indexOf(this.state.gender[0])}
						/>
						<DropDownMenu
							data={FormOptions.lookingFors}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ lookingFor: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ lookingFor: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Looking For"
							title="Looking For Filter"
							selected={FormOptions.lookingFors.indexOf(
								this.state.lookingFor[0]
							)}
						/>
						<DropDownMenu
							data={FormOptions.religion}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ religion: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ religion: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Relgion"
							title="Religion Filter"
							selected={FormOptions.religion.indexOf(this.state.religion[0])}
						/>
						<DropDownMenu
							data={FormOptions.ethnicities}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ ethnicity: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ ethnicity: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Ethnicity"
							title="Ethnicity Filter"
							selected={FormOptions.ethnicities.indexOf(
								this.state.ethnicity[0]
							)}
						/>
						<DropDownMenu
							data={FormOptions.races}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ race: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ race: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Race"
							title="Race Filter"
							selected={FormOptions.races.indexOf(this.state.race[0])}
						/>
						<DropDownMenu
							data={FormOptions.eyeColor}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ eyeColor: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ eyeColor: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Eye Color"
							title="Eye Color Filter"
							selected={FormOptions.eyeColor.indexOf(this.state.eyeColor[0])}
						/>
						<DropDownMenu
							data={FormOptions.orientation}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ orientation: "" }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ orientation: data }, () => this.handleGetUsersRequest());
								}
							}}
							type="Sexual Orientation"
							title="Sexual Orientation Filter"
							selected={FormOptions.orientation.indexOf(this.state.orientation)}
						/>
						<DropDownMenu
							data={FormOptions.hairColor}
							onItemClick={data => {
								if (data === "all") {
									this.setState({ hairColor: [] }, () => this.handleGetUsersRequest());
								} else {
									this.setState({ hairColor: [data] }, () => this.handleGetUsersRequest());
								}
							}}
							type="Hair Color"
							title="Hair Color Filter"
							selected={FormOptions.hairColor.indexOf(this.state.hairColor[0])}
						/>
						<DropDownMenu
							data={Countries.countryNames}
							onItemClick={string => {
								this.updateField("country", string);
							}}
							type="Country"
							title="Country"
							selected={Countries.countryNames.indexOf(this.state.country)}
						/>
						<TextInput
							value={this.state.zipcode}
							label="Zipcode"
							onEnterPress={this.onEnterPress}
							onContentChange={data => {
								this.updateField("zipcode", data ? data : "");
							}}
						/>
					</div>
				</div>
				<div className="buttonControls">
					<div className="refreshUserListBtn">
						<Button
							style="orangeGradient"
							onClick={this.onEnterPress}
							text="Refresh"
						/>
					</div>
					<div className="refreshUserListBtn">
						<Button
							style="red"
							text="Export as CSV"
							onClick={() => {
								this.exportCSV(users);
							}}
						/>
					</div>
				</div>
			</div>
		);

		let menuButton = (
			<div onClick={this.filterButtonClick} className="filterButton">
				<div className="buttonImageContainer">
					<img alt={null} src={FilterImage} className="filterImage"></img>
				</div>
			</div>
		);

		let pageAmount = this.state.totalCount / this.limit;

		let pageComponents = [];

		let startingIndex =
			this.state.page >= 8 ? this.state.page : 0;
		let endingIndex =
			pageAmount >= 8
				? this.state.page >= 8
					? this.state.page + 9
					: 10
				: pageAmount;

		for (
			let index = startingIndex;
			index < endingIndex;
			index++
		) {
			pageComponents.push(
				<div className="pageButton" key={index + this.state.page}>
					<Button
						style={
							index == this.state.page
								? "white"
								: "orangeGradient"
						}
						onClick={() => {
							if (index != this.state.page)
								this.handleGetUsersRequest(index)
						}}
						text={index + 1}
					/>
				</div>
			);
		}
		if (this.state.page >= 8) {
			pageComponents.unshift(
				<div className="pageButton">
					<Button
						style={"orangeGradient"}
						onClick={() => {
							this.handleGetUsersRequest(this.state.page >= 8 ? 0 : this.state.page - 1)
						}}
						text={this.state.page >= 8 ? 1 : this.state.page}
					/>
				</div>
			);
		}
		return (
			<div className="adminHome">
				<div className="fd-row">
					{menu}
					{menuButton}
					{this.state.showMenu ? null : (
							<div className="adminUserListContainer" key={Math.random() * 100}>
								{ this.state.loading ? <ModamilyAppLoadingComponent /> : null}
								<div className="paginationContainer fd-row">
									{pageComponents}
								</div>
								<ModamilyAppTabMenuHeader
									key={this.state.count}
									text={`showing ${this.state.count * this.state.page +
										1 +
										"-" +
										(this.state.count * this.state.page +
											this.state.count)} results of ${this.state.totalCount}`}
								/>
								{
									this.generateUserList(
										this.state.users
									)
								}
							</div>
					) }
				</div>
			</div>
		);
	};
}

export default withApollo(AdminHome);