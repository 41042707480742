import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";

/**
 * @property data [Object] - { lookingFor:null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppLookingFor extends React.Component {
	state = {
		data: this.props.data || { lookingFor: [] }
	};

	componentDidMount = () => {
		let data = this.state.data;
		if (data.lookingFor.length > 0) {
			this.props.onValidChange({ lookingFor: data });
		}
	};

	buttonOptions = [
		"dating leading to children",
		"co-parent",
		"open to being a known donor",
		"looking for a known donor",
		"surrogate"
	];

	componentDidMount = () =>  {
		
	}

	componentWillMount = () => {
		let data = this.state.data;
		if (data.length > 0) {
			this.props.onValidChange({ lookingFor: data });
		} else {
			this.props.invalidate();
		}
	};

	selectItem = item => {
		let data = this.state.data.lookingFor;
		if (data.indexOf(item) > -1) {
			data.splice(data.indexOf(item), 1);
		} else {
			data.push(item);
		}

		if (data.length > 0) {
			this.props.onValidChange({ lookingFor: data });
		} else {
			this.props.invalidate();
		}
		this.setState({ data: { lookingFor: data } });
	};

	render() {
		let selectedIndexes = [];

		for (let index = 0; index < this.state.data.lookingFor.length; index++) {
			const lookingFor = this.state.data.lookingFor[index];
			let i = this.buttonOptions.indexOf(lookingFor);
			if (i != -1) {
				selectedIndexes.push(i);
			}
		}

		console.log(this.state.data.lookingFor, selectedIndexes)

		return (
			<div className="lookingForForm">
				<ModamilyAppFormHeader text="Looking For" />
				<ModamilyAppButtonGroup
					type="check"
					data={this.buttonOptions}
					onButtonClick={this.selectItem}
					selected={selectedIndexes}
				/>
			</div>
		);
	}
}
