import React from "react";
import FormHeader from "../form/ModamilyAppFormHeader";
// import GridDraggable, { Section } from "grid-draggable";
import Badge from "../common/ModamilyAppBadge";
import RoundImage from "../common/ModamilyAppRoundImage";
// import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";

// import imageCompression from "browser-image-compression";

import Reorder, {
  reorder,
  // reorderImmutable,
  // reorderFromTo,
  // reorderFromToImmutable
} from "react-reorder";

function buildFileSelector() {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  return fileSelector;
}

/**
 * @property {Object} data - {images: []} - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppProfilePhotos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data || { images: [] },
      isProfilePhoto: false,
      loading: false
    };

    this.fileSelector = buildFileSelector();
    this.fileSelector.onchange = this.fileSelectorClick;
  }
  filter = () => {};

  fileSelectorClick = async evt => {
    try {
      let files = evt.target.files;
      let { isProfilePhoto } = this.state;
      let images = this.state.data.images;
      var reader = new FileReader();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
  
        reader.onload = e => {
          file.base64 = e.target.result;
  
          if (isProfilePhoto) images = [file, ...images];
          else images = [...images, file];
  
          let profImg = images[0];
          this.setState(
            { data: { images }, profileImage: profImg, isProfilePhoto: false },
            this.validate
          );
        };
        reader.readAsDataURL(file);
      }
    } catch(err) {
      console.log('Error: ', err);
    }
  };

  urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function(res) {
        return res.arrayBuffer();
      })
      .then(function(buf) {
        return new File([buf], filename, { type: mimeType });
      }).catch(err=> {
        console.log('error trying to convert url to file: ', err)
      })
  };

  validate = () => {
    let images = this.state.data.images;
    let atLeast1 = false;
    for (let index = 0; index < images.length; index++) {
      const image = images[index];
      atLeast1 = !!image.base64;
      if (atLeast1) {
        break;
      }
    }
    if (atLeast1) {
      this.props.onValidChange({ images });
    } else {
      this.props.invalidate();
    }
  };

  onClick = (hasImage) => {
    if (!hasImage) {
      this.fileSelector.click();
    }
  };

  removeImage = index => {
    let images = this.state.data.images;

    images.splice(index, 1);

    this.setState({ data: { images } }, this.validate);
  };

  onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
    let images = this.state.data.images;
    console.log('images', images);
    if (!(nextIndex > images.length - 1)) {
      console.log("test")
      const orderedImages = reorder(images, previousIndex, nextIndex);
      this.setState({
        data: { images: orderedImages }
      },() => this.props.onValidChange({ images: orderedImages }));
    }
  };

  onProfilePhotoChange = e => {
    e.stopPropagation();
    this.setState({ isProfilePhoto: true }, () => {
      this.onClick(false, 0);
    });
  };

  render = () => {
    let images = this.state.data.images;
    let imageComponents = [];

    let profImg = images[0];

    for (let index = 0; index < images.length; index++) {
      const image = images[index];

      imageComponents.push(
        <div
          key={image.name + Math.floor(Math.random() * 100)}
          className="draggableImageContainer"
        >
          {
            images && images.length > 1 ?
            <Badge
              type="delete"
              onClick={() => {
                this.removeImage(index);
              }}
            >
              <RoundImage size="Regular" image={image.base64} />
            </Badge> :
            <RoundImage size="Regular" image={image.base64} />
          }
        </div>
      );
    }

    while (imageComponents.length < 6) {
      imageComponents.push(
        <div
          key={imageComponents.length}
          className="draggableImageContainer"
          onClick={e => {
            this.onClick(false, imageComponents.length - 1);
            e.stopPropagation();
          }}
        >
          <RoundImage size="Regular" isUrl={false} image={null} />
        </div>
      );
    }

    return (
      <div className="profilePhotoForm">
        <FormHeader text="Profile Photos" />
        <div
          className="profilePhotoContainer"
          onClick={this.onProfilePhotoChange}
        >
          <RoundImage
            key={profImg ? profImg.name : ""}
            size="Large"
            image={profImg ? profImg.base64 : null}
          />
        </div>
        <div
          ref={gridElement => (this.gridElement = gridElement)}
          className="onboardingImageListContainer"
        >
          <Reorder
            className="onboardingPhotoGrid"
            itemKey="id"
            reorderId="onboardingProfilePhotos" // Unique ID that is used internally to track this list (required)
            reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
            component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
            placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
            holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
            touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
            mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
            onReorder={this.onReorder} // Callback when an item is dropped (you will need this to update your state)
            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
            disabled={false} // Disable reordering (optional), defaults to false
            disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
          >
            {
              imageComponents
              /*
											  Note this example is an ImmutableJS List so we must convert it to an array.
											  I've left this up to you to covert to an array, as react-reorder updates a lot,
											  and if I called this internally it could get rather slow,
											  whereas you have greater control over your component updates.
											  */
            }
          </Reorder>
        </div>
      </div>
    );
  };
}


