exports.genders = ["male", "female", "trans male", "trans female"];

exports.educationLevels = [
	"undergrad",
	"postgrad",
	"highschool or some equivalent",
	"some college",
	"no preference"
];

exports.politics = [
	"liberal",
	"moderate",
	"conservative",
	"other",
	"no preference"
];

exports.religion = [
	"atheist",
	"christian",
	"catholic",
	"jewish",
	"muslim",
	'hinduism',
	"buddhist",
	"orthodox",
	"spiritual",
	"no preference"
];

exports.ethnicities = [
	"african",
	"brazilian",
	"caribbean",
	"chinese",
	"czech",
	"danish",
	"dutch",
	"english",
	"filipino",
	"french",
	"german",
	"hungarian",
	"indian",
	"irish",
	"japanese",
	"jewish",
	"korean",
	"lebanese",
	"mexican",
	"native american",
	"norwegian",
	"pacific islander",
	"polish",
	"portuguese",
	"russian",
	"scottish",
	"slavic",
	"spanish",
	"swedish",
	"thai",
	"welsh",
	"prefer not to say"
];

exports.eyeColor = [
	"black",
	"blue",
	"brown",
	"green",
	"hazel",
	"no Preference"
];

exports.hairColor = [
	"auburn",
	"brown",
	"black",
	"blonde",
	"dark brown",
	"dirty blonde",
	"light brown",
	"red",
	"strawberry blonde",
	"orange",
	"gray",
	"other",
	"no preference"
];

exports.races = [
	"asian",
	"black",
	"white",
	"hispanic/latin",
	"native american",
	"middle eastern",
	"mixed",
	"no preference"
];

exports.lookingFors = [
	"dating leading to children",
	"co-parent",
	"open to being a known donor",
	"looking for a known donor",
	"surrogate"
];

exports.bodyTypes = [
	"thin",
	"athletic",
	"normal",
	"curvy",
	"overweight",
	"prefer not to say"
];

exports.diet = [
	"anything goes",
	"healthy",
	"vegetarian",
	"vegan",
	"kosher",
	"only eat to survive",
	"other"
];

exports.drinks = ["very often", "socially", "rarely", "not at all"];

exports.drugs = ["often", "sometimes", "never"];

exports.marijuana = ["yes", "sometimes", "no", "prefer not to say"];

exports.smoke = ["yes", "socially", "no"];

exports.fertilization = [
	"IVF",
	"home insemination",
	"artificial insemination",
	"natural insemination",
	"depends on the person"
];

exports.bloodType = ["A+", "A-", "B+", "B-", "AB+", "O+", "O-"];

exports.children = ["I have none", "I have one", "I have two or more children"];

exports.orientation = ["straight", "gay", "bi", "undeclared", "prefer not to say"]