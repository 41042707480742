import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import StripeCheckout from 'react-stripe-checkout';
import decode from 'jwt-decode';
import clsx from 'clsx';

import LoadingComponent from '../common/ModamilyAppLoadingComponent';
import ModamilyAppFormHeader from '../form/ModamilyAppFormHeader';
import ModamilyAppButton from '../common/ModamilyAppButton';
import Badge from '../common/ModamilyAppBadge';

import Queries from '../../apollo/queries/queries';
import Mutations from '../../apollo/mutations/mutations';
import SubscriptionOptions from '../../constants/subscriptionTypes';
import config from '../../config/config';

import closeImg from '../../resources/images/appAssets/closePurple.png';

class UpgradeModalButton extends Component {
  render() {
    // prettier-ignore
    const { duration, price, savings, totalCost, onClick, selected } = this.props;
    const classes = {
      wrapper: clsx('upgradeModalButton', selected && 'selectedUpgradeButton')
    };

    return (
      <div onClick={onClick} className={classes.wrapper}>
        <div className="upgradeModalButtonContainer">
          <p className="upgradeButtonDurationText">
            {`${duration} month`}
            {duration > 1 && 's'}
          </p>
          <p className="upgradeButtonPrice">{`$${price}/mo ($${totalCost})`}</p>
          {savings && (
            <p className="redText regularWeight">{`${savings} savings`}</p>
          )}
        </div>
      </div>
    );
  }
}

/**
 * @property {Function} closeModal - function used to close the modal
 * @property {Boolean} active - boolean determining if the modal is shown or hidden
 */
export default class ModamilyAppUpgradeModal extends React.Component {
  state = {
    subscription: null,
    valid: false
  };

  validate = () => {
    let query = this.state.subscriptionQuery;
    if (query.type) {
      this.setState({ valid: true });
    }
  };

  handleToken = (token, upgradeAccount) => {
    // console.log(this.state.subscriptionQuery);
    console.log('token', token);

    let str = JSON.stringify(token);
    // console.log(str);
    upgradeAccount({
      variables: {
        subType: '' + this.state.subscriptionQuery.type,
        price: '' + this.state.subscriptionQuery.price,
        storeSubscription: '' + this.state.subscriptionQuery.store,
        storeReceipt: '' + str
      }
    }).then(data => {
      this.props.closeModal();
      window.location.reload();
    });
  };

  updateSubscription = sub => {
    this.setState({ subscriptionQuery: sub }, this.validate);
  };

  renderUpgradeSubscription = () => {
    const user = JSON.parse(localStorage['user']);

    return (
      <Mutation mutation={Mutations.upgradeAccount}>
        {(upgradeAccount, { loading, error, data }) => {
          if (loading) return null;
          if (error) console.log(error);
          let buttons = [];

          for (let index = 0; index < SubscriptionOptions.length; index++) {
            const option = SubscriptionOptions[index];
            let button = (
              <UpgradeModalButton
                key={index}
                {...option}
                selected={
                  this.state.subscriptionQuery
                    ? this.state.subscriptionQuery.type === option.type
                    : false
                }
                onClick={() => {
                  this.updateSubscription({
                    type: option.type,
                    price: option.price,
                    store: 'stripe'
                  });
                }}
              />
            );
            if (option.type === '12m') {
              buttons.push(
                <Badge
                  key={index + 'badge'}
                  type="text"
                  text="Most Popular"
                  color="red"
                >
                  {button}
                </Badge>
              );
            } else {
              buttons.push(button);
            }
          }

          buttons.reverse();

          return (
            <div className="upgradeAccountButtons">
              <div className="upgradeAccountButtonsContainer">{buttons}</div>
              <div className="upgradeAccountFormActions">
                <div className="upgradeAccountUpgradeButtonContainer">
                  <StripeCheckout
                    email={user.email}
                    stripeKey={config.stripePublicKey}
                    token={token => this.handleToken(token, upgradeAccount)}
                  >
                    <ModamilyAppButton
                      text="Upgrade"
                      style="orangeGradient"
                      disabled={!this.state.valid}
                    />
                  </StripeCheckout>
                </div>
                <div className="upgradeAccountCancelSubscriptionContainer">
                  <ModamilyAppButton
                    text="Cancel"
                    onClick={this.props.closeModal}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  };

  renderEditSubscription = subscription => {
    let user = JSON.parse(localStorage['user']);

    return (
      <Mutation mutation={Mutations.upgradeAccount}>
        {(mutation, { loading, error, data }) => {
          if (loading) {
            return null;
          }
          if (error) {
            console.log(error);
          }
          let buttons = [];

          for (let index = 0; index < SubscriptionOptions.length; index++) {
            const option = SubscriptionOptions[index];
            let button = (
              <UpgradeModalButton
                key={index}
                {...option}
                selected={
                  this.state.subscriptionQuery
                    ? this.state.subscriptionQuery.type === option.type
                    : false
                }
                onClick={() => {
                  this.updateSubscription({
                    type: option.type,
                    price: option.price,
                    store: 'stripe'
                  });
                }}
              />
            );
            if (option.type === subscription.subType) {
              buttons.push(
                <Badge
                  key={index + 'badge'}
                  type="text"
                  text="Your Current Plan"
                  color="red"
                >
                  {button}
                </Badge>
              );
            } else {
              buttons.push(button);
            }
          }
          buttons.reverse();

          return (
            <div className="upgradeAccountButtons">
              <div className="upgradeAccountButtonsContainer">{buttons}</div>
              <div className="upgradeAccountFormActions">
                <div className="upgradeAccountUpgradeButtonContainer">
                  <StripeCheckout
                    email={user.email}
                    stripeKey={config.stripePublicKey}
                    token={token => {
                      // console.log(this.state.subscriptionQuery);
                      let str = JSON.stringify(token);
                      // console.log(str);
                      mutation({
                        variables: {
                          subType: '' + this.state.subscriptionQuery.type,
                          price: '' + this.state.subscriptionQuery.price,
                          storeSubscription:
                            '' + this.state.subscriptionQuery.store,
                          storeReceipt: str
                        }
                      }).then(data => {
                        this.props.closeModal();
                        window.location.reload();
                      });
                    }}
                  >
                    <ModamilyAppButton
                      text="Upgrade"
                      style="orangeGradient"
                      disabled={!this.state.valid}
                    />
                  </StripeCheckout>
                </div>
                <div className="upgradeAccountCancelSubscriptionContainer">
                  <ModamilyAppButton
                    text="Cancel Subscription"
                    onClick={e => {
                      mutation({
                        variables: {
                          subType: 'free',
                          price: '0.00',
                          storeSubscription: 'stripe',
                          storeReceipt: null
                        }
                      }).then(data => {
                        this.props.closeModal();
                        window.location.reload();
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  };

  render = () => {
    let active = this.props.active;

    return (
      <Query query={Queries.getSubscription}>
        {({ loading, data, error }) => {
          if (loading) {
            return null;
          }
          let subscription = null;
          if (data) {
            // console.log(data);
            let response = decode(data.getSubscription);
            if (response.action === 'success') {
              subscription = response.data.subscription;
              let user = JSON.parse(localStorage.getItem('user'));
              user.subscription = subscription;
              localStorage.setItem('user', JSON.stringify(user));
            }
          }

          return (
            <div
              className={
                'modalCurtain' + (active ? ' curtainDrag' : ' modalHidden')
              }
            >
              <div className="upgradeModalCard">
                <div className="upgradeModalCardHeader">
                  <div
                    className="upgradeModalCloseButton"
                    onClick={this.props.closeModal}
                  >
                    <img className="closeButton" src={closeImg} />
                  </div>
                  <div className="upgradeModalHeaderContent">
                    <ModamilyAppFormHeader text="Upgrade today!" />

                    <p className="greyText semiBoldWeight">
                      PREMIUM SUBSCRIPTION FEATURES
                    </p>
                    <p>See who likes you</p>
                    <p>Advanced search filters</p>
                    <p>Message read reciepts</p>
                    <p>Unlimited extends to message</p>
                    <p>Unlimited messaging</p>
                    <p>No Ads</p>
                  </div>
                </div>
                <div className="upgradeModalCardBody">
                  {subscription && subscription.subType.toUpperCase() != 'FREE'
                    ? this.renderEditSubscription(subscription)
                    : this.renderUpgradeSubscription()}
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  };
}
