import React, { Component } from 'react';
import { Redirect } from "react-router-dom";


import ModamilyAppLikedUserCard from '../components/likes/ModamilyAppLikedUserCard';
import ModamilyAppLoadingComponent from '../components/common/ModamilyAppLoadingComponent';
import ModamilyAppLikeLoadingComponent from "../components/common/ModamilyAppLikeLoadingComponent";
import ModamilyAppRoundImage from '../components/common/ModamilyAppRoundImage';
import ModamilyAppNavBar from '../components/common/ModamilyAppNavBar';
import FullProfile from '../components/modals/ModamilyAppFullProfile';
import UpgradeModal from '../components/modals/ModamilyAppUpgrade';
import ModamilyAppMatchModal from "../components/modals/ModamilyAppMatchModal";

import Mutations from '../apollo/mutations/mutations';
import Queries from '../apollo/queries/queries';
import { withApollo } from 'react-apollo';
import decode from 'jwt-decode';

// redux
import { connect } from 'react-redux';
import { setLikeRequest, setDislikeRequest } from '../redux/actions/browse';

class ModamilyAppLikes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayProfile: false,
      showMatchModal: false,
      displayUpgrade: false,
      selectedUser: -1,
      otherUser: null,
      currentUser: null,
      likes: [],
      likedBy: [],
      likeLoading: false,
      dislikeLoading: false,
      generalLoading: false
    };
  }

  async componentDidMount() {
    const { client } = this.props;
    
    this.setState({
      generalLoading: true,
    })
    const query = new URLSearchParams(this.props.location.search);

    let currentUser = decode(localStorage.getItem("auth.token"));
    if (!currentUser) {
      return <Redirect to="/" />;
    }
    this.state.currentUser = currentUser;

    const upgrade = query.get('upgrade')
    if(upgrade) {
      this.openUpgrade();
    }
    const res = await client.query({ query: Queries.getLikes });
    this.handlegetLikesResponse(res.data)
    const pid = query.get('pid');
    if(pid) {
      this.showProfileHandler(pid);
    }
    
    this.setState({
      generalLoading: false,
    })
  }

  handlegetLikesResponse = (data) => {
    try {
      if (data) {
        let response = decode(data.getLikes);
        const likes = response.userLiked;
        const likedBy = response.likedUser;
        this.setState({
          likes,
          likedBy
        })
        
      }
    } catch(err) {
      console.log('err',err);
    }
  }
  showProfileHandler = (pid) => {
    const {likes} = this.state;
    const user = likes.find(user => user.id === pid);
    console.log('user', user);
    console.log('likes', likes);
    this.setState({ displayProfile: true, selectedUser: user });
  }

  scrollHorizontally = e => {
    e = window.event || e;
    var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
    document.getElementById('userLikesRow').scrollLeft -= delta * 40; // Multiplied by 40
    e.preventDefault();
  };

  componentWillUnmount = () => {
    if (document.getElementById('userLikesRow')) {
      document
        .getElementById('userLikesRow')
        .removeEventListener('mousewheel', this.scrollHorizontally);
      document
        .getElementById('userLikesRow')
        .removeEventListener('DOMMouseScroll', this.scrollHorizontally);
    }
  };

  openProfile = user => {
    this.setState({ displayProfile: true, selectedUser: user });
  };

  closeProfile = () => {
    this.setState({ displayProfile: false });
  };

  openUpgrade = () => {
    this.setState({ displayUpgrade: true });
  };

  closeUpgrade = () => {
    this.setState({ displayUpgrade: false });
  };

  renderLikedUsersSection = likes => {
    let likeComponents = [];
    for (let index = 0; index < likes.length; index++) {
      const like = likes[index];
      likeComponents.push(
        <div
          key={like.email}
          className="likeUserContainer"
          onClick={() => {
            this.openProfile(like);
          }}
        >
          <ModamilyAppRoundImage
            isUrl={true}
            image={like.images[0].path}
            name={like.firstName}
          />
          <div className="likeNameContainer">
            <p>{like.firstName ? like.firstName : 'Unknown'}</p>
          </div>
        </div>
      );
    }

    if (likes.length > 8) {
      likeComponents.unshift(
        <div className="likeUserContainer">
          <div className="likedModel">
            <span>{likes.length}+</span>
          </div>
        </div>
      );
    }

    if (likes.length === 0) {
      likeComponents.push(
        <div className="noLikedUsers" key={'not_users'}>
          <p className="noLikedUsersText">You have not liked any one yet</p>
        </div>
      );
    }

    return (
      <div className="userLikesContainer">
        <div className="userLikesHeader">
          <p className="greyText semiBoldWeight">YOU'VE LIKED</p>
        </div>
        <div className="likesRowContainer">
          <div
            id="userLikesRow"
            ref={e => {
              if (e) {
                e.addEventListener(
                  'DOMMouseScroll',
                  this.scrollHorizontally,
                  false
                );
                e.addEventListener(
                  'mousewheel',
                  this.scrollHorizontally,
                  false
                );
              }
            }}
            className="userLikesRow"
          >
            {likeComponents}
          </div>
        </div>
      </div>
    );
  };

  handleCardAction = async (liked, user) => {
    let { likedBy } = this.state;
    const { client } = this.props;
    try {
      this.setState({likeLoading: !!liked, dislikeLoading: !liked});
      const res = await client.mutate({ mutation: Mutations.addRelationship, variables: { relationship: liked, userb: user.id }});
      let response = decode(res.data.addRelationship);
      if (response.action === "success" ) {
        
        if(!!liked) {
          this.props.setLikeRequest(user.id);
        } else {
          this.props.setDislikeRequest(user.id);
        }

        likedBy = likedBy.filter(l => l.id !== user.id);
        
        this.setState({ likedBy, likeLoading: false, dislikeLoading: false, fullProfileUser: null });

        if (response.data.likedBack) {
          this.setState({showMatchModal: true, otherUser: user});
        }
      } else {
        console.log('Error liking user');
        this.setState({ likeLoading: false, fullProfile: false, fullProfileUser: null });
      }
    } catch (err) {
      console.log('Error handling card actions', err);
    }
  }

  renderLikedBySection = likedBy => {
    let likeComponents = [];
    let overlay = null;

    let user = JSON.parse(localStorage['user']);

    if (user.subscription.subType.toUpperCase() === 'FREE') {
      overlay = (
        <div className="noUsersLiked">
          <div className="noUsersLiked-text-container">
            <p className="noUsersLikedText">Upgrade to view who</p>
            <p className="noUsersLikedText">have liked you!</p>
            <p className="noUsersLikedLink" onClick={this.openUpgrade}>Upgrade Account</p>
          </div>
        </div>
      );
    }
    for (let index = 0; index < likedBy.length; index++) {
      const like = likedBy[index];
      likeComponents.push(
        <div
          key={like.email}
          className={
            user.subscription.subType.toUpperCase() === 'FREE'
              ? 'premiumOverlayBlur'
              : null
          }
        >
          <ModamilyAppLikedUserCard
            isPremium={user.subscription.subType.toUpperCase() !== 'FREE'}
            viewFullProfile={user => {
              this.openProfile(user);
            }}
            handleCardAction={liked => {
							this.handleCardAction(liked, like);
						}}
            user={like}
          />
        </div>
      );
    }

    if (
      likeComponents.length === 0 &&
      user.subscription.subType.toUpperCase() !== 'FREE'
    ) {
      likeComponents.push(
        <div className="noLikedUsers" key={'no_likes'}>
          <p className="noLikedUsersText">No one has liked you yet</p>
        </div>
      );
    }

    return (
      <div className="likesUserContainer">
        <div className="likesUserHeader">
          <p className="greyText semiBoldWeight">LIKED YOU</p>
        </div>
        <div className="likedUserGrid">
          {likeComponents}
          {overlay}
        </div>
      </div>
    );
  };

  render() {
    const { likes, likedBy, likeLoading, dislikeLoading, generalLoading, showMatchModal } = this.state;
    return (
      <div className="ModamilyAppLikes">
        <ModamilyAppNavBar currentLink="likes" type="inApp" />
          <div className="likesPageContainer">
            {this.renderLikedUsersSection(likes)}
            {this.renderLikedBySection(likedBy)}
          </div>
          {
              generalLoading ? 
              <ModamilyAppLoadingComponent />
              : null
            }
        <UpgradeModal
          active={this.state.displayUpgrade}
          closeModal={this.closeUpgrade}
        />
        {this.state.displayProfile ? (
          <FullProfile
            customContainerStyle={{ height: '100%' }}
            disableCardActions={true}
            user={this.state.selectedUser}
            exitFullProfile={this.closeProfile}
          />
        ) : null}

        
        <ModamilyAppMatchModal
          key={this.state.otherUser}
          userMatchedWithImage={
            this.state.otherUser
              ? this.state.otherUser.images[0]
              : null
          }
          currentUserImage={
            this.state.currentUser ? this.state.currentUser.images[0] : null
          }
          active={showMatchModal}
        />
        

        {
					likeLoading || dislikeLoading  ? 
          <ModamilyAppLikeLoadingComponent scroll={Math.ceil(document.documentElement.scrollTop) + 'px'} type={likeLoading ? 'like' : 'dislike'} /> 
          : null
        }
      </div>
    );
  }
}

export default connect(
  null
  , 
	{ 
		setLikeRequest,
		setDislikeRequest,
	}
)(withApollo(ModamilyAppLikes));
