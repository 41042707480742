import React from "react";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppDropDownMenu from "../form/ModamilyAppDropDownMenu";
import countries from "../../constants/countries";


/**
 * @property {Object} data  - { hometownCountry: null, hometownZipcode: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppHometownLocationForm extends React.Component {
  state = {
    data: this.props.data || { hometownCountry: null, hometownZipcode: null }
  };


  componentDidMount = () => {

    let data = this.state.data;

    if (!data.hometownZipcode || !data.hometownCountry) {
      this.props.invalidate();
    } else if (data.hometownCountry && data.hometownZipcode) {

      this.props.onValidChange(data);
    }
  };
  onEnterPress = evt => {
    let data = this.state.data;

    console.log(data);
    if (!data.hometownZipcode || !data.hometownCountry) {
      this.props.invalidate();
    } else if (data.hometownCountry && data.hometownZipcode) {

      this.props.onValidChange(data);
    }
  };

  updateName = (key, text) => {
    let stateData = this.state.data;

    if (!text) {
      this.props.invalidate();
    }

    stateData[key] = text;

    let state = this.state;

    state.data = stateData;

    this.setState(state, () => {

      this.onEnterPress();
    });
  };

  onCountrySelect = country => {
    let data = this.state.data;
    data.hometownCountry = country;
    this.setState({ data });
  };

  render() {

    let selected = countries.countryNames.indexOf(this.state.data.hometownCountry)

    return (
      <div className="hometownForm">
        <ModamilyAppFormHeader text="Hometown" />
        <ModamilyAppDropDownMenu
          selected={selected}
          data={countries.countryNames}
          title="Select Country"
          type="Country"
          onItemClick={this.onCountrySelect}
        />
        <ModamilyAppTextInput
          onEnterPress={this.onEnterPress}
          value={this.state.data.hometownZipcode}
          onContentChange={text => {
            if (typeof text == "boolean") {
              this.updateName("hometownZipcode", null);
              this.props.invalidate();
            } else {
              this.updateName("hometownZipcode", text);
            }
          }}
          reference="zipcodeInput"
          ref="zipcodeComponent"
          label="Zip Code"
        />
      </div>
    );
  }
}
