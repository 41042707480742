import React from 'react';
import ModamilyAppFormHeader from '../form/ModamilyAppFormHeader';

/**
 * @property {Object} data  - { dob: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppBirthForm extends React.Component {
  state = {
    data: this.props.data || { dob: null },
    valid: false
  };

  componentDidMount = () => {
    let data = this.state.data;
    if (!data.dob) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }

    let supportsDateInput =
      navigator.userAgent.includes('chrome') ||
      navigator.userAgent.includes('Chrome');

    // console.log(supportsDateInput);
    this.setState({ supportsDateInput });
  };

  meetsMinimumAge = (birthDate, minAge) => {
    let tempDate = new Date(
      birthDate.getFullYear() + minAge,
      birthDate.getMonth(),
      birthDate.getDate()
    );
    return tempDate <= new Date();
  };

  onChange = evt => {
    let data = evt.target.value;

    console.log(data);

    if (data.length < 1) {
      this.props.invalidate();
    }

    let date = new Date(data);

    if (date != 'Invalid Date') {
      if (
        !(this.meetsMinimumAge(date, 18) && !this.meetsMinimumAge(date, 100))
      ) {
        data = null;
        this.props.invalidate();
      } else {
        this.props.onValidChange({ dob: data });
        this.setState({ data: { dob: data } });
      }
    }
  };

  updateValue = (key, data) => {
    let stateData = this.state.data;

    if (!data) {
      this.props.invalidate();
    }

    stateData[key] = data;

    let state = this.state;

    state.data = stateData;

    this.setState(state);
  };

  customInputChange = (num, type) => {
    let temp = this.state.data.dob;

    // console.log(temp);

    let data = num.trim();
    if (data.length == 0) {
      return null;
    }

    let date = temp ? temp.split('-') : ['0000', '00', '00'];

    switch (type) {
      case 'day':
        if (num.length <= 2 && num.length > 0) {
          date[2] = num;
        }

        break;
      case 'month':
        if (num.length <= 2 && num.length > 0) {
          date[1] = num;
        }
        break;
      case 'year':
        if (num.length <= 4 && num.length > 0) {
          date[0] = num;
        }
        break;
    }

    let bdate = date.join('-');

    this.setState({ data: { dob: bdate } });

    let dateObj = new Date(bdate);

    if (
      !(
        this.meetsMinimumAge(dateObj, 18) && !this.meetsMinimumAge(dateObj, 100)
      )
    ) {
      data = null;
      this.props.invalidate();
    } else {
      this.props.onValidChange({ dob: dateObj });
    }
  };

  render() {
    let { supportsDateInput, data } = this.state;

    let inputComponent = null;
    let date = data.dob ? data.dob.split('-') : ['', '', ''];

    if (supportsDateInput) {
      inputComponent = (
        <input
          type="date"
          className="birthdayInput"
          value={this.state.data.dob}
          onChange={this.onChange}
        />
      );
    } else {
      inputComponent = (
        <div className="customDateInput">
          <input
            type="number"
            className="monthInput"
            placeholder="MM"
            value={date[1]}
            onChange={evt => {
              this.customInputChange(evt.target.value, 'month');
            }}
          />
          <input
            type="number"
            placeholder="DD"
            className="dayInput"
            value={date[2]}
            onChange={evt => {
              this.customInputChange(evt.target.value, 'day');
            }}
          />
          <input
            type="number"
            placeholder="YYYY"
            className="yearInput"
            value={date[0]}
            onChange={evt => {
              this.customInputChange(evt.target.value, 'year');
            }}
          />
        </div>
      );
    }

    return (
      <div className="birthdayForm">
        <ModamilyAppFormHeader text="Birthdate" />
        <div className="birthdayInputContainer">{inputComponent}</div>
      </div>
    );
  }
}
