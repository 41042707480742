import React from "react";
import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import DotMenu from "../common/ModamilyAppDotMenu";
import UpgadeModal from "./ModamilyAppUpgrade";
import config from "../../config/config";
import Mutations from "../../apollo/mutations/mutations";
import Queries from "../../apollo/queries/queries";
import { Query, Mutation } from "react-apollo";
import decode from "jwt-decode";
import ModamilyAppRoundImage from "../common/ModamilyAppRoundImage";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";

import photoImg from "../../resources/images/appAssets/photoButton.png";

import ChatMessage from "../messaging/ChatMessage";

function buildFileSelector() {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  return fileSelector;
}

export default class ModamilyAppMessageChat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connected: false,
      messages: [],
      user: JSON.parse(localStorage["user"]),
      otherUser: this.props.user,
      convoId: this.props.convoId,
      timeLeft: this.props.timeLeft,
      active: this.props.active,
      showUpgrade: false,
      messageText: "",
      scrollViewHeight: 0
    };
    this.fileSelector = buildFileSelector();
    this.fileSelector.onchange = this.fileSelectorClick;
  }

  messages = [];

  fileSelectorClick = evt => {
    let files = evt.target.files;
    var reader = new FileReader();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      reader.onload = e => {
        file.base64 = e.target.result;
        this.sendImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  componentDidMount = async () => {
    if (this.props.active) {
      await this.connectToWebsocket();
    }

    if (this.refs.chatViewComponent) {
      let view = this.refs.chatViewComponent;
      view.scrollTo(view.scrollHeight);
    }
  };

  componentWillUnmount = async () => {
    if (this.ws) this.ws.close();
    this.setState({
      connected: false,
      messages: [],
      user: decode(localStorage["auth.token"]),
      otherUser: this.props.user,
      convoId: this.props.convoId,
      timeLeft: this.props.timeLeft,
      active: this.props.active,
      messageText: "",
      scrollViewHeight: 0
    });
  };

  connectToWebsocket = async () => {
    const websocketUrl =
      "ws://" + config.backendUrn + "/api/ws/" + this.state.convoId;
    const authToken = localStorage.getItem("auth.token");
    this.ws = new WebSocket(websocketUrl, authToken);
    this.ws.onmessage = event => {
      const wsResponse = event.data ? JSON.parse(event.data) : {};
      const action = "Action" in wsResponse ? wsResponse.Action : null;
      const data = "Data" in wsResponse ? wsResponse.Data : null;
      if (action === "success") {
        let messages = this.messages;
        messages.push(data);
        this.setState({ hasReadAllMessages: false, messages: messages });
      } else if (action === "failure") {
        console.log("Failure:", data.message);
        this.ws.close();
        this.props.close();
      } else {
        // SOMETHING WENT TERRIBLY WRONG...
        console.log("UMMM:", JSON.stringify(event.data));
        this.ws.close();
        this.props.close();
      }
    };
    this.ws.onerror = error => {
      console.log("Error", error ? error : null);
      this.ws.close();
      this.props.close();
    };
  };

  sendMessage = message => {
    this.ws.send(
      JSON.stringify({
        content: message,
        content_type: "text"
      })
    );
    this.setState({ refetch: true, messageText: "" });
  };

  sendImage = image => {
    this.ws.send(
      JSON.stringify({
        content: image,
        content_type: "image"
      })
    );
    this.setState({ refetch: true, messageText: "" });
  };

  checkReadMessages = () => {
    let messagesToRead = [];
    for (let i = 0; i < this.messages.length; i++) {
      if (
        this.messages[i].authorId !== this.state.user.id &&
        !this.messages[i].read
      ) {
        messagesToRead.push(this.messages[i].id);
      }
    }

    return messagesToRead.length > 0;
  };

  updateCurrentMessage = text => {
    this.setState({ messageText: text.target.value });
  };

  renderMessageComponents = arr => {
    let components = [];

    for (let index = 0; index < arr.length; index++) {
      const message = arr[index];

      let isYours = message.author_id === this.state.user.id;

      components.push(
        <ChatMessage
          key={"" + index + message.contentType + message.author_id}
          message={message.content}
          contentType={message.content_type}
          byCurrentUser={isYours}
          timeSent={message.created_at}
        />
      );
    }
    return components;
  };

  getImage = () => {
    let user = this.state.user;
    let subscription = user.subscription;

    if (subscription.subType.toUpperCase() !== "FREE") {
      this.fileSelector.click();
    } else {
      this.setState({ showUpgrade: true });
    }
  };

  render = () => {
    return (
      <div
        id="messageModalCurtain"
        className={
          "messageModalCurtain " +
          (this.state.active ? "messageCurtainDrag" : "hiddenMessageModal")
        }
        onClick={e => {
          if (e.target.className === "messageModalCurtain messageCurtainDrag")
            this.props.close();
        }}
      >
        {this.state.convoId ? (
          <Query
            query={Queries.getMessages}
            variables={{ conversationId: this.state.convoId }}
          >
            {({ loading, error, data, refetch }) => {
              let conversationData = [];
              let messageComponents = [];

              if (this.state.refetch) {
                refetch();
              }

              if (loading) {
                return <ModamilyAppLoadingComponent />;
              }
              if (error) {
                console.log(error);
              }
              if (data) {
                let response = decode(data.getMessages);
                if (this.messages.length > 0) {
                  console.log("wut");
                  refetch();
                }
                messageComponents = this.renderMessageComponents(
                  response.messages
                );
              }

              let curUserImg = null;

              // console.log(this.state.user, "current user");

              for (
                let index = 0;
                index < this.state.user.images.length;
                index++
              ) {
                const image = this.state.user.images[index];
                if (image.isMain) {
                  curUserImg = image;
                  break;
                }
              }

              if (!curUserImg && this.state.user.images.length > 0) {
                curUserImg = this.state.user.images[0];
              }

              let otherUserImg = this.state.otherUser.otherUserProfileImage;

              return (
                <div className="messageChatCard">
                  <div className="messageChatContainer">
                    <div className="browseCardPopUpMenuContainer">
                      <DotMenu
                        options={["Exit Conversation", "View Profile"]}
                        functions={[
                          () => {
                            this.props.close();
                          },
                          () => {}
                        ]}
                      />
                    </div>
                    <div className="messageChatHeaderContainer">
                      <div className="messageChatHeaderImageContainer">
                        <div className="overlayImageContainer">
                          <ModamilyAppRoundImage
                            size="Regular"
                            image={curUserImg ? curUserImg.path : null}
                            isUrl={true}
                            name={this.state.user.firstName}
                          />
                        </div>
                        <ModamilyAppRoundImage
                          size="Regular"
                          image={otherUserImg ? otherUserImg.path : null}
                          isUrl={true}
                          name={this.state.otherUser.otherUser.firstName}
                        />
                      </div>
                      <div className="messageChatHeaderTextContainer">
                        <p className="messageChatHeaderText">
                          {this.state.user.firstName +
                            " + " +
                            this.state.otherUser.otherUser.firstName}
                        </p>
                        <p className="greyText lightWeight">
                          You two liked each other
                        </p>
                      </div>
                    </div>
                    <div className="messageChatBody">
                      <div
                        ref={e => {
                          if (e) {
                            e.scrollTop = e.scrollHeight;
                          }
                        }}
                        key={messageComponents ? messageComponents.length : ""}
                        className="chatView"
                      >
                        {messageComponents}
                      </div>
                    </div>
                  </div>
                  <div className="chatTextInputContainer">
                    <div
                      onClick={() => {
                        this.getImage();
                      }}
                      className="sendImageButton"
                    >
                      <img className="sendImageButtonImg" src={photoImg} />
                    </div>
                    <input
                      placeholder="Enter message"
                      className="messageChatInput"
                      type="text"
                      onChange={this.updateCurrentMessage}
                      value={this.state.messageText}
                    />
                    <div
                      className={
                        "messageChatButton " +
                        (this.state.messageText ? "" : "diabledChatButton")
                      }
                      onClick={() => {
                        this.sendMessage(this.state.messageText);
                      }}
                    >
                      <p className="redText semiBoldWeight">Send</p>
                    </div>
                  </div>
                </div>
              );
            }}
          </Query>
        ) : null}

        <UpgadeModal
          closeModal={() => {
            this.setState({ showUpgrade: false });
          }}
          active={this.state.showUpgrade}
        />
      </div>
    );
  };
}
