/* eslint-disable default-case */
import React, { Component } from "react";
import ModamilyAppButton from "../components/common/ModamilyAppButton";
import { Redirect } from "react-router-dom";
import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyAppFooter from "../components/common/ModamilyAppFooter";

//import onboarding components
import ModamilyAppName from "../components/onboarding/ModamilyAppNameForm";
import ModamilyAppBirth from "../components/onboarding/ModamilyAppBirthForm";
import ModamilyAppGender from "../components/onboarding/ModamilyAppGenderForm";
import ModamilyAppSexualOrientation from "../components/onboarding/ModamilyAppSexualOrientation";
import ModamilyAppHomeTown from "../components/onboarding/ModamilyAppHometown";
import ModamilyAppCurrentLocation from "../components/onboarding/ModamilyAppCurrentLocation";
import ModamilyAppLookingFor from "../components/onboarding/ModamilyAppLookingFor";
import ModamilyAppWork from "../components/onboarding/ModamilyAppWork";
import ModamilyAppEducation from "../components/onboarding/ModamilyAppEducation";
import ModamilyAppIncome from "../components/onboarding/ModamilyAppIncome";
import ModamilyAppReligion from "../components/onboarding/ModamilyAppReligion";
import ModamilyAppPolitics from "../components/onboarding/ModamilyAppPolitics";
import ModamilyAppHeight from "../components/onboarding/ModamilyAppHeight";
import ModamilyAppEyeColor from "../components/onboarding/ModamilyAppEyeColor";
import ModamilyAppNaturalHair from "../components/onboarding/ModamilyAppNaturalHair";
import ModamilyAppRace from "../components/onboarding/ModamilyAppRace";
import ModamilyAppEthnicity from "../components/onboarding/ModamilyAppEthnicity";
import ModamilyAppProfilePhotos from "../components/onboarding/ModamilyAppProfilePhotos";
import ModamilyAppConversationStarters from "../components/onboarding/ModamilyAppConversationStarters";
import ModamilyAppCoparentRanks from "../components/onboarding/ModamilyAppCoparentRanks";

import Queries from "../apollo/queries/queries";
import Mutations from "../apollo/mutations/mutations";
import decode from "jwt-decode";
import { Query, Mutation } from "react-apollo";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";

class ModamilyAppOnboarding extends Component {
	state = {
		step: 0,
		data: {},
		loading: true,
		fireMutation: false,
		disableButton: true,
		getOnboardingStatus: true,
		scrollToBottom: false,
		startingStep: 0,
		footerActive: true
	};

	componentDidMount = () => {
		let user = JSON.parse(localStorage.getItem("user"));

		if (user) {
			this.setState({ user }, this.checkForSocialMediaAutoFill);
		}
	};

	checkForSocialMediaAutoFill = () => {
		let facebookToken = localStorage.getItem("social.facebook.token");
		if (facebookToken) {
			let response = JSON.parse(
				localStorage.getItem("social.facebook.signInResponse")
			);

			let data = this.state.data;
			let keys = Object.keys(response);

			for (let index = 0; index < keys.length; index++) {
				const key = keys[index];
				switch (key) {
					case "name":
						let arr = response[key].split(" ");

						let fName = arr[0];
						let lName = arr[arr.length - 1];

						data.firstName = fName;
						data.lastName = lName;

						break;
					case "gender":
						data.gender = response[key];

						break;
				}
			}
		}
	};

	createErrorAlert = message => {
		alert(message);
	};

	hideFooter = status => {
		// console.log(status, "updateing footer status");
		this.setState({ footerActive: status });
	};

	handleGetStatusSuccess = data => {
		let key = localStorage.getItem("oboarding-key");

		let response = decode(data.getOnboardingStatus);
		let keys = Object.keys(response.data);
		let screen = -1;
		for (let index = 0; index < keys.length; index++) {
			const key = keys[index];
			
			if (!response.data[key]) {
				switch (key) {
					case "hasUserInfo":
						screen = 0;
						break;
					case "hasProfileInfo":
						screen = 0;
						break;
					case "hasProfileImage":
						screen = 17;
						break;
					case "hasConversationStarters":
						screen = 18;
						break;
					case "hasCoparentRanks":
						screen = 19;
						break;
				}
				break;
			}
		}
		
		if (screen != -1) {
			// console.log(screen);
			let step = this.state.step;
			if (step > screen) {
				return step;
			} else {
				return screen;
			}
		} else {
			return <Redirect to={"/browse"}></Redirect>;
		}
	};

	handleGetStatusError = error => {
		console.log(error);
		return null;
	};

	handleUpdateUserSuccess = data => {
		console.log(data);
		let user = this.state.mutationVariables;

		localStorage.setItem("user", JSON.stringify(user));

		return null;
	};

	handleCreateProfileSuccess = data => {
		return null;
	};

	previousStep = () => {
		let step = this.state.step;
		step--;
		this.setState({ step, disableButton: false });
	};

	nextStep = () => {
		let step = this.state.step;
		let lastStep = this.state.step;
		let data = this.state.data;
		step++;
		let mutation = null;
		let fireMutation = false;
		let mutationVariables = null;
		switch (step) {
			case 6:
				fireMutation = !fireMutation;
				mutation = "user";
				mutationVariables = {
					email: this.state.user.email,
					firstName: data.firstName,
					lastName: data.lastName,
					addressLine1: data.addressLine1,
					addressLine2: data.addressLine2,
					zipcode: data.zipcode,
					city: data.city,
					phone: data.phone,
					state: data.state,
					country: data.country,
					latLong: data.latLong,
					fcmToken: ""
				};

				break;
			case 17:
				fireMutation = !fireMutation;
				mutation = "profile";
				mutationVariables = {
					dob: data.dob || "",
					gender: data.gender.toLowerCase() || "",
					lookingFor: data.lookingFor || "",
					orientation: data.orientation.toLowerCase() || "",
					eyeColor: data.eyeColor.toLowerCase() || "",
					bloodType: data.bloodType || "",
					ethnicity: data.ethnicity,
					race: data.race.toLowerCase() || "",
					diet: data.diet || "",
					occupationTitle: data.occupationTitle || "",
					occupationWorkplace: data.occupationWorkplace || "",
					income: data.income || 0,
					bodyType: data.bodyType || "",
					height: data.height || 0,
					heightIsMetric: data.isMetric || false,
					drink: data.drink || "",
					smoke: data.smoke || "",
					marijuana: data.marijuana || "",
					religion: data.religion.toLowerCase() || "",
					religionActivity: data.religionActivity.toLowerCase() || "",
					politics: data.politics.toLowerCase() || "",
					children: data.children || "",
					hairColor: data.hairColor.toLowerCase() || "",
					fertilization: data.fertilization,
					hometownCountry: data.hometownCountry || "",
					hometownZip: data.hometownZipcode || "",
					relationshipStatus: data.relationshipStatus || "",
					school: data.school || "",
					education: data.educationLevel.toLowerCase() || ""
				};

				break;
			case 18:
				fireMutation = !fireMutation;
				mutation = "images";

				let images = [];
				let dtaImages = data.images;

				for (let index = 0; index < dtaImages.length; index++) {
					const image = dtaImages[index];
					if (image.base64) {
						images.push({ base64: image.base64, uri: image.name });
					}
				}
				mutationVariables = { images };
				break;
			case 19:
				fireMutation = !fireMutation;
				mutation = "convo";
				mutationVariables = { list: JSON.stringify(data.conversationStarters) };

				break;
			case 20:
				fireMutation = !fireMutation;
				mutation = "coparent";
				mutationVariables = {
					qualities: data.coparentRanks.join(".")
				};

				break;
		}

		this.setState({
			step,
			disableButton: true,
			lastStep
		});

		return {
			mutation,
			mutationVariables,
			fireMutation
		};
	};

	invalidate = () => {
		this.setState({ disableButton: true });
	};

	validateStep = data => {
		let stateData = this.state.data;
		let disabled = this.state.disableButton;
		let tempData = { ...stateData, ...data };
		disabled = false;

		this.setState({
			data: tempData,
			disableButton: disabled,
			scrollToBottom: false
		});
	};

	scrollToBottom = () => {
		this.setState({ scrollToBottom: true });
	};

	render() {
		let {
			step,
			data,
			disableButton,
			errorState,
			lastStep
		} = this.state;

		let stateData = data;

		if (errorState) {
			if (lastStep === 17) {
				// Last Step was image upload step
				this.createErrorAlert('Error uploading image, try uploading samller image');
				stateData = { ...stateData, images: [] };
			}
			this.setState({ step: lastStep, lastStep: lastStep - 1, errorState: null, data: stateData });
		}

		return (
			<Query query={Queries.getOnboardingStatus}>
				{({ loading, error, data, refetch }) => {
					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						let action = this.handleGetStatusSuccess(data);

						if (typeof action != "number") {
							return action;
						} else {
							this.state.step = action;
						}
					}

					return (
						<Mutation mutation={Mutations.updateUser}>
							{(updateUser, { loading, error, data }) => {
								if (loading) {
									return <ModamilyAppLoadingComponent />;
								} else if (error) {
									console.log(error);
								} else if (data) {
									console.log(data);
								}

								return (
									<Mutation mutation={Mutations.createProfile}>
										{(createProfile, { loading, error, data }) => {
											if (loading) {
												return <ModamilyAppLoadingComponent />;
											} else if (error) {
												console.log(error);
											} else if (data) {
												console.log(data);
											}

											return (
												<Mutation mutation={Mutations.uploadImages} onError={(error) => this.setState({errorState: error})}>
													{(uploadImages, { loading, error, data }) => {
														if (loading) {
															return <ModamilyAppLoadingComponent />;
														} else if (error) {
															console.log(error);
														} else if (data) {
															console.log(data);
														}

														return (
															<Mutation
																mutation={Mutations.createConversationStarters}>
																{(
																	createConversationStarters,
																	{ loading, error, data }
																) => {
																	if (loading) {
																		return <ModamilyAppLoadingComponent />;
																	} else if (error) {
																		console.log(error);
																	} else if (data) {
																		console.log(data);
																	}

																	return (
																		<Mutation
																			mutation={Mutations.createCoparentRanks}>
																			{(
																				createCoparentRanks,
																				{ loading, error, data }
																			) => {
																				if (loading) {
																					return (
																						<ModamilyAppLoadingComponent />
																					);
																				} else if (error) {
																					console.log(error);
																					refetch()
																				} else if (data) {
																					refetch()
																					// console.log(data);
																				}

																				let children = {
																					0: (
																						<ModamilyAppName
																							data={{
																								firstName: stateData.firstName,
																								lastName: stateData.lastName
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					1: (
																						<ModamilyAppBirth
																							data={{ dob: stateData.dob }}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					2: (
																						<ModamilyAppGender
																							data={{
																								gender: stateData.gender
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					3: (
																						<ModamilyAppSexualOrientation
																							data={{
																								orientation:
																									stateData.orientation
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					4: (
																						<ModamilyAppCurrentLocation
																							data={{
																								country: stateData.country,
																								zipcode: stateData.zipcode,
																								latLong: stateData.latLong
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					5: (
																						<ModamilyAppHomeTown
																							data={{
																								hometownCountry:
																									stateData.hometownCountry,
																								hometownZipcode:
																									stateData.hometownZipcode
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					6: (
																						<ModamilyAppLookingFor
																							data={{
																								lookingFor:
																									stateData.lookingFor || []
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					7: (
																						<ModamilyAppWork
																							data={{
																								occupationTitle:
																									stateData.occupationTitle,
																								occupationWorkplace:
																									stateData.occupationWorkplace
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					8: (
																						<ModamilyAppEducation
																							data={{
																								educationLevel:
																									stateData.educationLevel,
																								school: stateData.school
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					9: (
																						<ModamilyAppIncome
																							data={{
																								income:
																									stateData.income || 100000
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																						/>
																					),
																					10: (
																						<ModamilyAppReligion
																							data={{
																								religion: stateData.religion,
																								religionActivity:
																									stateData.religionActivity
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					11: (
																						<ModamilyAppPolitics
																							data={{
																								politics: stateData.politics
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					12: (
																						<ModamilyAppHeight
																							data={{
																								height: stateData.height,
																								isMetric: stateData.isMetric
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					13: (
																						<ModamilyAppEyeColor
																							data={{
																								eyeColor: stateData.eyeColor
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					14: (
																						<ModamilyAppNaturalHair
																							data={{
																								hairColor: stateData.hairColor
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					15: (
																						<ModamilyAppRace
																							data={{ race: stateData.race }}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					16: (
																						<ModamilyAppEthnicity
																							data={{
																								ethnicity:
																									stateData.ethnicity || []
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					17: (
																						<ModamilyAppProfilePhotos
																							data={{
																								images: stateData.images || []
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																						/>
																					),
																					18: (
																						<ModamilyAppConversationStarters
																							data={{
																								profilePhoto: stateData.images && stateData.images[0] || '',
																								conversationStarters: stateData.conversationStarters || [
																									{
																										question: null,
																										answer: null
																									},
																									{
																										question: null,
																										answer: null
																									},
																									{
																										question: null,
																										answer: null
																									}
																								]
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																							hideFooter={this.hideFooter}
																						/>
																					),
																					19: (
																						<ModamilyAppCoparentRanks
																							data={{
																								coparentRanks: stateData.coparentRanks || [
																									"Physical attractiveness",
																									"Intelligence",
																									"Creativity",
																									"Financial stability / income",
																									"Shared value system",
																									"Same religion",
																									"Health"
																								]
																							}}
																							invalidate={this.invalidate}
																							onValidChange={this.validateStep}
																							scrollToBottom={
																								this.scrollToBottom
																							}
																							hideFooter={this.hideFooter}
																						/>
																					)
																				};

																				let footerClass = this.state
																					.footerActive
																					? "onboarding"
																					: "onboardingHidden";

																				let component = (
																					<div
																						ref="onboarding"
																						className="ModamilyAppOnboarding">
																						<ModamilyAppNavBar returnToHome type="onboarding" />
																						<div className="onboardingContainer">
																							<div className="onboardingFormContainer">
																								{children[step]}
																							</div>
																						</div>
																						<ModamilyAppFooter
																							type={footerClass}>
																							<div className="onboardingButtonContainer">
																								{step >
																								this.state.startingStep ? (
																									<div className="onboardingStepButton">
																										<ModamilyAppButton
																											style="whiteButton"
																											text="Previous"
																											onClick={
																												this.previousStep
																											}
																										/>
																									</div>
																								) : null}

																								<div className="onboardingStepButton">
																									<ModamilyAppButton
																										disabled={disableButton}
																										style="orangeGradient"
																										text="Next"
																										onClick={() => {
																											let response = this.nextStep();

																											if (
																												response.fireMutation
																											) {
																												// console.log(
																												// 	response.mutation,
																												// 	response.mutationVariables
																												// );

																												switch (
																													response.mutation
																												) {
																													case "user":
																														updateUser({
																															variables: {
																																...response.mutationVariables
																															}
																														});
																														break;
																													case "profile":
																														createProfile({
																															variables: {
																																...response.mutationVariables
																															}
																														});
																														break;
																													case "images":
																														uploadImages({
																															variables: {
																																...response.mutationVariables
																															}
																														});
																														break;
																													case "convo":
																														createConversationStarters(
																															{
																																variables: {
																																	...response.mutationVariables
																																}
																															}
																														);
																														break;
																													case "coparent":
																														createCoparentRanks(
																															{
																																variables: {
																																	...response.mutationVariables
																																}
																															}
																														);
																														break;
																												}
																											}
																										}}
																									/>
																								</div>
																							</div>
																						</ModamilyAppFooter>
																					</div>
																				);

																				return component;
																			}}
																		</Mutation>
																	);
																}}
															</Mutation>
														);
													}}
												</Mutation>
											);
										}}
									</Mutation>
								);
							}}
						</Mutation>
					);
				}}
			</Query>
		);
	}
}

export default ModamilyAppOnboarding;
