import React from 'react';

//forms
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";

const Orientations = ({ searchPreferences, selectedOrientationIndexes, handleUpdate }) => {
	return (
  <div className="searchPrefForm">
			<div className="searchPrefEducationFormContainer">
				<FormHeader text="Sexual Orientation" />
				<ButtonGroup
					manualSelection={true}
					customSelected={selectedOrientationIndexes}
					data={Options.orientation}
					onButtonClick={(value, index) => {
						if (index > -1) {
							if(value.toLowerCase() === 'prefer not to say') {
								searchPreferences.orientations = [value.toLowerCase()]
							} else {
								const indexOfPNTS = searchPreferences.orientations.indexOf('prefer not to say');
								if ( indexOfPNTS > -1) { 
									searchPreferences.orientations.splice(
										indexOfPNTS,
										1
									);
								}
								searchPreferences.orientations.push(
									value.toLowerCase()
								);
							}
						} else {
							searchPreferences.orientations.splice(
								searchPreferences.orientations.indexOf(
									value.toLowerCase()
								),
								1
							);
						}
						handleUpdate(searchPreferences);
					}}
				/>
			</div>
		</div>
);
}


export default Orientations;