import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import Subtitle from "../common/ModmilyAppSubtitle";

/**
 * @property {Object} data  - { race: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppRace extends React.Component {
	state = {
		data: this.props.data || { race: null }
	};

	buttonOptions = [
		"asian",
		"black",
		"white",
		"hispanic/latin",
		"native american",
		"middle eastern",
		"mixed",
		"other",
		"prefer not to say"
	];

	componentWillMount = () => {
		this.validate();
	};

	validate = () => {
		let data = this.state.data;
		if (!data.race) {
			this.props.invalidate();
		} else if (data.race) {
			console.log(data);
			this.props.onValidChange(data);
		}
	};

	handleButtonClick = (value, index, isActive) => {
		let data = this.state.data;
		data.race = isActive ? value : null;
		this.props.scrollToBottom && this.props.scrollToBottom();
		this.validate();
	};

	render = () => {
		let data = this.state.data;

		let raceIndex = this.buttonOptions.indexOf(data.race);

		return (
			<div className="raceForm">
				<FormHeader text="Race" />
				<ButtonGroup
					selected={raceIndex}
					type="radio"
					data={this.buttonOptions}
					onButtonClick={this.handleButtonClick}
				/>
			</div>
		);
	};
}
