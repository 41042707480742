import React from "react";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";
import Subtitle from "../common/ModmilyAppSubtitle";


/**
 * @property {Object} data  - { hairColor: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppNaturalHair extends React.Component {
    state = {
        data: this.props.data || { hairColor: null }
    };

    buttonOptions = [
        "auburn",
        "brown",
        "black",
        "blonde",
        "dark brown",
        "dirty blonde",
        "light brown",
        "red",
        "strawberry blonde",
        "orange",
        "gray",
        "other",
    ];

    componentWillMount = () => {
        this.validate()
    }

    validate = () => {
        let data = this.state.data;
        if (!data.hairColor) {
            this.props.invalidate()
        } else if (data.hairColor) {
            console.log(data);
            this.props.onValidChange(data);
        }
    }

    handleButtonClick = (value, index, isActive) => {
        let data = this.state.data;
        data.hairColor = (isActive) ? value : null;
        this.props.scrollToBottom && this.props.scrollToBottom();
        this.validate();
    }

    render = () => {
        let data = this.state.data;

        let hairColorIndex = this.buttonOptions.indexOf(data.hairColor);

        return < div className="hairColorForm" >
            <FormHeader text="Natural Hair"></FormHeader>
            <ButtonGroup
                selected={hairColorIndex}
                type="radio"
                data={this.buttonOptions}
                onButtonClick={this.handleButtonClick}
            ></ButtonGroup>


        </div >
    }
}