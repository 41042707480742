import React from "react";
import moment from "moment";

/**
 * @property {String} message - message text / image binary
 * @property {String} contentType - "image" | "text"
 * @property {Boolean} byCurrentUser - determines color and which side to place the chat
 * @property {String} timeSent - time the message was sent to the other user
 */
export default class ChatMessage extends React.Component {
  getTimestamp = (timeSent, isPremium, read, isCurrentUser) => {
    if (this.isPreviousDate(timeSent)) {
      return (
        <p className="chatTime">
          {moment(timeSent).format("ddd Do MMM, YYYY, h:mm a") +
            (isPremium && isCurrentUser && read ? ", Read" : "")}
        </p>
      );
    } else {
      return (
        <p>
          {moment().format("ddd h:mmA") +
            (isPremium && isCurrentUser && read ? ", Read" : "")}
        </p>
      );
    }
  };

  isPreviousDate = date => {
    return (
      new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)
    );
  };

  render() {
    let {
      message,
      contentType,
      timeSent,
      byCurrentUser,
      userObject,
      content,
      read,
      authorId
    } = this.props;

    let containerName = byCurrentUser ? "currentUserChat" : "";

    let isPremium = userObject.subscription
      ? userObject.subscription.subType.toLowerCase() !== "free"
      : false;

    let isCurrentUsers = authorId === userObject.id;

    switch (contentType) {
      case "text":
        content = <p className="chatMessageText">{message}</p>;
        break;
      case "image":
        content = (
          <img className="chatMessageImg" src={message} alt="message" />
        );
        break;
      default:
        content = <p className="chatMessageText">{message}</p>;
        break;
    }

    return (
      <div className={"chatMessageContainer " + containerName}>
        <div className={"chatTimeContainer " + containerName}>
          {this.getTimestamp(timeSent, isPremium, read, isCurrentUsers)}
        </div>
        <div className={"chatMessage " + containerName}>{content}</div>
      </div>
    );
  }
}
