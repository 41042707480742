import React from "react";
import Button from "../common/ModamilyAppButton";

/**
 * @property {Boolean} active - determines if the modal is hidden or not
 * @property {Function} onSelect - the function called when a question is selected
 * @property {Array(String)} selectedQuestions - an array of the questions already selected by the user
 */

export default class ModamilyAppQuestionModal extends React.Component {
	state = {
		active: this.props.active || false
	};

	questions = [
		`What's your favorite movie?`,
		"Favorite quality in a person?",
		"What is your go to Karaoke song?",
		"Do you speak any other languages?",
		"Who is someone that inspires you?",
		`What's are your favorite TV shows?`,
		"What did you want to be growing up?",
		"What do you like to do on the weekend?",
		"What things do you like to do after work?",
		"If you won the lottery, what would you do?",
		`What is a favorite trip you've taken recently?`,
		"If you could interview anyone, who would it be?",
		"What recent accomplishment are you most proud of?",
		"Two truths and one lie, guess which one is false.",
		`What's the most impactful thing you've read recently?`,
		"Do you see your child going to private school or public school?",
		"Do you want to raise your child in the same religion that you grew up in?",
		"If you were to pack a bag and go on a trip tomorrow, where would you want to go?"
	];

	componentDidMount = () => {
		let state = this.state;
		if (this.props.active) {
			state.active = this.props.active;
		}
		if (this.props.hideFooter) {
			this.props.hideFooter(!state.active);
		}

		this.setState(state);
	};

	render = () => {
		let { active } = this.state;

		let selectedQuestions = this.props.selectedQuestions;

		let componentList = [];

		if (selectedQuestions) {
			let copyArr = this.questions;
			for (let index = 0; index < selectedQuestions.length; index++) {
				const question = selectedQuestions[index];

				let qI = this.questions.indexOf(question);
				if (qI > -1) {
					copyArr.splice(qI, 1);
				}
			}
			this.questions = copyArr;
		}

		for (let index = 0; index < this.questions.length; index++) {
			const question = this.questions[index];
			componentList.push(
				<Button
					key={question}
					style="clear"
					text={question}
					onClick={() => {
						this.props.onSelect(question);
					}}
				/>
			);
		}

		// console.log(componentList);

		return (
			<div className={active ? "modalCurtain curtainDrag" : "modalCurtain"}>
				<div className={"questionModal"}>
					<div className="questionModalHeader">
						<p className="whiteHeader">{"Select a"}</p>
						<p className="whiteHeader">{"Conversation Starter"}</p>
					</div>
					<div className="questionList">{componentList}</div>
				</div>
			</div>
		);
	};
}
