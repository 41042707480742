import React from 'react';
import regex from '../../utils/regex';

/**
 * @property {String} type- The input type - 'password', 'email', 'number',if none is supplied then there is no validation
 * @property {String} value- Initial value of the text view
 * @property {Function(String, bool)} onContentChange - function taking in the content from the input.This is called when valiation completed. returns a String when the text is valid otherwise returns FALSE
 * @property {String} label - label text for the input field
 * @property {Function} onEnterPress -function that will be fired when the user hits enter key
 * @property {String} reference - ref name for the input, use this if you want to switch focus from outside this component
 * @property {Integer} maxLength - used for Number inputs, determines the length of the input string
 * @property {Boolean} validate- used to disabled validation
 */

export default class ModamilyAppTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.setValue = this.setValue.bind(this);
  }
  state = {
    type: this.props.type,
    value: this.props.value,
    label: this.props.label,
    iconClass: 'inputIcon',
    maxLength: this.props.maxLength,
    valid: false,
    allowZero: this.props.allowZero || false
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.setState({ ...this.props });
    }
  }

  passwordTest = password => {
    let valid = false;
    let regexArr = Object.values(regex.passwordRegex);

    for (let index = 0; index < regexArr.length; index++) {
      const regex = regexArr[index];
      valid = regex.test(password);
      if (!valid) {
        return valid;
      }
    }
    return valid;
  };

  _handleKeyDown = e => {
    if (e.key === 'Enter' && this.state.valid && !!this.props.onEnterPress) {
      this.props.onEnterPress(e);
    }
  };

  validate = evt => {
    let tempText = evt.target.value;

    let reg;
    let isValid;

    if (this.state.maxLength) {
      isValid = !(tempText.length > this.state.maxLength);
    } else {
      switch (this.state.type) {
        case 'password':
          isValid = this.props.validate ? this.passwordTest(tempText) : true;
          break;
        case 'email':
          reg = regex.emailRegex;
          isValid = this.props.validate ? reg.test(tempText) : true;
          break;
        case 'number':
          reg = regex.numberRegex;
          isValid = this.props.validate ? reg.test(tempText) : true;
          break;
        case 'zipcode':
          reg = regex.zipRegex;
          isValid = this.props.validate ? reg.test(tempText) : true;
          break;
        default:
          reg = regex.noWhiteSpaceRegex;
          isValid = this.props.validate ? reg.test(tempText) : true;
          break;
      }
    }

    this.props.onContentChange(tempText, isValid);
    this.setState({ value: tempText, valid: isValid });
  };

  updateIcon = isFocused => {
    this.setState({
      iconClass: isFocused ? 'iconFocus' : 'inputIcon'
    });
  };
  setValue() {

  }

  render() {
    let icon = (
      <img alt={''} className={this.state.iconClass} src={this.props.icon} />
    );

    return (
      <div className="inputContainer">
        <label htmlFor={this.props.reference} className="inputLabel">
          <p className="inputLabelText">{this.state.label}</p>
        </label>
        <div name={this.props.reference} className="input">
          {this.props.icon ? icon : null}
          <input
            onFocus={() => {
              this.updateIcon(true);
            }}
            onBlur={() => {
              this.updateIcon(false);
            }}
            ref={this.props.reference}
            type={this.state.type}
            value={this.props.allowZero ? this.state.value  : (this.state.value || '')}
            onChange={this.validate}
            onKeyPress={this._handleKeyDown}
          />
        </div>
      </div>
    );
  }
}
