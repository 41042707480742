import React from "react";
import moment from 'moment';

import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import TabHeader from "../common/ModamilyAppTabMenuHeader";
import TabItem from "../common/ModamilyAppTabMenuItem";
import FullProfile from "../modals/ModamilyAppFullProfile";
import Badge from "../common/ModamilyAppBadge";
import Button from "../common/ModamilyAppButton";

import BirthForm from "../onboarding/ModamilyAppBirthForm";
import GenderForm from "../onboarding/ModamilyAppGenderForm";
import OrientationForm from "../onboarding/ModamilyAppSexualOrientation";
import HometownForm from "../onboarding/ModamilyAppHometown";
import LookingForForm from "../onboarding/ModamilyAppLookingFor";
import EducationForm from "../onboarding/ModamilyAppEducation";
import WorkForm from "../onboarding/ModamilyAppWork";
import IncomeForm from "../onboarding/ModamilyAppIncome";
import HeightForm from "../onboarding/ModamilyAppHeight";
import ReligionForm from "../onboarding/ModamilyAppReligion";
import RaceForm from "../onboarding/ModamilyAppRace";
import EthnicityForm from "../onboarding/ModamilyAppEthnicity";
import HairForm from "../onboarding/ModamilyAppNaturalHair";
import EyeColorForm from "../onboarding/ModamilyAppEyeColor";
import RelationshipStatusForm from "./forms/accountDetails/ModamilyAppRelationshipStatusForm";
import GenericForm from "./forms/accountDetails/ModamilyAppAdditionalDetailsForm";

import CoparentRanks from "../onboarding/ModamilyAppCoparentRanks";
import ConversationStarterForm from "../onboarding/ModamilyAppConversationStarters";
import EditProfilePhotos from "./forms/accountDetails/ModamilyAppEditProfilePhotos";

import FormOptions from "../../constants/formOptions";

import { Mutation, Query } from "react-apollo";
import decode from "jwt-decode";
import Mutations from "../../apollo/mutations/mutations";
import Queries from "../../apollo/queries/queries";

class ConversationCard extends React.Component {
	render () {
		let { question, answer } = this.props;

		if (!question) {
			question = "Add a question to answer...";
			answer = null;
		}

		let questionTextClass = "question";

		return (
			<div className="conversationCard">
				<div className="questionContainer">
					<p className={questionTextClass}>{question}</p>
				</div>
				{answer ? (
					<div className="answerContainer">
						<p className="purpleText regularWeight">{answer}</p>
					</div>
				) : null}
			</div>
		);
	};
}

class EditProfileMenu extends React.Component {
	state = {
		user: this.props.user,
		viewFullProfile: false
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.user !== this.props.user) {
			this.setState({user: this.props.user});
		}
	}

	hideFullProfile = () => {
		this.setState({ viewFullProfile: false });
	};

	displayFullProfile = () => {
		this.setState({ viewFullProfile: true });
	};

	handleUpdateUser = (images) => {
		const {user} = this.state;
		user.images = images;
		this.setState({ user });
	}

	render() {
		let { user } = this.state;
		if (!user) {
			return <ModamilyAppLoadingComponent />;
		}

		if (this.state.viewFullProfile) {
			return (
				<FullProfile
					user={user}
					active={this.state.viewFullProfile}
					exitFullProfile={this.hideFullProfile}
					disableCardActions={true}
				/>
			);
		}

		let units = user.heightIsMetric ? "cm" : "in";

		let arr = user.coparentRanks && user.coparentRanks.map((e, i) => {
			return `${i + 1}. ${e}`;
		});

		let displayRanksText = user.coparentRanks && [arr[0], arr[1], arr[2]].join(", ");

		let conversationCards = [];

		
		if(user.convoStarters) {
			for (let index = 0; index < user.convoStarters.length; index++) {
				const convoStarter = user.convoStarters[index];
				conversationCards.push(
					<div
						key={index}
						className="convoCardContainer"
						onClick={() => {
							this.props.setView(25);
						}}>
						<Badge
							type="delete"
							onClick={e => {
								e.stopPropagation();
							}}>
							<ConversationCard
								question={convoStarter.question}
								answer={convoStarter.answer}
							/>
						</Badge>
					</div>
				);
			}
		}

		if (conversationCards.length < 3) {
			conversationCards.push(
				<div
					className="convoCardContainer"
					key={conversationCards.length}
					onClick={() => {
						this.props.setView(25);
					}}>
					<Badge type="add">
						<ConversationCard question="Add a question to answer" answer="" />
					</Badge>
				</div>
			);
		}

		return (
			<div className="editProfileMenu">
				<div className="editViewButtonContainer">
					<div className="editButton">
						<Button text="Edit" onClick={this.hideFullProfile} />
					</div>
					<div className="viewButton">
						<Button
							style="orangeGradient"
							text="View"
							onClick={this.displayFullProfile}></Button>
					</div>
				</div>
				<TabHeader text="BASIC INFO" />
				<TabItem
					header="AGE"
					data={user.age}
					onClick={() => {
						this.props.setView(1);
					}}
				/>
				<TabItem
					header="GENDER"
					data={user.gender}
					onClick={() => {
						this.props.setView(2);
					}}
				/>
				<TabItem
					header="SEXUAL ORIENTATION"
					data={user.orientation}
					onClick={() => {
						this.props.setView(3);
					}}
				/>
				<TabItem
					header="HOMETOWN"
					data={
						user.hometownCountry
							? user.hometownCountry + ", " + user.hometownZip
							: " "
					}
					onClick={() => {
						this.props.setView(4);
					}}
				/>
				<TabItem
					header="Looking for"
					hideOverflow
					data={user.lookingFor ? user.lookingFor.join(", ") : ''}
					onClick={() => {
						this.props.setView(5);
					}}
				/>
				<TabItem
					header="EDUCATION"
					data={user.education + ", " + user.school}
					onClick={() => {
						this.props.setView(6);
					}}
				/>
				<TabItem
					header="WORK"
					data={user.occupationTitle + ", " + user.occupationWorkplace}
					onClick={() => {
						this.props.setView(7);
					}}
				/>
				<TabItem
					header="INCOME"
					data={user.income}
					onClick={() => {
						this.props.setView(8);
					}}
				/>
				<TabItem
					header="RELGION"
					data={user.religion + ", " + user.religionActivity}
					onClick={() => {
						this.props.setView(9);
					}}
				/>
				<TabItem
					header="HEIGHT"
					data={user.height + units}
					onClick={() => {
						this.props.setView(10);
					}}
				/>
				<TabItem
					header="EYE COLOR"
					data={user.eyeColor}
					onClick={() => {
						this.props.setView(11);
					}}
				/>
				<TabItem
					header="NATURAL HAIR"
					data={user.hairColor}
					onClick={() => {
						this.props.setView(12);
					}}
				/>
				<TabItem
					header="RACE"
					data={user.race}
					onClick={() => {
						this.props.setView(13);
					}}
				/>
				<TabItem
					header="ETHNICITY"
					data={user.ethnicity ? user.ethnicity.join(", ") : ''}
					onClick={() => {
						this.props.setView(14);
					}}
				/>
				<TabHeader text="PHOTOS" />
				<EditProfilePhotos
					data={user.images}
					onUpdateUser={this.handleUpdateUser}
					setView={() => {
						this.props.setView(0);
					}}
				/>
				<TabHeader text="CONVERSATION STARTERS" />
				{conversationCards}
				<TabHeader text="RANK CO-PARENT QUALITIES" />
				<TabItem
					header="1-7"
					data={displayRanksText || ''}
					onClick={() => {
						this.props.setView(26);
					}}
				/>
				<TabHeader text="ADDITIONAL INFO" />
				<TabItem
					header="RELATIONSHIP STATUS"
					data={user.relationshipStatus || "Add"}
					onClick={() => {
						this.props.setView(15);
					}}
				/>
				<TabItem
					header="BODY TYPE"
					data={user.bodyType || "Add"}
					onClick={() => {
						this.props.setView(16);
					}}
				/>
				<TabItem
					header="DIET"
					data={user.diet || "Add"}
					onClick={() => {
						this.props.setView(17);
					}}
				/>
				<TabItem
					header="DRINKS"
					data={user.drink || "Add"}
					onClick={() => {
						this.props.setView(18);
					}}
				/>
				{/* <TabItem
					header="DRUGS"
					data={user.drugs || "Add"}
					onClick={() => {
						this.props.setView(19);
					}}
				/> */}
				<TabItem
					header="MARIJUANA"
					data={user.marijuana || "Add"}
					onClick={() => {
						this.props.setView(20);
					}}
				/>
				<TabItem
					header="SMOKE"
					data={user.smoke || "Add"}
					onClick={() => {
						this.props.setView(21);
					}}
				/>
				<TabItem
					header="FERTILIZATION OPTIONS"
					data={user.fertilization ? user.fertilization.join(", ") : "Add"}
					onClick={() => {
						this.props.setView(22);
					}}
				/>
				<TabItem
					header="BLOOD TYPE"
					data={user.bloodType || "Add"}
					onClick={() => {
						this.props.setView(23);
					}}
				/>
				<TabItem
					header="CHILDREN"
					data={user.children || "Add"}
					onClick={() => {
						this.props.setView(24);
					}}
				/>
			</div>
		);
	};
}

export default class ModamilyAppEditProfile extends React.Component {
	state = {
		showFullProfile: false,
		formValid: false,
		refetch: false,
		view: 0
	};

	setView = (index, refetch) => {
		this.setState({ view: index });
	};

	validate = valid => {
		this.setState({ formValid: valid });
	};

	render = () => {
		let user = decode(localStorage["auth.token"]);

		return (
			<Query query={Queries.getFullProfile} variables={{ id: user.id }}>
				{({ loading, error, data, refetch }) => {
					if (loading) return <ModamilyAppLoadingComponent />;
					if (error) console.log(error);
					if (data) {

						if (data.getFullProfile) {
							let response = decode(data.getFullProfile);
							user = response.data;
							if (user.coparentRanks && user.coparentRanks.length === 0) {
								user.coparentRanks = [
									"Physical attractiveness",
									"Intelligence",
									"Creativity",
									"Financial stability / income",
									"Shared value system",
									"Same religion",
									"Health"
								];
							}
						}
					}
					
					return (
						<Mutation mutation={Mutations.updateProfile}>
							{(updateProfile, { loading, data, error }) => {
								if (loading) return <ModamilyAppLoadingComponent />;
								if (error) console.log(error);
								if (data) {
									let response = decode(data.updateProfile);
									if (response.action === "success") {
										this.setView(0);
										refetch();
									}
								}

								return (
									<Mutation mutation={Mutations.updateConversationStarters}>
										{(updateConversationStarters, { loading, data, error }) => {
											if (loading) return <ModamilyAppLoadingComponent />;
											if (error) console.log(error);
											if (data) {
												let response = decode(data.updateConversationStarters);
												if (response.action === "success") {
													console.log(response);
													this.setView(0);
													refetch();
												}
											}
											return (
												<Mutation mutation={Mutations.updateCoParentRanks}>
													{(updateCoParentRanks, { loading, error, data }) => {
														if (loading) return <ModamilyAppLoadingComponent />;
														if (error) console.log(error);
														if (data) {
															let response = decode(data.updateCoParentRanks);
															if (response.action === "success") {
																this.setView(0);
																refetch();
															}
														}
														let children = [
															<EditProfileMenu
																user={user}
																setView={this.setView}
															/>,
															<BirthForm
																data={{ dob: user.dob }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.dob = data.dob;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<GenderForm
																data={{ gender: user.gender }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.gender = data.gender;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<OrientationForm
																data={{ orientation: user.orientation }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.orientation = data.orientation;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<HometownForm
																data={{
																	hometownCountry: user.hometownCountry,
																	hometownZipcode: user.hometownZip
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.hometownCountry = data.hometownCountry;
																	user.hometownZip = data.hometownZipcode;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<LookingForForm
																data={{ lookingFor: user.lookingFor }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.lookingFor = data.lookingFor;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<EducationForm
																data={{
																	educationLevel: user.education,
																	school: user.school
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.education = data.educationLevel;
																	user.school = data.school;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<WorkForm
																data={{
																	occupationTitle: user.occupationTitle,
																	occupationWorkplace: user.occupationWorkplace
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.occupationTitle = data.occupationTitle;
																	user.occupationWorkplace =
																		data.occupationWorkplace;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<IncomeForm
																data={{ income: user.income }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.income = data.income;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<ReligionForm
																data={{
																	religion: user.religion,
																	religionActivity: user.religionActivity
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.religion = data.religion;
																	user.religionActivity = data.religionActivity;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<HeightForm
																data={{
																	height: user.height,
																	isMetric: user.heightIsMetric
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.height = data.height;
																	user.heightIsMetric = data.isMetric;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<EyeColorForm
																data={{ eyeColor: user.eyeColor }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.eyeColor = data.eyeColor;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<HairForm
																data={{ hairColor: user.hairColor }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.hairColor = data.hairColor;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<RaceForm
																data={{ race: user.race }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.race = data.race;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<EthnicityForm
																data={{ ethnicity: user.ethnicity }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.ethnicity = data.ethnicity;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<RelationshipStatusForm
																data={{
																	relationshipStatus: user.relationshipStatus
																}}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.relationshipStatus =
																		data.relationshipStatus;
																	this.setState({ user: user });
																	this.validate(true);
																}}
															/>,
															<GenericForm
																data={{ bodyType: user.bodyType }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.bodyType = data.bodyType;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Body Type"
																buttonType="radio"
																options={FormOptions.bodyTypes}
															/>,
															<GenericForm
																data={{ diet: user.diet }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.diet = data.diet;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Diet"
																buttonType="radio"
																options={FormOptions.diet}
															/>,
															<GenericForm
																data={{ drink: user.drink }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.drink = data.drink;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Drinks"
																buttonType="radio"
																options={FormOptions.drinks}
															/>,
															<GenericForm
																data={{ drugs: user.drugs }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.drugs = data.drugs;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Drugs"
																buttonType="radio"
																options={FormOptions.drugs}
															/>,
															<GenericForm
																data={{ marijuana: user.marijuana }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.marijuana = data.marijuana;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Marijuana"
																buttonType="radio"
																options={FormOptions.marijuana}
															/>,
															<GenericForm
																data={{ smoke: user.smoke }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.smoke = data.smoke;
																	this.setState({ user: user });
																	this.validate(true);
																}}
																formHeader="Smoke"
																buttonType="radio"
																options={FormOptions.smoke}
															/>,
															<GenericForm
																data={{ fertilization: user.fertilization }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.fertilization = data.fertilization;
																	this.setState({ user: user }, () => {
																		this.validate(true);
																	});
																}}
																formHeader="Fertilization Options"
																buttonType="check"
																options={FormOptions.fertilization}
															/>,
															<GenericForm
																data={{ bloodType: user.bloodType }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.bloodType = data.bloodType;
																	this.setState({ user: user }, () => {
																		this.validate(true);
																	});
																}}
																formHeader="Blood Type"
																buttonType="radio"
																options={FormOptions.bloodType}
															/>,
															<GenericForm
																data={{ children: user.children }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.children = data.children;
																	this.setState({ user: user }, () => {
																		this.validate(true);
																	});
																}}
																formHeader="Children"
																buttonType="radio"
																options={FormOptions.children}
															/>,
															// 25
															<div className="genderForm">
																<ConversationStarterForm
																	onEditProfile
																	hideFooter={() => {
																		console.log("hidefooter");
																	}}
																	selectQuestionHandler={data => {
																		console.log(data);
																	}}
																	data={{
																		conversationStarters: user.convoStarters
																	}}
																	invalidate={() => {
																		// this.validate(false);
																	}}
																	onValidChange={data => {
																		user.convoStarters =
																			data.conversationStarters;
																		this.setState({
																			user: user,
																			formValid: true
																		});
																	}}
																/>
															</div>,
															//26
															<CoparentRanks
																data={{ coparentRanks: user.coparentRanks }}
																invalidate={() => {
																	this.validate(false);
																}}
																onValidChange={data => {
																	user.coparentRanks = data.coparentRanks;
																	this.setState({
																		user: user,
																		formValid: true
																	});
																}}
															/>
														];

														if (this.state.view === 0) {
															return children[this.state.view];
														} else {
															return (
																<div className="editProfileForm">
																	<div className="editProfileFormContainer">
																		{children[this.state.view]}
																	</div>
																	<div className="editProfileFormActions">
																		<div className="editProfileFormSubmit">
																			<Button
																				disabled={!this.state.formValid}
																				text="Submit"
																				style="orangeGradient"
																				onClick={() => {
																					let user = this.state.user;
																					switch (true) {
																						case this.state.view <= 24:
																							let vars = {
																								gender: user.gender || "",
																								dob: user.dob === '0000-00-00' ? moment().format('YYYY-MM-DD') : user.dob,
																								lookingFor: user.lookingFor || [],
																								orientation:
																									user.orientation || "",
																								hairColor: user.hairColor || "",
																								eyeColor: user.eyeColor || "",
																								bloodType: user.bloodType || "",
																								ethnicity: user.ethnicity || [],
																								race: user.race || "",
																								diet: user.diet || "",
																								occupationTitle:
																									user.occupationTitle || "",
																								occupationWorkplace:
																									user.occupationWorkplace ||
																									"",
																								income: user.income || 0,
																								bodyType: user.bodyType || "",
																								height: user.height || 0,
																								heightIsMetric:
																									user.heightIsMetric || false,
																								drink: user.drink || "",
																								smoke: user.smoke || "",
																								marijuana: user.marijuana || "",
																								religion: user.religion || "",
																								religionActivity:
																									user.religionActivity || "",
																								politics: user.politics || "",
																								children: user.children || "",
																								fertilization:
																									user.fertilization || [],
																								relationshipStatus:
																									user.relationshipStatus || "",
																								hometownCountry:
																									user.hometownCountry || "",
																								hometownZip:
																									user.hometownZip || "",
																								education: user.education || "",
																								school: user.school || ""
																							};

																							updateProfile({
																								variables: { ...vars }
																							}).then(data => {
																								console.log(data);
																							});
																							break;
																						case 26 == this.state.view:
																							updateCoParentRanks({
																								variables: {
																									qualities: user.coparentRanks.join(
																										"."
																									)
																								}
																							});
																							break;
																						case 25 == this.state.view:																						
																							updateConversationStarters({
																								variables: {
																									list: JSON.stringify(
																										user.convoStarters
																									)
																								}
																							});
																							
																							break;
																						case "image-remove":
																							refetch();
																							break;
																						case "image-add":
																							refetch();
																							break;
																						case "image-reorder":
																							refetch();
																							break;
																					}
																				}}
																			/>
																		</div>
																		<div className="editProfileFormCancel">
																			<Button
																				text="Cancel"
																				onClick={() => {
																					this.setView(0);
																				}}
																			/>
																		</div>
																	</div>
																</div>
															);
														}
													}}
												</Mutation>
											);
										}}
									</Mutation>
								);
							}}
						</Mutation>
					);
				}}
			</Query>
		);
	};
}
