import React from "react";
import SliderInput from "../form/ModamilyAppSliderInput";
import FormHeader from "../form/ModamilyAppFormHeader";

/**
 * @property data [Object] - { income: 100000 } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppIncome extends React.Component {
	state = {
		data: this.props.data || { income: 100000 }
	};

	validate = () => {
		let data = this.state.data;
		if (!data.income) {
			this.props.invalidate();
		} else {
			this.props.onValidChange(data);
		}
	};

	/**
	 * @argument key [String] - key of the value that is to be changed
	 */
	updateData = (key, value) => {
		let data = this.state.data;

		if (!value) {
			this.props.invalidate();
		}

		data[key] = value;

		let state = this.state;

		state.data = data;

		this.setState(state);
		this.validate();
	};
	render = () => {
		let { data } = this.state;

		return (
			<div className="ModamilyAppIncome">
				<FormHeader text="Income" />
				<SliderInput
					onChange={data => {
						console.log(data);
						this.updateData("income", data);
						this.props.onValidChange({ income: data });
					}}
					step={5000}
					min={25000}
					max={1000000}
					value={data.income}
					units="currency"
				/>
			</div>
		);
	};
}
