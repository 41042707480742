import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";

/**
 * @property data [Object] - { ethnicity:null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppEthnicity extends React.Component {
	state = {
		data: this.props.data || { ethnicity: [] }
	};

	buttonOptions = [
		"african",
		"brazilian",
		"caribbean",
		"chinese",
		"czech",
		"danish",
		"dutch",
		"english",
		"filipino",
		"french",
		"german",
		"hungarian",
		"indian",
		"irish",
		"italian",
		"japanese",
		"jewish",
		"korean",
		"lebanese",
		"mexican",
		"native American",
		"norwegian",
		"pacific islander",
		"polish",
		"portuguese",
		"russian",
		"scottish",
		"slavic",
		"spanish",
		"swedish",
		"thai",
		"welsh",
		"prefer not to say"
	];

	componentWillMount = () => {
		let data = this.state.data;
		if (data.length > 0) {
			this.props.onValidChange({ ethnicity: data });
		} else {
			this.props.invalidate();
		}
	};

	selectItem = item => {
		let data = this.state.data.ethnicity;
		if (data.indexOf(item) > -1) {
			data.splice(data.indexOf(item), 1);
		} else {
			data.push(item);
		}

		if (data.length > 0) {
			this.props.onValidChange({ ethnicity: data });
		} else {
			this.props.invalidate();
		}
		this.setState({ data: { ethnicity: data } });
	};

	render() {
		let selectedIndexes = [];

		for (let index = 0; index < this.state.data.ethnicity.length; index++) {
			const ethnicity = this.state.data.ethnicity[index];
			let i = this.buttonOptions.indexOf(ethnicity);
			if (i > -1) {
				selectedIndexes.push(i);
			}
		}

		return (
			<div className="ethnicityForm">
				<ModamilyAppFormHeader text="Ethnicity" />
				<ModamilyAppButtonGroup
					type="check"
					data={this.buttonOptions}
					onButtonClick={this.selectItem}
					selected={selectedIndexes}
				/>
			</div>
		);
	}
}
