import React from "react";

import decode from "jwt-decode";
import { Query, Mutation, withApollo } from "react-apollo";

import config from "../../config/config";
import Queries from "../../apollo/queries/queries";
import TextInput from "../form/ModamilyAppTextInput";
import RaceForm from "../onboarding/ModamilyAppRace";
import WorkForm from "../onboarding/ModamilyAppWork";
import FormHeader from "../form/ModamilyAppFormHeader";
import Mutations from "../../apollo/mutations/mutations";
import IncomeForm from "../onboarding/ModamilyAppIncome";
import HeightForm from "../onboarding/ModamilyAppHeight";
import BirthForm from "../onboarding/ModamilyAppBirthForm";
import HairForm from "../onboarding/ModamilyAppNaturalHair";
import EyeColorForm from "../onboarding/ModamilyAppEyeColor";
import GenderForm from "../onboarding/ModamilyAppGenderForm";
import HometownForm from "../onboarding/ModamilyAppHometown";
import ReligionForm from "../onboarding/ModamilyAppReligion";
import EducationForm from "../onboarding/ModamilyAppEducation";
import EthnicityForm from "../onboarding/ModamilyAppEthnicity";
import LookingForForm from "../onboarding/ModamilyAppLookingFor";
import OrientationForm from "../onboarding/ModamilyAppSexualOrientation";
import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import GenericForm from "../settings/forms/accountDetails/ModamilyAppAdditionalDetailsForm";
import RelationshipStatusForm from "../settings/forms/accountDetails/ModamilyAppRelationshipStatusForm";
import Button from "../common/ModamilyAppButton";

import TabMenuItem from "../common/ModamilyAppTabMenuItem";
import TabMenuHeader from "../common/ModamilyAppTabMenuHeader";
import closeWhite from "../../resources/images/appAssets/back.png";

import FormOptions from "../../constants/formOptions";
import NameForm from "../onboarding/ModamilyAppNameForm";
import CurrentLocationForm from "../onboarding/ModamilyAppCurrentLocation";

class AdminEditUser extends React.Component {
	state = {
		userId: this.props.user.id,
		slideIndex: 0,
		form: {
			type: "",
			index: 0
		},
		user: this.props.user
	};

	nextImage = () => {
		let index = this.state.slideIndex;
		index++;
		if (index > this.props.user.images.length) {
			index = 0;
		}
		this.setState({ slideIndex: index });
	};

	pickImage = index => {
		this.setState({ slideIndex: index });
	};

	prevImage = () => {
		let index = this.state.slideIndex;
		index--;
		if (index < 0) {
			index = this.props.user.images.length - 1;
		}
		this.setState({ slideIndex: index });
	};

	renderMenuComponenet = user => {
		return (
			<div className="adminUserProfile">
				<div className="exitAdminProfile" onClick={this.props.exitEditUser}>
					<img className="exitProfileImg" src={closeWhite} />
					<div className="userProfileName">
						<FormHeader text={user.firstName + " " + user.lastName} />
					</div>
				</div>
				<div className="adminUserProfileImage">
					<div className="profileImageSlideshow">
						{user.images.map((e, index) => (
							<img
								alt={""}
								key={index}
								className={
									"profileSlideshowImage" +
									(index === this.state.slideIndex
										? " slideActive"
										: " slideInactive")
								}
								src={config.AWS_URL + e.path}
							/>
						))}
						{user.images.length > 1 ? (
							<div className="slideDots">
								{user.images.slice(0, 6).map((e, index) => {
									return (
										<div
											className={
												"slideDot" +
												(index === this.state.slideIndex ? " activeDot" : "")
											}
											onClick={e => {
												this.pickImage(index);
												e.stopPropagation();
											}}
										/>
									);
								})}
							</div>
						) : null}
					</div>
				</div>
				<div>
					<Query
						query={Queries.adminGetUserAccountDetails}
						variables={{ userId: user.id }}>
						{({ loading, error, data, refetch }) => {
							let blockedUserCount = "...loading";
							let accountDetails = null;
							if (loading || !user) {
								return <ModamilyAppLoadingComponent />;
							}
							if (error) {
								console.log(error);
							}
							if (data) {
								let response = decode(data.adminGetUserAccountDetails);

								if (response.action === "success") {
									accountDetails = response.data.user;
									user.email = response.data.user.email;
									blockedUserCount = response.data.blockedUserCount;
								}
							}

							let units = user.heightIsMetric ? "cm" : "in";

							return (
								<div className="accountDetails">
									<TabMenuHeader text="Account" />
									<TabMenuItem
										header="Name"
										data={user ? user.firstName + " " + user.lastName : null}
										onClick={() => this.showEditForm(0, "accountDetails")}
									/>
									<TabMenuItem
										header="Mobile Number"
										data={user ? user.phone : ""}
										onClick={() => this.showEditForm(1, "accountDetails")}
									/>
									<TabMenuItem
										header="Email Address"
										data={user ? user.email : null}
										onClick={() => this.showEditForm(2, "accountDetails")}
									/>
									<TabMenuItem
										header="Current Location"
										data={user ? user.country + ", " + user.zipcode : null}
										onClick={() => this.showEditForm(3, "accountDetails")}
									/>
									<TabMenuHeader text="Membership" />
									<TabMenuItem
										header={
											user
												? user.subscription
													? "Manage Membership"
													: "Upgrade Account"
												: "Loading..."
										}
										data={
											user
												? user.subscription
													? user.subscription.subType
													: "Go Premium!"
												: null
										}
										onClick={() => {
											this.showEditForm(0, "upgrade");
										}}
									/>
									<div className="editProfileMenu">
										<TabMenuHeader text="BASIC INFO" />
										<TabMenuItem
											header="AGE"
											data={user.age}
											onClick={() => {
												this.showEditForm(0, "editProfile");
											}}
										/>
										<TabMenuItem
											header="GENDER"
											data={user.gender}
											onClick={() => {
												this.showEditForm(1, "editProfile");
											}}
										/>
										<TabMenuItem
											header="SEXUAL ORIENTATION"
											data={user.orientation}
											onClick={() => {
												this.showEditForm(2, "editProfile");
											}}
										/>
										<TabMenuItem
											header="HOMETOWN"
											data={
												user.hometownCountry
													? user.hometownCountry + ", " + user.hometownZip
													: " "
											}
											onClick={() => {
												this.showEditForm(3, "editProfile");
											}}
										/>
										<TabMenuItem
											header="LOOKING FOR"
											hideOverflow
											data={user.lookingFor.join(", ")}
											onClick={() => {
												this.showEditForm(4, "editProfile");
											}}
										/>
										<TabMenuItem
											header="EDUCATION"
											data={user.education + ", " + user.school}
											onClick={() => {
												this.showEditForm(5, "editProfile");
											}}
										/>
										<TabMenuItem
											header="WORK"
											data={
												user.occupationTitle + ", " + user.occupationWorkplace
											}
											onClick={() => {
												this.showEditForm(6, "editProfile");
											}}
										/>
										<TabMenuItem
											header="INCOME"
											data={user.income}
											onClick={() => {
												this.showEditForm(7, "editProfile");
											}}
										/>
										<TabMenuItem
											header="RELGION"
											data={user.religion + ", " + user.religionActivity}
											onClick={() => {
												this.showEditForm(8, "editProfile");
											}}
										/>
										<TabMenuItem
											header="HEIGHT"
											data={user.height + units}
											onClick={() => {
												this.showEditForm(9, "editProfile");
											}}
										/>
										<TabMenuItem
											header="EYE COLOR"
											data={user.eyeColor}
											onClick={() => {
												this.showEditForm(10, "editProfile");
											}}
										/>
										<TabMenuItem
											header="NATURAL HAIR"
											data={user.hairColor}
											onClick={() => {
												this.showEditForm(11, "editProfile");
											}}
										/>
										<TabMenuItem
											header="RACE"
											data={user.race}
											onClick={() => {
												this.showEditForm(12, "editProfile");
											}}
										/>
										<TabMenuItem
											header="ETHNICITY"
											data={user.ethnicity.join(", ")}
											onClick={() => {
												this.showEditForm(13, "editProfile");
											}}
										/>
										<TabMenuHeader text="ADDITIONAL INFO" />
										<TabMenuItem
											header="RELATIONSHIP STATUS"
											data={user.relationshipStatus || "Add"}
											onClick={() => {
												this.showEditForm(14, "editProfile");
											}}
										/>
										<TabMenuItem
											header="BODY TYPE"
											data={user.bodyType || "Add"}
											onClick={() => {
												this.showEditForm(15, "editProfile");
											}}
										/>
										<TabMenuItem
											header="DIET"
											data={user.diet || "Add"}
											onClick={() => {
												this.showEditForm(16, "editProfile");
											}}
										/>
										<TabMenuItem
											header="DRINKS"
											data={user.drink || "Add"}
											onClick={() => {
												this.showEditForm(17, "editProfile");
											}}
										/>

										<TabMenuItem
											header="MARIJUANA"
											data={user.marijuana || "Add"}
											onClick={() => {
												this.showEditForm(19, "editProfile");
											}}
										/>
										<TabMenuItem
											header="SMOKE"
											data={user.smoke || "Add"}
											onClick={() => {
												this.showEditForm(20, "editProfile");
											}}
										/>
										<TabMenuItem
											header="FERTILIZATION OPTIONS"
											data={user.fertilization.join(", ") || "Add"}
											onClick={() => {
												this.showEditForm(21, "editProfile");
											}}
										/>
										<TabMenuItem
											header="BLOOD TYPE"
											data={user.bloodType || "Add"}
											onClick={() => {
												this.showEditForm(22, "editProfile");
											}}
										/>
										<TabMenuItem
											header="CHILDREN"
											data={user.children || "Add"}
											onClick={() => {
												this.showEditForm(23, "editProfile");
											}}
										/>
										<div className="dangerousActions">
											<div className="deactivateAccountButtonContainer">
												<Button
													style="white"
													text= {this.state.user.active ? "Deactivate User" : "Activate User"}
													onClick={this.state.user.active ? 
															() => this.handleDeactivateAccount(user.id) : 
															() => this.handleActivateAccount(user.id) 
														}
												/>
											</div>
											<div className="deactivateAccountButtonContainer">
												<Button
													style="white"
													text="Destroy User"
													onClick={evt => {
														this.showEditForm(0, "deleteProfile");
													}}
												/>
											</div>
										</div>
									</div>
								</div>
							);
						}}
					</Query>
				</div>
			</div>
		);
	};

	handleDeactivateAccount = async (userID) => {
		try {
			const { client } = this.props;
			const response = await client.mutate({ mutation: Mutations.updateUserActive, variables: { userID , active: false }})
			const result = decode(response.data.updateUserActive);
			if(result.action === 'success') {
				let { user } = this.state;
				this.setState({
					user: { ...user, active: false }
				});
			} else {
				throw new Error('Error deactivating account.');
			}
		} catch(err) {
			console.log('error deactivating account.')
		}
	}


	handleActivateAccount = async (userID) => {
		try {
			const { client } = this.props;
			const response = await client.mutate({ mutation: Mutations.updateUserActive, variables: { userID, active: true }})
			const result = decode(response.data.updateUserActive);
			if(result.action === 'success') {
				let { user } = this.state;
				this.setState({
					user: { ...user, active: true }
				});
			} else {
				throw new Error('Error activating account.');
			}
		} catch(err) {
			console.log('error activating account.')
		}
	}

	getSelectedIndexes = (prefs, options) => {
		if (!prefs) {
			return null;
		}
		let arr = [];

		for (let index = 0; index < prefs.length; index++) {
			const gender = prefs[index];
			let i = -1;
			options.forEach(e => {
				if (e.toUpperCase() === gender.toUpperCase()) {
					i = options.indexOf(e);
				}
			});
			if (i >= 0) {
				arr.push(i);
			}
		}
		return arr;
	};

	showEditForm = (index, formType) => {
		let refetch = index == 0;
		this.setState({
			form: { type: formType, index: index, valid: false },
			refetch
		});
	};

	validate = valid => {
		let form = this.state.form;
		form.valid = valid;
		this.setState({ form });
	};

	render = () => {
		return (
			<Mutation mutation={Mutations.adminUpgradeAccount}>
				{(adminUpgradeAccount, { loading, data, error }) => {
					if (loading) {
						return <ModamilyAppLoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						let response = decode(data.adminUpgradeAccount);
					}
					return (
						<Mutation mutation={Mutations.adminUpdateProfile}>
							{(adminUpdateProfile, { loading, data, error }) => {
								if (loading) {
									return <ModamilyAppLoadingComponent />;
								} else if (error) {
									console.log(error);
								} else if (data) {
									let response = decode(data.adminUpdateProfile);
								}
								return (
									<Mutation mutation={Mutations.adminDeleteUser}>
										{(adminDeleteUser, { loading, data, error }) => {
											if (loading) {
												return <ModamilyAppLoadingComponent />;
											} else if (error) {
												console.log(error);
											} else if (data) {
												window.location.reload();
											}

											return (
												<Mutation mutation={Mutations.adminUpdateUser}>
													{(adminUpdateUser, { loading, data, error }) => {
														if (loading) {
															return <ModamilyAppLoadingComponent />;
														} else if (error) {
															console.log(error);
														} else if (data) {
															let response = decode(data.adminUpdateUser);
														}
														return (
															<Query
																query={Queries.adminGetUserProfile}
																variables={{ userId: this.state.userId }}>
																{({ loading, error, data, refetch }) => {
																	let user = null;
																	if (loading) {
																		return <ModamilyAppLoadingComponent />;
																	}
																	if (error) {
																		console.log(error);
																	}
																	if (data) {
																		let response = decode(
																			data.adminGetUserProfile
																		);
																		delete response.data.userId;
																		user = response.data;
																		if (!this.state.user) {
																			this.setState({ user });
																		}
																	}

																	if (this.state.refetch) {
																		refetch().then(data => {
																			this.setState({ refetch: false });
																		});
																	}

																	let accountForms = [
																		<NameForm
																			key={
																				user
																					? user.firstName + user.lastName
																					: null
																			}
																			data={
																				user
																					? {
																							firstName: user.firstName,
																							lastName: user.lastName
																					  }
																					: null
																			}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				let newUser = { ...user, ...data };
																				user = newUser;
																				this.setState({ user });
																				this.validate(true);
																			}}
																		/>,

																		<div className="confirmEmailPage">
																			<FormHeader text="Phone" />
																			<TextInput
																				key={
																					user
																						? user.phone
																						: user.firstName + user.age
																				}
																				type="phone"
																				label="Phone"
																				reference="phoneSign"
																				ref="phoneInput"
																				value={user ? user.phone : null}
																				validate={true}
																				onContentChange={data => {
																					let newUser = { ...user };
																					newUser.phone = data
																						? data
																						: user.phone;

																					this.validate(true);
																					user = newUser;
																					this.setState({ user });
																				}}
																				onEnterPress={this.onEnterPress}
																			/>
																		</div>,
																		<div className="confirmEmailPage">
																			<FormHeader text="Email" />
																			<TextInput
																				key={user ? user.email : null}
																				type="email"
																				label="Email"
																				reference="emailSign"
																				ref="emailInput"
																				value={this.state.user ? this.state.user.email : ''}
																				validate={true}
																				onContentChange={data => {
																					let { user } = this.state;
																					let newUser = { ...user };
																					newUser.email = data
																						? data
																						: user.email;

																					this.validate(true);
																					user = newUser;
																					this.setState({ user });
																				}}
																				onEnterPress={this.onEnterPress}
																			/>
																		</div>,
																		<CurrentLocationForm
																			key={
																				user
																					? user.country + user.zipcode
																					: null
																			}
																			data={
																				user
																					? {
																							country: user.country,
																							zipcode: user.zipcode
																					  }
																					: null
																			}
																			invalidate={() => {}}
																			onValidChange={data => {
																				this.validate(true);
																				user.country = data.country;
																				user.zipcode = data.zipcode;
																				this.setState({ user });
																			}}
																		/>
																	];

																	let editProfileForms = [
																		<BirthForm
																			data={{ dob: user.dob }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.dob = data.dob;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<GenderForm
																			data={{ gender: user.gender }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.gender = data.gender;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<OrientationForm
																			data={{ orientation: user.orientation }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.orientation = data.orientation;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<HometownForm
																			data={{
																				hometownCountry: user.hometownCountry,
																				hometownZipcode: user.hometownZip
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.hometownCountry =
																					data.hometownCountry;
																				user.hometownZip = data.hometownZipcode;
																				console.log('user', user);
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<LookingForForm
																			data={{ lookingFor: user.lookingFor }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.lookingFor = data.lookingFor;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<EducationForm
																			data={{
																				educationLevel: user.education,
																				school: user.school
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.education = data.educationLevel;
																				user.school = data.school;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<WorkForm
																			data={{
																				occupationTitle: user.occupationTitle,
																				occupationWorkplace:
																					user.occupationWorkplace
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.occupationTitle =
																					data.occupationTitle;
																				user.occupationWorkplace =
																					data.occupationWorkplace;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<IncomeForm
																			data={{ income: user.income }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.income = data.income;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<ReligionForm
																			data={{
																				religion: user.religion,
																				religionActivity: user.religionActivity
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.religion = data.religion;
																				user.religionActivity =
																					data.religionActivity;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<HeightForm
																			data={{
																				height: user.height,
																				isMetric: user.heightIsMetric
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.height = data.height;
																				user.heightIsMetric = data.isMetric;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<EyeColorForm
																			data={{ eyeColor: user.eyeColor }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.eyeColor = data.eyeColor;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<HairForm
																			data={{ hairColor: user.hairColor }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.hairColor = data.hairColor;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<RaceForm
																			data={{ race: user.race }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.race = data.race;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<EthnicityForm
																			data={{ ethnicity: user.ethnicity }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.ethnicity = data.ethnicity;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<RelationshipStatusForm
																			data={{
																				relationshipStatus:
																					user.relationshipStatus
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.relationshipStatus =
																					data.relationshipStatus;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																		/>,
																		<GenericForm
																			data={{ bodyType: user.bodyType }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.bodyType = data.bodyType;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Body Type"
																			buttonType="radio"
																			options={FormOptions.bodyTypes}
																		/>,
																		<GenericForm
																			data={{ diet: user.diet }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.diet = data.diet;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Diet"
																			buttonType="radio"
																			options={FormOptions.diet}
																		/>,
																		<GenericForm
																			data={{ drink: user.drink }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.drink = data.drink;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Drinks"
																			buttonType="radio"
																			options={FormOptions.drinks}
																		/>,
																		<GenericForm
																			data={{ drugs: user.drugs }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.drugs = data.drugs;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Drugs"
																			buttonType="radio"
																			options={FormOptions.drugs}
																		/>,
																		<GenericForm
																			data={{ marijuana: user.marijuana }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.marijuana = data.marijuana;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Marijuana"
																			buttonType="radio"
																			options={FormOptions.marijuana}
																		/>,
																		<GenericForm
																			data={{ smoke: user.smoke }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.smoke = data.smoke;
																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Smoke"
																			buttonType="radio"
																			options={FormOptions.smoke}
																		/>,
																		<GenericForm
																			data={{
																				fertilization: user.fertilization
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.fertilization = data.fertilization;
																				this.setState({ user: user }, () => {
																					this.validate(true);
																				});
																			}}
																			formHeader="Fertilization Options"
																			buttonType="check"
																			options={FormOptions.fertilization}
																		/>,
																		<GenericForm
																			data={{ bloodType: user.bloodType }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.bloodType = data.bloodType;
																				this.setState({ user: user }, () => {
																					this.validate(true);
																				});
																			}}
																			formHeader="Blood Type"
																			buttonType="radio"
																			options={FormOptions.bloodType}
																		/>,
																		<GenericForm
																			data={{ children: user.children }}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				user.children = data.children;
																				this.setState({ user: user }, () => {
																					this.validate(true);
																				});
																			}}
																			formHeader="Children"
																			buttonType="radio"
																			options={FormOptions.children}
																		/>
																	];

																	let upgradeForms = [
																		<GenericForm
																			data={{
																				subType: user.subscription
																					? user.subscription.subType
																					: "free"
																			}}
																			invalidate={() => {
																				this.validate(false);
																			}}
																			onValidChange={data => {
																				if (user.subscription) {
																					user.subscription.subType =
																						data.subType;
																				} else {
																					user.subscription = data;
																				}

																				this.setState({ user: user });
																				this.validate(true);
																			}}
																			formHeader="Subscription"
																			buttonType="radio"
																			options={[
																				"1m",
																				"3m",
																				"6m",
																				"12m",
																				"free"
																			]}
																		/>
																	];

																	let deleteForm = [
																		<div className="deleteUser">
																			<FormHeader text="Are you sure you want to delete this user?" />
																			<div className="fd-row">
																				<div className="refreshUserListBtn">
																					<Button
																						text="Cancel"
																						style="red"
																						onClick={() => {
																							this.showEditForm(0, "");
																						}}
																					/>
																				</div>
																				<div className="refreshUserListBtn">
																					<Button
																						text="Confirm"
																						style="white"
																						onClick={() => {
																							adminDeleteUser({
																								variables: {
																									id: this.state.user.id
																								}
																							}).then(data => {
																								window.location.reload();
																							});
																						}}
																					/>
																				</div>
																			</div>
																		</div>
																	];

																	let form = this.state.form;
																	if (form.type && form.type != "") {
																		switch (form.type) {
																			case "deleteProfile":
																				return (
																					<div className="deleteFormContainer">
																						{deleteForm[form.index]}
																					</div>
																				);
																			case "editProfile":
																				return (
																					<div className="formContainer">
																						<div
																							className="exitAdminProfile"
																							onClick={() =>
																								this.showEditForm(0, "")
																							}>
																							<img
																								className="exitProfileImg"
																								src={closeWhite}
																							/>
																						</div>
																						{editProfileForms[form.index]}
																						<Button
																							disabled={!this.state.form.valid}
																							text="Apply"
																							onClick={() => {
																								let obj = {
																									active: this.state.user.active,
																									gender: this.state.user
																										.gender,
																									dob: this.state.user.dob,
																									lookingFor: this.state.user
																										.lookingFor,
																									orientation: this.state.user
																										.orientation,
																									hairColor: this.state.user
																										.hairColor,
																									eyeColor: this.state.user
																										.eyeColor,
																									bloodType: this.state.user
																										.bloodType,
																									ethnicity: this.state.user
																										.ethnicity,
																									race: this.state.user.race,
																									diet: this.state.user.diet,
																									occupationTitle: this.state
																										.user.occupationTitle,
																									occupationWorkplace: this
																										.state.user
																										.occupationWorkplace,
																									income: this.state.user
																										.income,
																									bodyType: this.state.user
																										.bodyType,
																									height: this.state.user
																										.height,
																									heightIsMetric: this.state
																										.user.heightIsMetric,
																									drink: this.state.user.drink,
																									smoke: this.state.user.smoke,
																									marijuana: this.state.user
																										.marijuana,
																									religion: this.state.user
																										.religion,
																									religionActivity: this.state
																										.user.religionActivity,
																									politics: this.state.user
																										.politics,
																									children: this.state.user
																										.children,
																									fertilization: this.state.user
																										.fertilization,
																									hometownCountry: this.state
																										.user.hometownCountry,
																									hometownZip: this.state.user
																										.hometownZip,
																									relationshipStatus: this.state
																										.user.relationshipStatus,
																									school: this.state.user
																										.school,
																									education: this.state.user
																										.education,
																									userId: this.state.userId
																								};
																								adminUpdateProfile({
																									variables: obj
																								})
																									.then(data => {
																										this.showEditForm(0, "");
																									})
																									.catch(error => {
																										console.log(error);
																									});
																							}}
																						/>
																					</div>
																				);

																			case "accountDetails":
																				return (
																					<div className="formContainer">
																						<div
																							className="exitAdminProfile"
																							onClick={() =>
																								this.showEditForm(0, "")
																							}>
																							<img
																								className="exitProfileImg"
																								src={closeWhite}
																							/>
																						</div>
																						{accountForms[form.index]}
																						<Button
																							onClick={() => {
																								
																								let obj = {};
																								if(this.state.user.id) {
																									obj.id = this.state.user.id;
																								} else {
																									throw new Error('The id is required');
																								}

																								if(this.state.user.email) {
																									obj.email = this.state.user.email;
																								}
																								if(this.state.user.firstName) {
																									obj.firstName = this.state.user.firstName;
																								}
																								if(this.state.user.lastName) {
																									obj.lastName = this.state.user.lastName;
																								}
																								if(this.state.user.zipcode) {
																									obj.zipcode = this.state.user.zipcode;
																								}
																								if(this.state.user.city) {
																									obj.city = this.state.user.city;
																								}
																								if(this.state.user.phone) {
																									obj.phone = this.state.user.phone;
																								}
																								if(this.state.user.state) {
																									obj.state = this.state.user.state;
																								}
																								if(this.state.user.country) {
																									obj.country = this.state.user.country;
																								}
																								if(this.state.user.addressLine1) {
																									obj.addressLine1 = this.state.user.addressLine1;
																								}
																								if(this.state.user.addressLine2) {
																									obj.addressLine2 = this.state.user.addressLine2;
																								}

																								adminUpdateUser({
																									variables: obj
																								})
																									.then(data => {
																										console.log(data);
																										this.showEditForm(0, "");
																									})
																									.catch(error => {
																										console.log(error);
																									});
																							}}
																							disabled={!this.state.form.valid}
																							text="Apply"
																						/>
																					</div>
																				);
																			case "upgrade":
																				return (
																					<div className="formContainer">
																						<div
																							className="exitAdminProfile"
																							onClick={() =>
																								this.showEditForm(0, "")
																							}>
																							<img
																								className="exitProfileImg"
																								src={closeWhite}
																							/>
																						</div>
																						{upgradeForms[form.index]}
																						<Button
																							onClick={() => {
																								let obj = {
																									subType: this.state.user
																										.subscription.subType,
																									userId: this.state.userId
																								};

																								adminUpgradeAccount({
																									variables: obj
																								})
																									.then(data => {
																										console.log(data);
																										this.showEditForm(0, "");
																									})
																									.catch(error => {
																										console.log(error);
																									});
																							}}
																							disabled={!this.state.form.valid}
																							text="Apply"
																						/>
																					</div>
																				);
																		}
																	} else {
																		return (
																			<div className="adminEditUser">
																				{this.renderMenuComponenet(user)}
																			</div>
																		);
																	}
																}}
															</Query>
														);
													}}
												</Mutation>
											);
										}}
									</Mutation>
								);
							}}
						</Mutation>
					);
				}}
			</Mutation>
		);
	};
}
export default withApollo(AdminEditUser);

