import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import _ from 'lodash';
import decode from "jwt-decode";
import debounce from 'lodash.debounce';

// redux
import { connect } from 'react-redux';
import { getUserRequest } from '../../redux/actions/users';
import { getBrowseRequest, setLikeRequest, setDislikeRequest } from '../../redux/actions/browse';
import { updateSearchPreferencesRequest, updateFetchProfilesRequest } from '../../redux/actions/searchPreferences';
import { getBrowseDataFiltered } from '../../redux/selectors/browse';

// apollo
import { withApollo } from 'react-apollo';
import queries from "../../apollo/queries/queries";
import Mutations from "../../apollo/mutations/mutations";

// modals
import ModamilyAppMatchModal from "../../components/modals/ModamilyAppMatchModal";

// common
import ModamilyAppNavBar from "../../components/common/ModamilyAppNavBar";
import ModamilyAppLoadingComponent from "../../components/common/ModamilyAppLoadingComponent";

// local
import BrowseCarousel from './BrowseCarousel';

// styles
import styles from './modamilyAppBrowse.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class ModamilyAppBrowse extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			getOnboardingStatus: false,
			getUser: false,
			getBrowse: false,
			users: [],
			usersDB: [],
			loading: false,
			showMatchModal: false,
			isLoading: false,
			hasMore: true,
			page: 1,
			error: '',
			limit: 10
		};

		// TODO enable this once we change flex-direction column, this will allow us to have a infinite scroll.
		// Binds our scroll event handler
    window.onscroll = debounce(() => {
      const {
        loadUsers,
        state: {
          error,
          isLoading,
					hasMore,
					page,
					limit,
        },
			} = this;
			if (error || isLoading || !hasMore) return;
			// Checks that the page has scrolled to the bottom
      if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
        loadUsers( page + 1, limit);
      }
    }, 100);
	}

  static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.browseData.data.length >= prevState.users.length) {
			return {
				users: nextProps.browseData.data
			}
		} else return null;
	}

	async componentDidMount() {
		let { client } = this.props;
		const { page, limit } = this.state;
		this.setState({ loading: true })
		// todo move to redux and use redux-persist
		let user = decode(localStorage.getItem("auth.token"));
		if (!user) {
			return <Redirect to="/" />;
		}
		// filter & subscription 
		const filtersAndSubscriptionResponse = await client.query({query: queries.getZipcodeCountryAndSubscription});
		const filtersAndSubscription = this.handleFiltersAndSubscriptionResponse(filtersAndSubscriptionResponse);
		// onboarding
		const onboardingStatus = await client.query({query: queries.getOnboardingStatus});
		this.handleOnboardingResponse(onboardingStatus.data);
		// browse
		const browseDataV2 = await client.query({query: queries.getUsersBrowse, variables:{ page, limit, ...filtersAndSubscription, zipcode: filtersAndSubscription.zipCode }}); 
		this.handleBrowseResponse(browseDataV2.data);

		this.setState({ loading: false })
	};

	loadUsers = (page, limit) => {
		this.setState({ isLoading: true }, async () => {
			let { client } = this.props;
			let { users, zipCode, country, is_free } = this.state;
			const browseDataV2 = await client.query({query: queries.getUsersBrowse, variables:{ page, limit, zipcode: zipCode, country, is_free }});
			const newUsers = this.handleBrowseResponse(browseDataV2.data);
			users = users.concat(newUsers);
			this.setState({
				users,
				page,
				isLoading: false,
				hasMore: newUsers.length > 0
			});
			
		});
	}


	handleFiltersAndSubscriptionResponse = (res) => {
		try {
			let response = decode(res.data.getZipcodeCountryAndSubscription);
			if (response.action === "success") {
				this.setState({...response.data})
				console.log('response.data', response.data);
				return {
					...response.data
				}
			}
		} catch (error) {
			console.log(error, res);
		}
	};

	handleBrowseResponse = (data) => {
		try {
			let response = decode(data.getUsersBrowse);
			console.log('response', response);
			if (response.action === "success") {
				this.props.getBrowseRequest(response.data);
				return response.data;
			}
		} catch (error) {
			console.log(error, data);
			localStorage.clear();
		}
	};

	handleUserError = error => {
		console.log(error);
	};

	handleOnboardingError = error => {
		console.log(error);
	};

	handleBrowseError = error => {
		console.log(error);
	};

	handleOnboardingResponse = data => {
		try {
			let response = decode(data.getOnboardingStatus);
			let keys = Object.keys(response.data);
			for (let index = 0; index < keys.length; index++) {
				const key = keys[index];
				if (!response.data[key]) {
					this.props.history.push('/onboarding');
					return <Redirect to="/onboarding" />;
				}
			}
		} catch (error) {
			console.log(error, data);
			localStorage.clear();
		}
	};


	handleLike = async (val, user) => {
		try {
			const { client } = this.props;
			let { users } = this.state;
			this.setState({ likeLoading: true });
			const res = await client.mutate({
				mutation: Mutations.addRelationship,
				variables: {
					relationship: val,
					userb: user.id
				}
			});
			let response = decode(res.data.addRelationship);
			if (response.action === "success") {
				this.handleExitFullProfile();
				this.props.setLikeRequest(user.id);
				users = users.filter(u => u.id !== user.id);
				if (response.data.likedBack) {
					this.setState({ showMatchModal: response.data.likedBack, otherUser: user, users, likeLoading: false, fullProfile: false, fullProfileUser: null });
				} else {
					this.setState({ users, likeLoading: false, fullProfile: false, fullProfileUser: null });
				}
			} else {
				this.setState({ likeLoading: false, fullProfile: false, fullProfileUser: null });
			}
		} catch(err) {
			console.log('error liking user', err);
		}
	}

	handleUnlike = async (val, user) => {
		const { client } = this.props;
		let { users } = this.state;
		this.setState({ dislikeLoading: true });
		const res = await client.mutate({
			mutation: Mutations.addRelationship,
			variables: {
				relationship: val,
				userb: user.id
			}
		});
		let response = decode(res.data.addRelationship);
		if (response.action === "success") {
			this.handleExitFullProfile();
			this.props.setDislikeRequest(user.id);
			users = users.filter(u => u.id !== user.id);
			this.setState({ dislikeLoading: false, fullProfile: false, fullProfileUser: null,  users });
		} else {
			this.setState({ dislikeLoading: false, fullProfile: false, fullProfileUser: null });
		}
	}

	refreshClick = () => {
		window.location.href = "/browse";
	};

	handleFullProfileModal = (user) => {
		this.setState({
			fullProfile: !!user,
			fullProfileUser: user,
		});
	}

	handleExitFullProfile = () => {
		this.setState({
			fullProfile: false,
			fullProfileUser: null
		});
	}

	render = () => {
		let { fullProfile, fullProfileUser, user, users, loading, isLoading, likeLoading, dislikeLoading, showMatchModal } = this.state;

		if(loading) {
			return <ModamilyAppLoadingComponent />
		}
		return (
		<div className={cx('flexReset','modamilyAppBrowse')}>
				<ModamilyAppNavBar
					type="inApp"
					currentLink="browse"
				/>
				<div className={cx('flexReset', 'modamilyAppBrowse-pageContainer')}>
					<BrowseCarousel
						users={users}
						refreshClick={this.refreshClick}
						viewFullProfile={this.handleFullProfileModal}
						onExitFullProfile={this.handleExitFullProfile}
						likeUser={this.handleLike}
						dislikeUser={this.handleUnlike}
						fullProfile={fullProfile}
						fullProfileUser={fullProfileUser}
						likeLoading={likeLoading}
						dislikeLoading={dislikeLoading}
					/>
					{isLoading ? <ModamilyAppLoadingComponent /> : null}
					<ModamilyAppMatchModal
						key={this.state.otherUser}
						userMatchedWithImage={
							this.state.otherUser
								? this.state.otherUser.images[0]
								: null
						}
						currentUserImage={
							user ? user.images[0] : null
						}
						active={showMatchModal}
					/>
				</div>
			</div>
		);
	};
}

export default connect(
	({searchPreferences, browse})=> {
		return {
			browseData: getBrowseDataFiltered(browse.items, searchPreferences.items),
			searchPreferences: searchPreferences
		}
	}, 
	{ 
		getUserRequest, 
		updateSearchPreferencesRequest, 
		getBrowseRequest ,
		setLikeRequest,
		setDislikeRequest,
		updateFetchProfilesRequest
	})(withApollo(ModamilyAppBrowse));
