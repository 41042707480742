exports.genders = ["male", "female", "trans male", "trans female", "all"];

exports.educationLevels = [
	"undergrad",
	"postgrad",
	"highschool or some equivalent",
	"some college",
	"all"
];

exports.politics = ["liberal", "moderate", "conservative", "other", "all"];

exports.religion = [
	"atheist",
	"christian",
	"catholic",
	"jewish",
	"muslim",
	"buddhist",
	"hinduism",
	"orthodox",
	"spiritual",
	"all"
];

exports.ethnicities = [
	"african",
	"brazilian",
	"caribbean",
	"chinese",
	"czech",
	"danish",
	"dutch",
	"english",
	"filipino",
	"french",
	"german",
	"hungarian",
	"indian",
	"irish",
	"italian",
	"japanese",
	"jewish",
	"korean",
	"lebanese",
	"mexican",
	"native american",
	"norwegian",
	"pacific islander",
	"polish",
	"portuguese",
	"russian",
	"scottish",
	"slavic",
	"spanish",
	"swedish",
	"thai",
	"welsh",
	"prefer not to say",
	"all"
];

exports.eyeColor = [
	"black",
	"blue",
	"brown",
	"green",
	"hazel",
	"no Preference"
];

exports.hairColor = [
	"auburn",
	"brown",
	"black",
	"blonde",
	"dark brown",
	"dirty blonde",
	"light brown",
	"red",
	"strawberry blonde",
	"orange",
	"gray",
	"other",
	"all"
];

exports.races = [
	"asian",
	"black",
	"white",
	"hispanic/latin",
	"native american",
	"middle eastern",
	"mixed",
	"all"
];

exports.lookingFors = [
	"dating leading to children",
	"co-parent",
	"open to being a known donor",
	"looking for a known donor",
	"surrogate",
	"all"
];

exports.bodyTypes = [
	"thin",
	"athletic",
	"normal",
	"curvy",
	"overweight",
	"prefer not to say",
	"all"
];

exports.diet = [
	"anything goes",
	"healthy",
	"vegetarian",
	"vegan",
	"kosher",
	"only eat to survive",
	"other",
	"all"
];

exports.drinks = ["very often", "socially", "rarely", "not at all", "all"];

exports.drugs = ["often", "sometimes", "never", "all"];

exports.marijuana = ["yes", "sometimes", "no", "prefer not to say", "all"];

exports.smoke = ["yes", "socially", "no", "all"];

exports.fertilization = [
	"IVF",
	"home insemination",
	"artificial insemination",
	"natural insemination",
	"depends on the person",
	"all"
];

exports.bloodType = ["A+", "A-", "B+", "B-", "AB+", "O+", "O-", "all"];

exports.children = [
	"I have none",
	"I have one",
	"I have two or more children",
	"all"
];

exports.orientation = ["straight", "gay", "bi", "undeclared", "all"]