export const Types = {
  GET_BROWSE_REQUEST: 'browse/get_browse_request',
  GET_BROWSE_SUCCESS: 'browse/get_browse_success',
  SET_LIKE_REQUEST: 'browse/set_like_request',
  SET_LIKE_SUCCESS: 'browse/set_like_success',
  SET_DISLIKE_REQUEST: 'browse/set_dislike_request',
  SET_DISLIKE_SUCCESS: 'browse/set_dislike_success',
}

export const getBrowseRequest = (browseData) => ({
  type: Types.GET_BROWSE_REQUEST,
  payload: {
    browseData
  }
});

export const getBrowseSuccess = (browseData) => {
  return ({
  type: Types.GET_BROWSE_SUCCESS,
  payload: {
    browseData
  }
})};

export const setLikeRequest = (userid) => ({
  type: Types.SET_LIKE_REQUEST,
  payload: {
    userid
  }
});

export const setLikeSuccess = (userid) => {
  return ({
  type: Types.SET_LIKE_SUCCESS,
  payload: {
    userid
  }
})};


export const setDislikeRequest = (userid) => ({
  type: Types.SET_DISLIKE_REQUEST,
  payload: {
    userid
  }
});

export const setDislikeSuccess = (userid) => {
  return ({
  type: Types.SET_DISLIKE_SUCCESS,
  payload: {
    userid
  }
})};

