import React from 'react';

//form
import FormHeader from "../../../form/ModamilyAppFormHeader";
import ButtonGroup from "../../../form/ModamilyAppButtonGroup";

// cosntants
import Options from "../../../../constants/formOptions";


const LookingFor = ({ selectedGendersIndexes, selectedLookingForIndexes, searchPreferences, handleUpdate }) => (
  <div className="searchPrefForm">
		  <div className="searchPrefLookingForFormContainer">
		  	<FormHeader text="I'm Looking For" />
		  	<p className="purpleText boldWeight">
		  		SELECT ALL THAT APPLY
		  	</p>
		  	<ButtonGroup
		  		selected={selectedGendersIndexes}
		  		type="check"
		  		data={Options.genders}
		  		onButtonClick={(value, index) => {
						try {
							if (index > -1) {
								searchPreferences.gender.push(value.toLowerCase());
								handleUpdate(searchPreferences);
							} else {
								searchPreferences.gender.splice(
									searchPreferences.gender.indexOf(
										value.toLowerCase()
									),
									1
								);
								handleUpdate(searchPreferences);
							}
						} catch(e) {
							console.log('error trying to select the option',e);
						}
		  		}}
		  	/>
		  	<FormHeader className="redText" text="&" />
		  	<ButtonGroup
		  		selected={selectedLookingForIndexes}
		  		type="check"
		  		data={Options.lookingFors}
		  		onButtonClick={(value, index) => {
						try {
							if (index > -1) {
								searchPreferences.lookingFor.push(
									value.toLowerCase()
								);
								handleUpdate(searchPreferences);
							} else {
								searchPreferences.lookingFor.splice(
									searchPreferences.lookingFor.indexOf(
										value.toLowerCase()
									),
									1
								);
								handleUpdate(searchPreferences);
							}
						} catch(e) {
							console.log('error trying to select the option',e);
						}
		  		}}
		  	/>
		  </div>
		</div>
)


export default LookingFor;