import React from "react";
import checkImage from "../../resources/images/active_2019-05-10/active@3x.png";

/**
 * @property {Boolean} active - if the button is active or not
 * @property {Function} onClick - a function run when the button is clicked
 */
export default class ModamilyAppCheckButton extends React.Component {

  constructor(props) {
    super(props);

    let manualSelection = false;
    if ('manualSelection' in props && props.manualSelection) {
      manualSelection = true;
    }

    this.state = {
      manualSelection,
      active: !!this.props.active
    };
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if ('manualSelection' in nextProps && nextProps.manualSelection) {
      return nextProps;
    }
    return null;
  }

  onClick = evt => {
    let newState = {};
    const active = !this.state.active;

    if (!this.state.manualSelection) {
      this.setState({active}, () => {
        if (this.props.onClick) {
          this.props.onClick(this.state.active);
        }
      });
    } else {
      this.props.onClick(active);
    }
  };

  render() {
    // console.log(this.props.text);
    // console.log('ModamilyAppCheckButton.render.active', this.state.active);
    // console.log('----');

    return (
      <div className={"checkButtonContainer"} onClick={this.onClick}>
        <div className={"checkButton"}>
          {this.state.active ? <img className="checkImage" src={checkImage} /> : null}
        </div>
        <div className="checkButtonTextContainer">
          <p className="checkButtonText">{this.props.text}</p>
        </div>
      </div>
    );
  }
}
