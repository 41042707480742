import React from "react";
import decode from "jwt-decode";
import { Link } from "react-router-dom";
import { Mutation, Query } from "react-apollo";

import Button from "../common/ModamilyAppButton";
import TabMenuItem from "../common/ModamilyAppTabMenuItem";
import TabMenuHeader from "../common/ModamilyAppTabMenuHeader";
import LoadingComponent from "../common/ModamilyAppLoadingComponent";

import TextInput from "../form/ModamilyAppTextInput";
import FormHeader from "../form/ModamilyAppFormHeader";
import ButtonGroup from "../form/ModamilyAppButtonGroup";

import UpgradeModal from "../modals/ModamilyAppUpgrade";
import ConfirmDeactivate from "../modals/ModamilyAppConfirmDeactivate";

import Queries from "../../apollo/queries/queries";
import Mutations from "../../apollo/mutations/mutations";

import NameForm from "../onboarding/ModamilyAppNameForm";
import CurrentLocationForm from "../onboarding/ModamilyAppCurrentLocation";

class AccountDetailsMenu extends React.Component {
	state = {
		user: this.props.user,
		deactivate: false,
		showUpgrade: false
	};

	onDeactivateAccountClick = () => {
		this.setState({ deactivate: true });
	};

	closeModal = () => {
		this.setState({ deactivate: false });
	};

	closeUpgradeModal = () => {
		this.setState({ showUpgrade: false });
	};

	openUpgradeModal = () => {
		this.setState({ showUpgrade: true });
	};

	onLogoutClick = evt => {
		localStorage.clear();
		window.location.reload();
	};

	onConnectedAccountClick = (account, isActive) => {
		let state = this.state;
		state[account] = isActive;
		this.setState(state);
	};

	/**
	 * Generates a random string containing numbers and letters
	 * @param  {number} length The length of the string
	 * @return {string} The generated string
	 */
	generateRandomString = length => {
		let text = "";
		let possible =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		for (let i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	};

	/**
	 * Obtains parameters from the hash of the URL
	 * @return Object
	 */
	getHashParams = () => {
		let hashParams = {};
		let e,
			r = /([^&;=]+)=?([^&;]*)/g,
			q =
				window.location.hash.substring(1) || window.location.href.split("?")[1];
		while ((e = r.exec(q))) {
			hashParams[e[1]] = decodeURIComponent(e[2]);
		}

		return hashParams;
	};

	render = () => {
		let user = this.state.user;

		return (
			<Mutation mutation={Mutations.addApi}>
				{(addApi, { error, loading, data }) => {
					if (loading) {
						return <LoadingComponent />;
					} else if (error) {
						console.log(error);
					} else if (data) {
						console.log(data, localStorage.getItem("base.url"));
						if (localStorage.getItem("base.url"))
							window.location.href = localStorage.getItem("base.url");
					}
					const stateKey = "spotify_auth_state";
					let params = this.getHashParams();
					let access_token = params.access_token,
						state = params.state,
						storedState = localStorage.getItem(stateKey);
					// console.log(params);

					if (access_token && (state == null || state !== storedState)) {
					} else if (access_token && params) {
						localStorage.removeItem(stateKey);
						let tok = {
							accessToken: params.access_token,
							expiresIn: params.expires_in,
							code: params.code,
							redirectUri: localStorage.getItem("base.url")
						};
						addApi({
							variables: {
								token: JSON.stringify(tok),
								api: "spotify"
							}
						});
					} else if (params.code && params.state === storedState) {
						localStorage.removeItem(stateKey);
						let tok = {
							accessToken: params.access_token,
							expiresIn: params.expires_in,
							code: params.code,
							redirectUri: localStorage.getItem("base.url")
						};
						addApi({
							variables: {
								token: JSON.stringify(tok),
								api: "spotify"
							}
						});
					}

					return (
						<Query query={Queries.getAccountDetails}>
							{({ loading, error, data, refetch }) => {
								let blockedUserCount = "...loading";
								// console.log(loading, data, error, user);
								if (loading || !user) {
									return <LoadingComponent />;
								}
								if (error) {
									console.log(error);
								}
								if (data) {
									let response = decode(data.getAccountDetails);
									if (response.action === "success") {
										let newUser = {
											...user,
											...response.data.user,
											images: user.images,
											notifications: response.data.notifications
										};
										blockedUserCount = response.data.blockedUserCount;
										console.log({newUser});
										localStorage.setItem("user", JSON.stringify(newUser));
										user = newUser;
									}
								}

								if (this.props.refetch) {
									refetch().then(data => {
										this.props.updateNav();
										this.props.stopRefetch();
									});
								}

								return (
									<div className="accountDetails">
										<TabMenuHeader text="Account" />
										<TabMenuItem
											header="Name"
											data={user ? user.firstName + " " + user.lastName : null}
											onClick={() => this.props.setView(1)}
										/>
										<TabMenuItem
											header="Mobile Number"
											data={user ? user.phone : ""}
											onClick={() => this.props.setView(3)}
										/>
										<TabMenuItem
											header="Email Address"
											data={user ? user.email : null}
											onClick={() => this.props.setView(2)}
										/>
										<TabMenuItem
											header="Current Location"
											data={user ? user.country + ", " + user.zipcode : null}
											onClick={() => this.props.setView(4)}
										/>
										<TabMenuHeader text="Membership" />
										<TabMenuItem
											header={
												user
													? user.subscription
														? "Manage Membership"
														: "Upgrade Account"
													: "Loading..."
											}
											data={
												user
													? user.subscription
														? user.subscription.subType
														: "Go Premium!"
													: null
											}
											onClick={() => {
												this.openUpgradeModal();
											}}
										/>
										<TabMenuHeader text="Notifications" />
										<TabMenuItem
											header="Notification Options"
											data={
												user
													? user.notifications 
														? user.notifications.join(', ') 
														: "No Notifications"
													: "loading..."
											}
											onClick={() => this.props.setView(5)}
										/>
										<TabMenuHeader text="Connected Accounts" />

										<TabMenuItem
											key={
												"spotify" +
												(user.socialTokens
													? !!user.socialTokens.spotifyToken
													: "no")
											}
											active={
												user.socialTokens
													? !!user.socialTokens.spotifyToken
													: false
											}
											onClick={isActive => {
												if (isActive) {
													let url = window.location.href;

													let state = this.generateRandomString(16);
													localStorage.setItem(stateKey, state);
													localStorage.setItem("base.url", url);
													window.location =
														"https://accounts.spotify.com/authorize" +
														"?response_type=code" +
														"&client_id=8705ab87e33f4c599fe4ae5de5420b0a" +
														"&scope=" +
														["user-top-read"].join(" ") +
														"&redirect_uri=" +
														url +
														"&state=" +
														encodeURIComponent(state);
												} else {
													addApi({
														variables: {
															token: JSON.stringify({
																accessToken: null,
																expiresIn: 0
															}),
															api: "spotify"
														}
													});
												}
											}}
											actionType="switch"
											data="Spotify"
										/>
										<TabMenuHeader text="Security / Info" />
										<TabMenuItem
											key={blockedUserCount}
											header={"Blocked Users"}
											data={blockedUserCount + ""}
										/>
										<Link to="/terms">
											<TabMenuItem data="Terms of Service" />
										</Link>
										<Link to="/privacy">
											<TabMenuItem data="Privacy Policy" />
										</Link>
										<div className="dangerousActions">
											<div className="logoutButtonContainer">
												<Button
													style="red"
													text="Log Out"
													onClick={this.onLogoutClick}
												/>
											</div>
											<div className="deactivateAccountButtonContainer">
												<Button
													style="white"
													text="Deactivate Account"
													onClick={evt => {
														this.onDeactivateAccountClick();
													}}
												/>
											</div>
										</div>
										<ConfirmDeactivate
											closeModal={this.closeModal}
											active={this.state.deactivate}
										/>
										<UpgradeModal
											active={this.state.showUpgrade}
											closeModal={this.closeUpgradeModal}
										/>
									</div>
								);
							}}
						</Query>
					);
				}}
			</Mutation>
		);
	};
}

export default class ModamilyAccountDetails extends React.Component {
	state = {
		view: 0,
		userSelectedNotifications: null,
		userCustomSelectedNotifications: null
	};

	setView = (index, refetch) => {
		this.setState({ view: index || 0, refetch });
	};

	handleNotificationButtonClick = (value, index, selected) => {
		const newState = {userCustomSelectedNotifications: null};

		if (index === 5) {
			newState.userCustomSelectedNotifications = [5];
		} else if (index === 0) {
			newState.userCustomSelectedNotifications = [0];
		} else if (index > 0 && index < 5) {
			newState.userCustomSelectedNotifications = selected
				.filter(s => s > 0 && s < 5)
			;
		}
		this.setState(newState);
	}

	render = () => {
		let user = JSON.parse(localStorage.getItem("user"));
		
		const notificationsList = [
			'enable all notifications',
			'new messages',
			'new likes',
			'new matches',
			'expiring matches',
			'disable all notifications'
		];
		let selectedNotifications = [];
		if (this.state.userSelectedNotifications === null) {
			selectedNotifications = user.notifications ? 
				user.notifications.map(
					notif => notificationsList.indexOf(notif)
				) : []
			;
		} else {
			selectedNotifications = this.state.userSelectedNotifications;
		}

		let userNotificationsNames = [];
		if (this.state.userCustomSelectedNotifications) {
			userNotificationsNames = this.state.userCustomSelectedNotifications.map(
				index => notificationsList[index]
			);
		} else {
			userNotificationsNames = selectedNotifications.map(
				index => notificationsList[index]
			);
		}
		user.notifications = userNotificationsNames;
		

		let views = [
			<AccountDetailsMenu
				setView={this.setView}
				key={user ? user.email : 0}
				user={user}
				refetch={this.state.refetch}
				updateNav={this.props.updateNav}
				stopRefetch={() => this.setState({ refetch: false })}
			/>,
			<NameForm
				key={user ? user.firstName + user.lastName : null}
				data={
					user ? { firstName: user.firstName, lastName: user.lastName } : null
				}
				invalidate={() => {}}
				onValidChange={data => {
					console.log(data);
					let newUser = { ...user, ...data };
					console.log(newUser);
					user = newUser;
				}}
			/>,
			<div className="confirmEmailPage">
				<FormHeader text="Email" />
				<TextInput
					key={user ? user.email : null}
					type="email"
					label="Email"
					reference="emailSign"
					ref="emailInput"
					value={user ? user.email : null}
					validate={true}
					onContentChange={data => {
						console.log(data);
						let newUser = { ...user };
						console.log(newUser);
						newUser.email = data ? data : user.email;
						user = newUser;
					}}
					onEnterPress={this.onEnterPress}
				/>
			</div>,
			<div className="confirmEmailPage">
				<FormHeader text="Phone" />
				<TextInput
					key={user ? user.phone : null}
					type="phone"
					label="Phone"
					reference="phoneSign"
					ref="phoneInput"
					value={user ? user.phone : null}
					validate={true}
					onContentChange={data => {
						let newUser = { ...user };
						newUser.phone = data ? data : user.phone;
						user = newUser;
					}}
					onEnterPress={this.onEnterPress}
				/>
			</div>,
			<CurrentLocationForm
				key={user ? user.country + user.zipcode : null}
				data={user ? { country: user.country, zipcode: user.zipcode } : null}
				invalidate={() => {}}
				onValidChange={data => {
					let newUser = { ...user, ...data };
					user = newUser;
				}}
			/>,
			<div className="confirmNotificationsPage">
				<FormHeader text="Notifications" />
				
				<ButtonGroup
					data={notificationsList}
					selected={selectedNotifications}
					customSelected={this.state.userCustomSelectedNotifications}
					type="check"
					onButtonClick={this.handleNotificationButtonClick}
					manualSelection={true}
				></ButtonGroup>
			</div>
		];

		let component = views[this.state.view];
		return (
			<Mutation mutation={Mutations.updateUser} variables={{ ...user }}>
				{(updateUser, update) => {
					if (update.loading) {
						return <LoadingComponent />;
					}

					if (update.error) {
						console.log(update.error);
					}

					if (update.data) {
						console.log(update.data);
					}

					if (this.state.view === 0) {
						return component;
					} else {
						return (
							<div className="accountDetailFormContainer">
								{component}
								<div className="accountDetailFormActions">
									<div className="cancelButtonContainer">
										<Button
											text="Cancel"
											style="white"
											onClick={() => this.setView(0)}
										/>
									</div>
									<div className="submitButtonContainer">
										<Button
											text="Submit"
											style="orangeGradient"
											onClick={() => {
												console.log(user);
												updateUser({
													variables: { ...user }
												}).then(data => {
													this.setView(0, true);
												});
											}}
										/>
									</div>
								</div>
							</div>
						);
					}
				}}
			</Mutation>
		);
	};
}
