import React from "react";
import TextInput from "../form/ModamilyAppTextInput";
import ButtonGroup from "../form/ModamilyAppButtonGroup";
import FormHeader from "../form/ModamilyAppFormHeader";

/**
 * @property data [Object] - { educationLevel: null, school: null } - default data for the form
 * @property invalidate [Function] - Function that gets called when the form is invalid
 * @property onValidChange[Function]- Function that gets called when the form is valid
 */
export default class ModamilyAppEducation extends React.Component {
	state = {
		data: this.props.data || { educationLevel: null, school: null }
	};

	buttonOptions = [
		"postgrad",
		"undergrad",
		"some college",
		"high-school or equivalent",
		"prefer not to say"
	];

	componentDidMount = () => {
		this.validate();
	};

	validate = () => {
		let data = this.state.data;
		if (!data.educationLevel || !data.school) {
			this.props.invalidate();
		} else {
			this.props.onValidChange(data);
		}
	};

	/**
	 * @argument key [String] - key of the value that is to be changed
	 */
	updateData = (key, value) => {
		let data = this.state.data;

		if (!value) {
			this.props.invalidate();
		}

		data[key] = value;

		let state = this.state;

		state.data = data;

		this.setState(state);
		this.validate();
	};

	handleButtonClick = (value, index, isActive) => {
		this.updateData("educationLevel", isActive ? value : null);
	};

	render = () => {
		let { data } = this.state;
		let selected = -1;
		if (data.educationLevel) {
			selected = this.buttonOptions.indexOf(data.educationLevel.toLowerCase());
		}

		return (
			<div className="educationForm">
				<FormHeader text="Education"></FormHeader>
				<ButtonGroup
					data={this.buttonOptions}
					selected={selected}
					type="radio"
					onButtonClick={this.handleButtonClick}></ButtonGroup>
				<TextInput
					label="School"
					value={data.school || ""}
					onContentChange={data => {
						if (typeof data === "boolean") {
							this.updateData("school", null);
						} else {
							this.updateData("school", data);
						}
					}}></TextInput>
			</div>
		);
	};
}
