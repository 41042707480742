import React from "react";

/**
 * @property {Boolean} active - if the button is active or not
 * @property {Function} onClick - a function run when the button is clicked
 */
export default class ModamilyAppRadioButton extends React.Component {
  state = {
    active: !!this.props.active
  };

  componentDidMount = () => {
    if (!(this.props.active == this.state.active)) {
      this.setState({ active: this.props.active });
    }
  };

  onClick = evt => {
    this.setState({ active: !this.state.active }, () => {
      if (this.props.onClick) {
        this.props.onClick(this.state.active);
      }
    });
  };

  render() {
    return (
      <div className={"radioButtonContainer"} onClick={this.onClick}>
        <div
          className={"radioButton" + (this.props.active ? " radioActive" : "")}
        />
        <div className="radioButtonTextContainer">
          <p className="radioButtonText">{this.props.text}</p>
        </div>
      </div>
    );
  }
}
