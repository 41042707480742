import React from 'react';
import FormHeader from '../form/ModamilyAppFormHeader';
import Badge from '../common/ModamilyAppBadge';
import Button from '../common/ModamilyAppButton';
import RoundImage from '../common/ModamilyAppRoundImage';
import TextArea from '../form/ModamilyAppTextArea';
import QuestionModal from '../onboarding/ModamilyAppQuestionModal';

/**
 * @property {Object} data - { conversationStarters: [] } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppConversationStarters extends React.Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('user'));

    this.state = {
      data: this.props.data || { conversationStarters: [] },
      edit: false,
      selected: -1,
      user
    };
  }

  addConvoStarter = index => {
    this.setState({ edit: true, selected: index });
  };

  validate = () => {
    let atLeast1 = false;
    let conversationStarters = this.state.data.conversationStarters;

    if (this.props.onEditProfile) {
      atLeast1 = true;
    } else {
      for (let index = 0; index < conversationStarters.length; index++) {
        const starter = conversationStarters[index];
        if (starter) {
          if (starter.question && starter.answer) {
            atLeast1 = true;
          }
        }
      }
    }

    if (atLeast1) {
      this.props.onValidChange(this.state.data);
    }
  };

  selectQuestionHandler = question => {
    let data = this.state.data;
    let selected = data.conversationStarters[this.state.selected] || {};

    selected.question = question;
    selected.answer = '';

    data.conversationStarters[this.state.selected] = selected;

    if (this.state.selected === -1) {
      this.setState({ selected: 0, edit: true, data });
    } else {
      this.setState({ data }, () => {
        this.props.hideFooter && this.props.hideFooter(!!question);
        console.log(question, this.state);
      });
    }
  };

  removeConversationStarter = index => {
    let data = this.state.data;

    let convo = data.conversationStarters[index];

    convo.question = '';
    convo.answer = '';

    console.log(convo);

    data.conversationStarters[index] = convo;

    this.setState({ data }, this.validate);
  };

  updateSelectedAnswer = answer => {
    let data = this.state.data;
    let selected = data.conversationStarters[this.state.selected];

    selected.answer = answer;
    this.setState({ data });
  };

  completeConvoStarter = () => {
    this.setState({ edit: false, selected: -1 }, this.validate);
  };
  cancelConvoStarter = () => {
    let data = this.state.data;
    let selected = data.conversationStarters[this.state.selected];

    selected.question = '';
    selected.answer = '';
    this.setState({ selected: -1, data, edit: false });
  };

  renderEditForm = () => {
    let mainImg = null;
    let { user, selected, data } = this.state;
    if (user.images)
      for (let index = 0; index < user.images.length; index++) {
        const image = user.images[index];
        if (image.isMain) {
          mainImg = image.path;
          break;
        }
      }

    // console.log(selected, data);

    // console.log(data.conversationStarters);

    let question =
      data.conversationStarters.length > selected
        ? data.conversationStarters[selected].question
        : null;

    // console.log(question);

    let selectedQuestions = [];

    for (let index = 0; index < data.conversationStarters.length; index++) {
      const starter = data.conversationStarters[index];
      selectedQuestions.push(starter.question);
    }

    if (!question) {
      return (
        <div className="conversationForm">
          <FormHeader text="Conversation Starters" />
          <div className="profileImageContainer">
            <RoundImage
              size="Regular"
              image={user ? mainImg : null}
              name={user.firstName}
              isUrl={true}
            />
          </div>
          <QuestionModal
            selectedQuestions={selectedQuestions}
            onSelect={q => {
              this.selectQuestionHandler(q);
            }}
            active={true}
            hideFooter={this.props.hideFooter}
          />
        </div>
      );
    }

    let isIncomplete = !(data.conversationStarters[selected].answer.length > 0);

    return (
      <div className="conversationForm">
        <FormHeader text="Conversation Starters" />
        <div className="profileImageContainer">
          <RoundImage
            size="Regular"
            image={user ? mainImg : null}
            name={user.firstName}
            isUrl={true}
          />
        </div>
        <div className="selectedQuestionContainer">
          <Badge
            type="delete"
            onClick={e => {
              e.stopPropagation();
              this.selectQuestionHandler(null);
            }}
          >
            <div className="cardContainer">
              <div>
                <p className="purpleText regularWeight">{question}</p>
              </div>
            </div>
          </Badge>
        </div>
        <TextArea
          onContentChange={this.updateSelectedAnswer}
          placeholderText="Answer here"
          text={data.conversationStarters[selected].answer}
        />
        <div className="convoFormButtonContainer">
          <Button
            text={isIncomplete ? 'Cancel' : 'Done'}
            style={isIncomplete ? 'white' : 'orangeGradient'}
            onClick={
              isIncomplete ? this.cancelConvoStarter : this.completeConvoStarter
            }
          />
        </div>
        <div />
      </div>
    );
  };

  render() {
    let { user, edit, data } = this.state;
    let components = [];

    for (let index = 0; index < 3; index++) {
      const starter = data.conversationStarters[index];
      if (starter) {
        if (starter.question) {
          components.push(
            <div
              key={components.length}
              className="convoCardContainer"
              onClick={() => {
                this.addConvoStarter(index);
              }}
            >
              <Badge
                type="delete"
                onClick={e => {
                  e.stopPropagation();
                  this.removeConversationStarter(index);
                }}
              >
                <ConversationCard
                  question={starter.question}
                  answer={starter.answer}
                />
              </Badge>
            </div>
          );
        } else {
          components.push(
            <div
              key={components.length}
              className="convoCardContainer"
              onClick={() => {
                this.addConvoStarter(index);
              }}
            >
              <Badge
                type="add"
                onClick={e => {
                  e.stopPropagation();
                  this.addConvoStarter(index);
                }}
              >
                <ConversationCard />
              </Badge>
            </div>
          );
        }
      } else {
        components.push(
          <div
            key={components.length}
            className="convoCardContainer"
            onClick={() => {
              this.addConvoStarter(index);
            }}
          >
            <Badge
              type="add"
              onClick={e => {
                e.stopPropagation();
                this.addConvoStarter(index);
              }}
            >
              <ConversationCard />
            </Badge>
          </div>
        );
      }
    }

    if (edit) {
      return this.renderEditForm();
    }

    let mainImg =
      this.state.data &&
      this.state.data.profilePhoto &&
      this.state.data.profilePhoto.base64;

    // if (user.images) {
    //   for (let index = 0; index < user.images.length; index++) {
    //     const image = user.images[index];
    //     if (image.isMain) {
    //       mainImg = image.path;
    //       break;
    //     }
    //   }
    // }

    return (
      <div className="conversationForm">
        <FormHeader text="Conversation Starters" />
        <div className="profileImageContainer">
          <RoundImage
            size="Regular"
            image={user ? mainImg : null}
            name={user.firstName}
            isUrl={false}
          />
        </div>
        <div className="conversationCardContainer">{components}</div>
      </div>
    );
  }
}

class ConversationCard extends React.Component {
  render = () => {
    let { question, answer } = this.props;

    if (!question) {
      question = 'Add a question to answer...';
      answer = null;
    }

    let questionTextClass = 'question';
    if (answer) {
      questionTextClass = 'purpleText BoldWeight';
    }

    return (
      <div className="conversationCard">
        <div className="questionContainer">
          <p className={questionTextClass}>{question}</p>
        </div>
        {answer ? (
          <div className="answerContainer">
            <p className="purpleText regularWeight">{answer}</p>
          </div>
        ) : null}
      </div>
    );
  };
}
