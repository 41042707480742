import React from "react";
import ModamilyAppLoadingComponent from "../common/ModamilyAppLoadingComponent";
import DotMenu from "../common/ModamilyAppDotMenu";
import UpgadeModal from "./ModamilyAppUpgrade";
import config from "../../config/config";
import Mutations from "../../apollo/mutations/mutations";
import Queries from "../../apollo/queries/queries";
import FullProfile from "../../components/modals/ModamilyAppFullProfile";

import { Query, Mutation, withApollo } from "react-apollo";

import decode from "jwt-decode";
import ModamilyAppRoundImage from "../common/ModamilyAppRoundImage";

import photoImg from "../../resources/images/appAssets/photoButton.png";

import ChatMessage from "../messaging/ChatMessage";
import io from "socket.io-client";


function buildFileSelector() {
  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  return fileSelector;
}

class ModamilyAppMessageChatSocketIo extends React.Component {
  messages = [];
  socket = null;
  authToken = null;
  messageComponents = [];

  constructor(props) {
    super(props);
    this.state = {
      connected: false,
      user: JSON.parse(localStorage["user"]),
      otherUser: this.props.user,
      convoId: this.props.convoId,
      timeLeft: this.props.timeLeft,
      active: this.props.active,
      showUpgrade: false,
      messageText: "",
      scrollViewHeight: 0,
      userb: null
    };
    this.fileSelector = buildFileSelector();
    this.fileSelector.onchange = this.fileSelectorClick;
  }

  fileSelectorClick = evt => {
    let files = evt.target.files;
    var reader = new FileReader();
    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      reader.onload = e => {
        file.base64 = e.target.result;
        this.sendImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  componentDidMount = async () => {
    const { client } = this.props;
    if (this.props.active) {
      this.connectToWebsocket();
    }

    if (this.refs.chatViewComponent) {
      let view = this.refs.chatViewComponent;
      view.scrollTo(view.scrollHeight);
    }

    if(this.state.otherUser) {
      const userId = this.state.otherUser.userB !== this.state.user.id ? this.state.otherUser.userB : this.state.otherUser.userA;
      const otherUser = await client.query({ query: Queries.getUser, variables: { id: userId }});
      const result = decode(otherUser.data.user);
      if(result.action === 'success') {
        this.setState({ userb: result.data.user})
      }
    }
  };

  componentWillUnmount = () => {
    this.messages = [];
    this.messageComponents = [];

    if (this.socket) {
      this.socket.emit(
        "onDisconnect",
        {
          authToken: this.authToken,
          conversationId: this.state.convoId
        },
        result => {
          if (result) {
            alert(JSON.stringify(result));
          }
        }
      );
      this.socket.emit("onDisconnect");
      this.socket.off();
    }
  };

  connectToWebsocket = async () => {
    this.authToken = await localStorage.getItem("auth.token");

    this.socket = io(config.backendUrn);

    this.socket.emit(
      "join",
      { conversationId: this.state.convoId, authToken: this.authToken },
      ({ result, message }) => {}
    );

    this.socket.on("message", ({ message }) => {
      this.messages.push(message);
      let ReceivedMessage = {
        id: message.id,
        content_type: message.contentType,
        content: message.content,
        conversation_id: message.conversationId,
        author_id: message.authorId,
        read: message.read,
        created_at: message.createdAt,
        updated_at: message.updatedAt
      };
      let messageComp = this.addMessageComponents({ message: ReceivedMessage });
      this.messageComponents.push(messageComp);
      this.setState({ hasReadAllMessages: false });
    });
  };

  sendMessage = async message => {
    if (message.trim().length <= 0) return;

    if (this.socket) {
      this.socket.emit(
        "sendMessage",
        {
          conversationId: this.state.convoId,
          authToken: this.authToken,
          message: JSON.stringify({
            content: message,
            content_type: "text"
          })
        },
        ({ result, message }) => {
          if (result === 200) {
            let ReceivedMessage = {
              id: message.id,
              content_type: message.contentType,
              content: message.content,
              conversation_id: message.conversationId,
              author_id: message.authorId,
              read: message.read,
              created_at: message.createdAt,
              updated_at: message.updatedAt
            };
            let messageComp = this.addMessageComponents({
              message: ReceivedMessage
            });
            this.messageComponents.push(messageComp);
            this.setState({
              hasReadAllMessages: false
            });
          } else {
            console.log(result, message);
          }
        }
      );
    }
    this.setState({ messageText: "" });
  };

  sendImage = image => {
    if (this.socket) {
      this.socket.emit(
        "sendMessage",
        {
          conversationId: this.state.convoId,
          authToken: this.authToken,
          message: JSON.stringify({
            content: image,
            content_type: "image"
          })
        },
        ({ result, message }) => {
          if (result === 200) {
            let ReceivedMessage = {
              id: message.id,
              content_type: message.contentType,
              content: message.content,
              conversation_id: message.conversationId,
              author_id: message.authorId,
              read: message.read,
              created_at: message.createdAt,
              updated_at: message.updatedAt
            };
            let messageComp = this.addMessageComponents({
              message: ReceivedMessage
            });
            this.messageComponents.push(messageComp);
            this.setState({
              hasReadAllMessages: false
            });
          } else {
            console.log(result, message);
          }
        }
      );
    }
    this.setState({ messageText: "" });
  };

  checkReadMessages = () => {
    let messagesToRead = [];
    for (let i = 0; i < this.messages.length; i++) {
      if (
        this.messages[i].authorId !== this.state.user.id &&
        !this.messages[i].read
      ) {
        messagesToRead.push(this.messages[i].id);
      }
    }

    return messagesToRead.length > 0;
  };

  updateCurrentMessage = text => {
    this.setState({ messageText: text.target.value, refetch: false });
  };

  addMessageComponents = ({ message }) => {
    let isYours = message.author_id === this.state.user.id;
    return (
      <ChatMessage
        key={
          "" +
          this.messageComponents.length +
          message.content_type +
          message.author_id
        }
        message={message.content}
        contentType={message.content_type}
        byCurrentUser={isYours}
        timeSent={message.created_at}
        userObject={this.state.user}
        read={message.read}
        authorId={message.author_id}
      />
    );
  };

  _handleKeyDown = e => {
    if (e.key === "Enter") {
      this.sendMessage(this.state.messageText);
    }
  };

  renderMessageComponents = arr => {
    let components = [];
    const messageIds = [];

    for (let index = 0; index < arr.length; index++) {
      const message = arr[index];
    

      let isYours = message.author_id === this.state.user.id;
      if(!isYours && !message.read) {
        messageIds.push(message.id)
      }
      components.push(
        <ChatMessage
          key={"" + index + message.content_type + message.author_id}
          message={message.content}
          contentType={message.content_type}
          byCurrentUser={isYours}
          timeSent={message.created_at}
          userObject={this.state.user}
          read={message.read}
          authorId={message.author_id}
        />
      );
    }

    if(messageIds.length > 0) {
      this.props.setMessagesAsRead({variables: { messageIds }});
    }
    return components;
  };

  getImage = () => {
    let user = this.state.user;
    let subscription = user.subscription;

    if (subscription.subType.toUpperCase() !== "FREE") {
      this.fileSelector.click();
    } else {
      this.setState({ showUpgrade: true });
    }
  };

  render = () => {
    return (
      <div
        id="messageModalCurtain"
        className={
          "messageModalCurtain " +
          (this.state.active ? "messageCurtainDrag" : "hiddenMessageModal")
        }
        onClick={e => {
          if (e.target.className === "messageModalCurtain messageCurtainDrag")
            this.props.close();
        }}
      >
        {this.state.convoId ? (
          <Query
            query={Queries.getMessages}
            variables={{ conversationId: this.state.convoId }}
          >
            {({ loading, error, data, refetch }) => {
              if (this.state.refetch) {
                refetch();
              }

              if (loading) {
                return <ModamilyAppLoadingComponent />;
              }
              if (error) {
                console.log(error);
              }

              if (data) {
                let response = decode(data.getMessages);

                //  we dont need to fetch every time
                // if (this.messages.length > 0) {
                // refetch();
                // }
                if (
                  this.messageComponents.length < response.messages.length
                ) {
                  refetch();
                  this.messageComponents = this.renderMessageComponents(
                    response.messages
                  );
                }
              }

              let curUserImg = null;

              for (
                let index = 0;
                index < this.state.user.images.length;
                index++
              ) {
                const image = this.state.user.images[index];
                if (image.isMain) {
                  curUserImg = image;
                  break;
                }
              }

              if (!curUserImg && this.state.user.images.length > 0) {
                curUserImg = this.state.user.images[0];
              }

              let otherUserImg = this.state.otherUser.otherUserProfileImage;
              return (
                <div className="messageChatCard" style={{ visibility: this.state.showFullProfile ? "hidden" : "visible"}}>
                  <div className="messageChatContainer">
                    <div className="browseCardPopUpMenuContainer">
                      <DotMenu
                        options={["Exit Conversation", "View Profile"]}
                        functions={[
                          () => {
                            this.props.close();
                          },
                          () => {
                            this.setState({ showFullProfile: true })
                          }
                        ]}
                      />
                    </div>
                    <div className="messageChatHeaderContainer">
                      <div className="messageChatHeaderImageContainer">
                        <div className="overlayImageContainer">
                          <ModamilyAppRoundImage
                            size="Regular"
                            image={curUserImg ? curUserImg.path : null}
                            isUrl={true}
                            name={this.state.user.firstName}
                          />
                        </div>
                        <ModamilyAppRoundImage
                          size="Regular"
                          image={otherUserImg ? otherUserImg.path : null}
                          isUrl={true}
                          name={this.state.otherUser.otherUser.firstName}
                          onClick={() => {
                            this.setState({showFullProfile: true});
                          }}
                        />
                      </div>
                      <div className="messageChatHeaderTextContainer">
                        <p className="messageChatHeaderText">
                          {this.state.user.firstName +
                            " + " +
                            this.state.otherUser.otherUser.firstName}
                        </p>
                        <p className="greyText lightWeight">
                          You two liked each other
                        </p>
                      </div>
                    </div>
                    <div className="messageChatBody">
                      <div
                        ref={e => {
                          if (e) {
                            e.scrollTop = e.scrollHeight;
                          }
                        }}
                        key={
                          this.messageComponents
                            ? this.messageComponents.length
                            : ""
                        }
                        className="chatView"
                      >
                        {this.messageComponents}
                      </div>
                    </div>
                  </div>
                  <div className="chatTextInputContainer">
                    <div
                      onClick={() => {
                        this.getImage();
                      }}
                      className="sendImageButton"
                    >
                      <img className="sendImageButtonImg" src={photoImg} />
                    </div>
                    <input
                      placeholder="Enter message"
                      className="messageChatInput"
                      type="text"
                      onChange={this.updateCurrentMessage}
                      onKeyDown={this._handleKeyDown}
                      value={this.state.messageText}
                    />
                    <div
                      className={
                        "messageChatButton " +
                        (this.state.messageText ? "" : "diabledChatButton")
                      }
                      onClick={() => {
                        this.sendMessage(this.state.messageText);
                      }}
                    >
                      <p className="redText semiBoldWeight">Send</p>
                    </div>
                  </div>
                </div>
              );
            }}
          </Query>
        ) : null}
        { this.state.showFullProfile && this.state.userb ? <FullProfile user={this.state.userb} exitFullProfile={() => this.setState({ showFullProfile: false })} disableCardActions={true} /> : null }

        <UpgadeModal
          closeModal={() => {
            this.setState({ showUpgrade: false });
          }}
          active={this.state.showUpgrade}
        />
      </div>
    );
  };
}

function withSetMessagesAsRead(WrappedComponent) {
  return function MutationWrapper(props) {
    return (<Mutation mutation={Mutations.setMessagesAsRead}>{(mutate, { error })=> {
      if (error) {
        console.log(error);
      } 
      return(<WrappedComponent {...props} setMessagesAsRead={mutate} />)
    }}</Mutation>)
  }
}

export default withApollo(withSetMessagesAsRead(ModamilyAppMessageChatSocketIo));
