import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "react-apollo";
import client from "./apollo/client";
import Router from "./router/ModamilyAppRouter";

// redux
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store'
import { PersistGate } from 'redux-persist/integration/react';
import ModamilyAppLoadingComponent from "./components/common/ModamilyAppLoadingComponent";

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={<ModamilyAppLoadingComponent />} persistor={persistor}>
        <Router>
          <App />
        </Router>
        </PersistGate>
      </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
