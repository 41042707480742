import React from "react";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";


/**
 * @property {Object} data  - { occupationWorkplace: null, occupationTitle: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppWorkForm extends React.Component {
  state = {
    data: this.props.data || {
      occupationWorkplace: null,
      occupationTitle: null
    }
  };
  componentDidMount = () => {
    let data = this.state.data;
    if (!data.occupationTitle || !data.occupationWorkplace) {
      this.props.invalidate();
    } else if (data.occupationTitle && data.occupationWorkplace) {
      this.props.onValidChange(data);
    }
  }


  updateData = (key, value) => {
    let data = this.state.data;
    data[key] = value;
    // console.log(data);
    this.setState({ data }, this.validate);
  };

  validate = () => {
    let data = this.state.data;

    console.log(data);

    if (!data.occupationTitle || !data.occupationWorkplace) {
      this.props.invalidate();
    } else if (data.occupationTitle && data.occupationWorkplace) {
      console.log(data);
      this.props.onValidChange(data);
    }
  };

  render = () => {
    return (
      <div className="workForm">
        <ModamilyAppFormHeader text="Workplace Information" />
        <ModamilyAppTextInput
          label="Title"
          onContentChange={data => {
            console.log(data);
            if (typeof data == "boolean") {
              this.updateData("occupationTitle", null);
            } else {
              this.updateData("occupationTitle", data);
            }
          }}
          value={this.state.data.occupationTitle}
        />
        <ModamilyAppTextInput
          label="Company"
          onContentChange={data => {
            console.log(data);
            if (typeof data == "boolean") {
              this.updateData("occupationWorkplace", null);
            } else {
              this.updateData("occupationWorkplace", data);
            }
          }}
          value={this.state.data.occupationWorkplace}
        />
      </div>
    );
  };
}
