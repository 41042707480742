import React from 'react';
import ModamilyAppTextInput from '../form/ModamilyAppTextInput';
import Button from '../common/ModamilyAppButton';
import ModamilyAppFormHeader from '../form/ModamilyAppFormHeader';
import ModamilyAppDropDownMenu from '../form/ModamilyAppDropDownMenu';
import getLocation from '../../services/googleMaps';
import countries from '../../constants/countries';
import ModamilyAppLoadingComponent from '../common/ModamilyAppLoadingComponent';

/**
 * @property {Object} data  - { country: null, zipcode: null, latLong: null  } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppHometownForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || { country: null, zipcode: null, latLong: null }
    };
  }

  componentDidMount() {
    const { type } = this.props;
    if(type !== 'UPDATE_SEARCH_PREFERENCES') {
      this.validateCallback();
      this.checkGeolocation();
    }
  }

  validateCallback = () => {
    const { data } = this.state;
    const { validation, invalidate, onValidChange } = this.props;

    if (validation === 'custom') onValidChange(data);
    else if (data.country && data.zipcode) onValidChange(data);
    else invalidate();
  };

  checkGeolocation = () => {
    if ('geolocation' in navigator) {
      this.setState({ isLoading: true });

      navigator.geolocation.getCurrentPosition(position => {
        let location = {
          city: '',
          country: '',
          zipcode: '',
          latLong: position.coords.latitude + ',' + position.coords.longitude
        };

        getLocation(position.coords).then(data => {
          const res = JSON.parse(data).results;
          console.log(res, 'response f/rom google');
          if (res.length === 0) {
            window.alert('Sorry, an error occured. \nPlease try again later');
            this.setState({ data: location }, this.validateCallback);
            return null;
          }

          for (let index = 0; index < res.length; index++) {
            const locationObj = res[index];
            if(locationObj.address_components) {
              for(let y = 0; y < locationObj.address_components.length; y++) {
                const address_components = locationObj.address_components[y];
                if(address_components.types.indexOf('locality') > -1) {
                  location.city = address_components.long_name;
                } else if (address_components.types.indexOf('country') > -1) {
                  location.country = address_components.long_name;
                } else if (address_components.types.indexOf('postal_code') > -1) {
                  location.zipcode = address_components.long_name;
                }

                if(location.zipcode && location.city && location.country) {
                  break;
                }
              }
              if(location.zipcode && location.city && location.country) {
                break;
              }
            }
            if(location.zipcode && location.city && location.country) {
              break;
            }
          }
          this.setState(
            { data: location, isLoading: false },
            this.validateCallback
          );
        });
        this.setState({ isLoading: false });
      });

      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
    }
  };

  updateName = (key, text) => {
    if (!text) this.props.invalidate();

    this.setState(
      ({ data }) => ({
        data: { ...data, [key]: text }
      }),
      this.validateCallback
    );
  };

  onCountrySelect = country => {
    this.setState(
      ({ data }) => ({
        data: { ...data, country }
      }),
      this.validateCallback
    );
  };

  render() {
    const { isLoading, data } = this.state;
    const { isSearchForm } = this.props;

    const selectedCountry = countries.countryNames.findIndex(countryName =>
      countryName.includes(data.country)
    );

    if (isLoading) return <ModamilyAppLoadingComponent />;
    return (
      <div className="currentLocationForm">
        <ModamilyAppFormHeader
          text={isSearchForm ? 'Country and Zipcode' : 'Current Location'}
        />
        <ModamilyAppDropDownMenu
          key={data.country}
          selected={selectedCountry}
          data={countries.countryNames}
          title="Select Country"
          type="Country"
          onItemClick={this.onCountrySelect}
        />
        <ModamilyAppTextInput
          // key={data.zipcode}
          onContentChange={text => {
            console.log('text',text);
            this.updateName('zipcode', text);
          }}
          value={data.zipcode}
          label="Zip Code"
        />
        <div className="getLocationButtonContainer">
          <Button
            text="Get Location"
            style="orangeGradient"
            onClick={this.checkGeolocation}
          />
        </div>
      </div>
    );
  }
}
