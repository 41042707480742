import React from "react";
import FormHeader from "../form/ModamilyAppFormHeader";
import ButtonGroup from "../form/ModamilyAppButtonGroup";

/**
 * @property {Object} data  - { politics: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppPolitics extends React.Component {
    state = {
        data: this.props.data || { politics: null }
    }

    buttonOptions = [
        "Liberal",
        "Moderate",
        "Conservative",
        "Other",
        "Prefer not to say",
    ];

    componentDidMount = () => {
        this.validate();
    }

    validate = () => {
        let data = this.state.data;

        if (!data.politics) {
            this.props.invalidate()
        } else {
            this.props.onValidChange(data);
        }
    }

    handleButtonClick = (value, index, isActive) => {
        let data = this.state.data;
        data.politics = (isActive) ? value : null;
        this.setState({ data }, this.validate)
    }

    render = () => {
        let data = this.state.data;

        let selectedIndex = this.buttonOptions.indexOf(data.politics);


        return <div className="politicsForm">
            <FormHeader text="Politics"></FormHeader>
            <ButtonGroup type="radio" selected={selectedIndex} data={this.buttonOptions} onButtonClick={this.handleButtonClick}></ButtonGroup>
        </div>
    }

}