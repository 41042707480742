import React, { Component } from "react";
import GoogleLogin from "react-google-login";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import decode from "jwt-decode";

import ModamilyAppNavBar from "../components/common/ModamilyAppNavBar";
import ModamilyAppTextInput from "../components/form/ModamilyAppTextInput";
import ModamiyAppPageContainer from "../components/common/ModamilyPageContainer";
import emailIcon from "../resources/images/appAssets/email_purple.png";
import lockIcon from "../resources/images/appAssets/lock.png";
import ModamilyAppButton from "../components/common/ModamilyAppButton";
import ModamilyAppLinkText from "../components/common/ModamilyAppLinkText";
import ModamilyAppFooter from "../components/common/ModamilyAppFooter";
import ModamilyAppMutationRenderer from "../apollo/mutations/mutationRenderer";
import ModamilyAppLoadingComponent from "../components/common/ModamilyAppLoadingComponent";

import mutations from "../apollo/mutations/mutations";
import config from "../config/config";

class ModamilyAppRegisterLogin extends Component {
	constructor(props){
		super(props);

		this.state = {
			isLogin: !!this.props.isLogin,
			email: "",
			password: "",
			confPass: "",
			sendQuery: false,
			errorMessage: null
		};
	}

	componentDidMount = () => {
		let token = localStorage.getItem("auth.token");
		if (token === "undefined") {
			localStorage.clear();
		}
		let user = localStorage.getItem("user");
		if (user) {
			this.setState({ alreadyLoggedIn: true });
		}
	};

	confirmPassword = () => {
		if (
			this.state.password.trim().length > 0 &&
			this.state.confPass.trim().length > 0
		) {
			return this.state.password === this.state.confPass;
		} else {
			return false;
		}
	};

	queryErrorHandler = error => {
		this.setState({
			sendQuery: false,
			loading: false,
			email: "",
			password: "",
			errorMessage: ""
		});
		return <div></div>;
	};

	querySuccessHandler = data => {
		let response = decode(data.signIn);
		if (response.action === "success") {
			let token = response.data.token;
			let user = decode(token);
			localStorage.setItem("auth.token", token);
			localStorage.setItem("user", JSON.stringify(user));
			return <Redirect to="/browse" />;
		} else {
			this.setState(
				{ sendQuery: false, password: "", loading:false },
				this.createErrorMessage(response.data.message)
			);
			return <div></div>;
		}
	};

	mutationSuccessHandler = data => {
		let response = decode(data.signUp);
    
		if (response.action === "success") {
			let token = response.data.token;
			let user = decode(token);
			localStorage.setItem("auth.token", token);
			localStorage.setItem("user", JSON.stringify(user));
			return <Redirect to="/onboarding" />;
		} else {
			this.setState(
				{ sendQuery: false, password: "", errorMessage: response.data.message}
			);
			return <div></div>;
		}
	};

	mutationErrorHandler = error => {
		let message = "Error, please try again later";

		this.setState({
			sendQuery: false,
			email: "",
			password: "",
			confPass: "",
			errorMessage: message
		});
		setTimeout(() => {
			this.setState({ errorMessage: null });
		}, 5000);
		return null;
	};

	createErrorMessage = message => {
		this.setState({
			errorMessage: message
		});
		setTimeout(() => {
			this.setState({ errorMessage: null });
		}, 5000);
	};

	renderSocialSignIn = () => {
		let { social, socialResponse } = this.state;

		let token;

		let returnToken;

		if (social === "google") {
			token = {
				user: socialResponse.profileObj,
				accesssToken: socialResponse.accessToken,
				accessTokenExpirationDate: socialResponse.expires_at
			};

			returnToken = JSON.stringify({
				...token,
				expoPushToken: this.state.expoToken || ""
			});

			return (
				<Mutation
					mutation={mutations.googleSignIn}
					variables={{ token: returnToken }}>
					{(mutation, { loading, data, error }) => {
						if (error) {
							this.setState({ socialSignIn: false });
						} else if (data) {
							try {
								let response = decode(data.googleSignUp);

								if (response.action === "success") {
									localStorage.setItem(
										"user",
										JSON.stringify(response.data.user)
									);
									localStorage.setItem(
										"auth.token",
										response.data.modamilyToken
									);


									if (response.data.found) {
										return <Redirect to="/browse" />;
									} else {
										return <Redirect to="/onboarding" />;
									}
								} else {
									this.setState({ socialSignIn: false });
								}
							} catch (error) {
								this.setState({ socialSignIn: false });
							}
						} else {
							mutation();
						}

						return <ModamilyAppLoadingComponent />;
					}}
				</Mutation>
			);
		} else {
			localStorage.setItem(
				"social.facebook.signInResponse",
				JSON.stringify(socialResponse)
			);
			localStorage.setItem("social.facebook.token", socialResponse.accessToken);
			return <Redirect to="/facebook/confirmEmail" />;
		}
	};

	renderQuery = () => {
		let vars = this.state.isLogin
			? { email: this.state.email, password: this.state.password }
			: {
					email: this.state.email,
					password: this.state.password,
					firstName: "",
					lastName: "",
					addressLine1: "",
					addressLine2: "",
					zipcode: "",
					city: "",
					state: "",
					phone: "",
					country: "",
					fcmToken: ""
				};
		return this.state.isLogin ? (
			<ModamilyAppMutationRenderer
				mutation={mutations.signIn}
				variables={vars}
				onError={this.queryErrorHandler}
				onSuccess={this.querySuccessHandler}
				loadingComponent={<ModamilyAppLoadingComponent type="loader" />}
			/>
		) : (
			<ModamilyAppMutationRenderer
				mutation={mutations.signUp}
				variables={vars}
				onError={this.mutationErrorHandler}
				onSuccess={this.mutationSuccessHandler}
				loadingComponent={<ModamilyAppLoadingComponent type="loader" />}
			/>
		);
	};

	onEnterPress = e => {
		if (this.state.password.trim().length <= 0) {
			this.refs.passwordInput.refs.passwordSign.focus();
			this.createErrorMessage("Please enter a valid password");
			return;
		} else if (this.state.isLogin) {
			this.setState({ sendQuery: true });

		} else if (
			this.state.password.trim().length >= 8 &&
			this.state.email.trim().length > 1
		) {
			if (this.state.confPass.trim().length <= 0) {
				this.refs.confirmPassInput.refs.confirmPassSign.focus();
				this.createErrorMessage("Confirm password");
			} else if (
				this.state.password.trim().length >= 8 &&
				this.state.email.trim().length > 1 &&
				this.state.confPass.trim().length > 1
			) {
				if (this.confirmPassword()) {
					this.setState({ sendQuery: true });
				} else {
					this.createErrorMessage("Passwords must match");
				}
			}
		}
	};

	onSocialSignIn = (api, response) => {
		this.setState({
			socialSignIn: true,
			social: api,
			socialResponse: response,
			loading: true
		});
	};
 
	toggleLoading = () => {
		let loading = this.state.loading;
		this.setState({ loading: !loading });
	};

	updateText = (text, key) => {
		this.setState({ [key]: text });
	};

	toggleForm = isLogin => {
		this.setState({ isLogin });
	};

	render() {
		let {
			isLogin,
			email,
			password,
			sendQuery,
			confPass,
			errorMessage,
			alreadyLoggedIn,
			socialSignIn
		} = this.state;

		if (alreadyLoggedIn) {
			return <Redirect to="/browse" />;
		}

		if (sendQuery) {
			return this.renderQuery();
		}

		if (socialSignIn) {
			return this.renderSocialSignIn();
		}

		let isDisabled = !(email.trim().length > 0 && password.trim().length > 0);
		if (!isLogin) {
			isDisabled = !(confPass.trim().length > 0);
			isDisabled = !this.confirmPassword();
		}

		let suggestionText = isLogin
			? "Forgot your password?"
			: "Already have an account?";
		let suggestionLinkText = isLogin ? "Reset" : "Login";
		let suggestionLinkUrl = isLogin ? "/confirmEmail/resetPassword" : "/login";

		let passwordRequirements = isLogin ? null : (
			<div className="passwordRequirements">
				<p className="purpleText lightWeight">
					Password must be at least 8 characters long, contain an uppercase
					letter, a number and a special character
				</p>
			</div>
		);

		let component = this.state.loading ? (
			<ModamilyAppLoadingComponent />
		) : (
			<ModamiyAppPageContainer>
				{" "}
				<div className="form signForm">
					<ModamilyAppTextInput
						type="email"
						label="Email"
						reference="emailSign"
						ref="emailInput"
						icon={emailIcon}
						onContentChange={text => {
							if (typeof text == "boolean") {
								this.updateText("", "email");
							} else {
								this.updateText(text, "email");
							}
						}}
						onEnterPress={this.onEnterPress}
					/>
					{passwordRequirements}
					<ModamilyAppTextInput
						type="password"
						label="Password"
						reference="passwordSign"
						icon={lockIcon}
						validate={!isLogin}
						ref="passwordInput"
						onContentChange={text => {
							if (typeof text == "boolean") {
								this.updateText("", "password");
							} else {
								this.updateText(text, "password");
							}
						}}
						onEnterPress={this.onEnterPress}
					/>
					{!this.state.isLogin ? (
						<ModamilyAppTextInput
							type="password"
							label="Confirm Password"
							reference="confirmPassSign"
							ref="confirmPassInput"
							onContentChange={text => {
								if (typeof text == "boolean") {
									this.updateText("", "confPass");
								} else {
									this.updateText(text, "confPass");
								}
							}}
							onEnterPress={this.onEnterPress}
							icon={lockIcon}
						/>
					) : null}{" "}
					{errorMessage ? (
						<div className="errorMessageContainer">
							<p className="errorMessageText">{errorMessage}</p>
						</div>
					) : null}
					<div className="signFormBtnContainer">
						<ModamilyAppButton
							text={isLogin ? "Login" : "Register"}
							style="orangeGradient"
							disabled={isDisabled}
							onClick={this.onEnterPress}
						/>
					</div>
					<div className="formSuggestionTextContainer">
						<p className="formSuggestionText">{suggestionText}</p>
						<div className="loginFormLink">
							<ModamilyAppLinkText
								color="red"
								text={suggestionLinkText}
								link={suggestionLinkUrl}
							/>
						</div>
					</div>
				</div>
			</ModamiyAppPageContainer>
		);

		return (
			<div className="ModamilyAppRegisterLogin">
				<ModamilyAppNavBar
					hasLinks={false}
					type={isLogin ? "signin" : "register"}
				/>
				{component}
				<ModamilyAppFooter type="signup">
					<div className="socialFooterButtonsContainer">
						<div className="footerHeader">
							<p className="footerHeaderText">Continue with</p>
						</div>
						<div className="socialButtons">
							<GoogleLogin
								clientId={config.googleClientID}
								buttonText=""
								onClick={() => {
									this.toggleLoading();
								}}
								onSuccess={response => {
									this.onSocialSignIn("google", response);
									return;
								}}
								onFailure={response => {
								}}
								autoLoad={false}
								cookiePolicy={"single_host_origin"}
								className="googleLoginButton"
							/>
							{/* <FacebookLogin
								appId={config.facebookAppID}
								autoLoad={false}
								fields="name,email,picture,gender"
								onClick={response => {
									this.toggleLoading();
									return;
								}}
								callback={response => {
									this.onSocialSignIn("facebook", response);
									return;
								}}
								cssClass="facebookLoginButton"
								icon="fa-facebook"
								textButton=""
							/> */}
						</div>
					</div>
				</ModamilyAppFooter>
			</div>
		);
	}
}

export default ModamilyAppRegisterLogin;
