import React from "react";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";
import ModamilyAppButtonGroup from "../form/ModamilyAppButtonGroup";

/**
 * @property {Object} data  - { gender:null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppGenderForm extends React.Component {
  state = {
    data: this.props.data || { gender: null },
    valid: false
  };

  componentDidMount = () => {
    let data = this.state.data;
    if (!data.gender) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  }

  buttonOptions = ["male", "female", "trans male", "trans female"];

  updateValue = (key, data) => {
    let stateData = this.state.data;

    if (!data) {
      this.props.invalidate();
    }

    stateData[key] = data;

    let state = this.state;

    state.data = stateData;

    this.setState(state);
    this.onEnterPress();
  };

  onButtonClick = (value, index, isActive) => {
    if (isActive) {
      this.props.onValidChange({ gender: value });
    } else {
      this.props.invalidate();
    }
  };

  render() {
    let index = this.buttonOptions.indexOf(this.state.data.gender);
    if (index < 0) {
      index = null;
    }

    return (
      <div className="genderForm">
        <ModamilyAppFormHeader text="Gender" />
        <ModamilyAppButtonGroup
          onButtonClick={this.onButtonClick}
          data={this.buttonOptions}
          type="radio"
          selected={index}
        />
      </div>
    );
  }
}
