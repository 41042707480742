import React from "react";
import ModamilyAppTextInput from "../form/ModamilyAppTextInput";
import ModamilyAppFormHeader from "../form/ModamilyAppFormHeader";


/**
 * @property {Object} data  - { firstName: null, lastName: null } - default data for the form
 * @property {Function} invalidate - Function that gets called when the form is invalid
 * @property {Function} onValidChange- Function that gets called when the form is valid
 */
export default class ModamilyAppNameForm extends React.Component {
  state = {
    data: this.props.data || { firstName: null, lastName: null }
  };

  componentDidMount = () => {
    let data = this.state.data;
    if (!data.firstName || !data.lastName) {
      this.props.invalidate();
    } else if (data.firstName && data.lastName) {
      this.props.onValidChange(data);
    }
  }


  onEnterPress = evt => {
    let data = this.state.data;

    if (!data.lastName) {
      this.props.invalidate();
    } else if (!data.firstName) {
      this.props.invalidate();
    } else {
      this.props.onValidChange(data);
    }
  };

  /**
   * @argument nameType [String] - key of the value that is to be changed
   */
  updateName = (nameType, text) => {
    let data = this.state.data;

    if (!text) {
      this.props.invalidate();
    }

    data[nameType] = text;

    let state = this.state;

    state.data = data;

    this.setState(state);
    this.onEnterPress();
  };

  render() {
    return (
      <div className="nameForm">
        <ModamilyAppFormHeader text="Name" />
        <ModamilyAppTextInput
          onEnterPress={this.onEnterPress}
          onContentChange={text => {
            if (typeof text == "boolean") {
              this.updateName("firstName", null);
            } else {
              this.updateName("firstName", text);
            }
          }}
          reference="firstNameInput"
          ref="firstNameComponent"
          label="First Name"
          value={this.state.data.firstName}
        />
        <ModamilyAppTextInput
          onEnterPress={this.onEnterPress}
          onContentChange={text => {
            if (typeof text == "boolean") {
              this.updateName("lastName", null);
              this.props.invalidate();
            } else {
              this.updateName("lastName", text);
            }
          }}
          reference="lastNameInput"
          ref="lastNameComponent"
          label="Last Name"
          value={this.state.data.lastName}
        />
      </div>
    );
  }
}
