export default [
	{
		duration: 1,
		price: 29.99,
		savings: null,
		totalCost: 29.99,
		type: "1m"
	},
	{
		duration: 3,
		price: 19.99,
		savings: "33%",
		totalCost: 59.97,
		type: "3m"
	},
	{
		duration: 6,
		price: 14.99,
		savings: "50%",
		totalCost: 89.94,
		type: "6m"
	},
	{
		duration: 12,
		price: 9.99,
		savings: "67%",
		totalCost: 119.88,
		type: "12m"
	}
];
