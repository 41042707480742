import React from "react";

import closeWhiteImg from "../../resources/images/appAssets/closeWhite.png";
import closePurpleImg from "../../resources/images/appAssets/closePurple.png";
import browseIcon from "../../resources/images/appAssets/searchActive.png";
import likesIcon from "../../resources/images/appAssets/heartActive.png";
import messagesIcon from "../../resources/images/appAssets/messagesActive.png";
import settingsActive from "../../resources/images/appAssets/settingsActive.png";
import Button from "../common/ModamilyAppButton";

import { Link } from "react-router-dom";

/**
 * @property {String} text - link display text
 * @property {String} url - link url
 * @property {String} className - className for link text
 * @property {Image} icon - icon to display
 */
class NavSideMenuLink extends React.Component {
	render = () => {
		let { icon, url, text, className } = this.props;

		let content = null;

		content = (
			<Link to={url}>
				<div className="textLinkContainer">
					{icon ? <img src={icon} alt="icon"/> : null}
					<p className={className}>{text}</p>
				</div>
			</Link>
		);

		return <div className="navSideMenuLink">{content}</div>;
	};
}

/**
 * @property {Boolean} active - determines whether the menu is displayed or not
 * @property {Function} close - a function to close the menu
 * @property {Boolean} loggedIn - determines whether to show authed links or unauthed links
 */
export default class NavSideMenu extends React.Component {
	
	downloadApp = () => {
		if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
			window.open("https://www.apple.com/ios/app-store/", "_blank");
		} else if (/Android/i.test(navigator.userAgent)) {
			window.open(
				"https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
				"_blank"
			);
		} else {
			window.open(
				"https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
				"_blank"
			);
		}
	};

	appStore = () => {
		window.open("https://www.apple.com/ios/app-store/", "_blank");
	};

	playStore = () => {
		window.open(
			"https://play.google.com/store?utm_source=na_Med&utm_medium=hasem&utm_content=Mar0519&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Mar0519-Text_Search_BKWS-id_100566_%7cEXA%7cONSEM_kwid_43700023142506977&gclid=Cj0KCQjwtr_mBRDeARIsALfBZA560JMlKsrF5WlLvA0CDbIm2PFp60SQTLhZHZV0vN81wliNECY4yb8aAiYyEALw_wcB&gclsrc=aw.ds",
			"_blank"
		);
	};

	render = () => {
		let { active, loggedIn, close } = this.props;

		let linkComponents = [];

		if (!loggedIn) {
			linkComponents = (
				<div className="navSideMenuLinksContainer">
					<div className="signUpButtonContainer">
						<Link to="/register">
							<Button text="Sign up" style="orangeGradient" />
						</Link>
					</div>
					<div className="signUpButtonContainer">
						<Link to="/login">
							<Button text="Sign in" style="white" />
						</Link>
					</div>
				</div>
			);
		} else {
			let user = JSON.parse(localStorage["user"]);

			linkComponents = (
				<div className="navSideMenuLinksContainer" onClick={() => close()}>
					<NavSideMenuLink text={"BROWSE"} url="/browse" icon={browseIcon} />
					<NavSideMenuLink text={"LIKES"} url="/likes" icon={likesIcon} />
					<NavSideMenuLink
						text={"MESSAGES"}
						url="/messages"
						icon={messagesIcon}
					/>
					<NavSideMenuLink
						text={"SETTINGS"}
						url="/settings/home"
						icon={settingsActive}
					/>
				</div>
			);
		}

		return (
			<div
				className={
					active ? "navSideMenu showSideMenu" : "navSideMenu hideSideMenu"
				}>
				<div
					className={
						"navSideMenuContainer" +
						(loggedIn ? " loggedInSideMenu" : " blueSideMenu")
					}>
					<div className="navSideMenuCloseButtonContainer" onClick={close}>
						<img
							className="closeImg"
							src={loggedIn ? closeWhiteImg : closePurpleImg}
						/>
					</div>
					<div className="menuLinksContainer">{linkComponents}</div>
				</div>
			</div>
		);
	};
}
